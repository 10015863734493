import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { DateTime } from "luxon";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ReactComponent as PauseIcon } from "../../assets/svg/DS/pause.svg";
import { ReactComponent as DiscardIcon } from "../../assets/svg/DS/version-lines/discardIcon.svg";
import { ReactComponent as RestoreIcon } from "../../assets/svg/DS/version-lines/restoreIcon.svg";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../helpers/notifications";
import UserAvatar from "../../components/UserAvatar";
import { cancelPauseEdit } from "../../services/file";
import ActiveUsersRow from "./ActiveUsersRow";

export const EditsPausedHeader = ({
  type,
  version,
}: {
  type: "discard" | "revert" | "unpause";
  version?: string;
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="body-lg-Regular flex pr-4 pl-2 items-center">
        {type === "revert" && <RestoreIcon className="mr-4" />}
        {type === "discard" && <DiscardIcon className="mr-4" />}
        {type === "unpause" && <PauseIcon className="mr-4" />}
        <span className="font-semibold text-sm">
          {type === "revert" && `Revert to ${version || ""}`}
          {type === "discard" && "Discard changes?"}
          {type === "unpause" && "Edits paused for this file"}
        </span>
      </div>
    </div>
  );
};

export const EditsPausedBody = ({
  type,
  onSuccess,
  activeUsers,
  pausedBy,
  pausedOn,
  canUnpause,
}: {
  type: "discard" | "revert" | "unpause";
  onSuccess: () => void;
  activeUsers: string[];
  pausedOn?: string;
  pausedBy?: string;
  canUnpause?: boolean;
}) => {
  const fileId = useParams<{ fileId: string }>().fileId;
  const unpauseEditsMutation = useMutation({
    mutationFn: cancelPauseEdit,
    onSuccess: () => {
      onSuccess();
      successNotification({
        title: "Edits unpaused",
        message: "Edits have been unpaused for this file",
      });
    },
    onMutate: () => {
      loadingNotification({
        title: "Unpausing edits",
        message: "Please wait while we unpause edits for this file",
      });
    },
    onError: () => {
      regularNotification({
        title: "Error unpausing edits",
        message: "An error occurred while unpausing edits for this file",
      });
    },
  });

  const getBodyTitle = () => {
    if (type === "discard") {
      return (
        <div className="w-full mt-5">
          <div className="text-sm border-b font-normal text-gray-600 p-6 pt-0">
            Cannot discard changes while a pause edits request is in effect.
          </div>
        </div>
      );
    }
    if (type === "revert") {
      return (
        <div className="w-full mt-5">
          <div className="text-sm border-b font-normal text-gray-600 p-6 pt-0">
            Cannot revert to a previous version while a pause edits request is
            in effect.
          </div>
        </div>
      );
    }
  };


  return (
    <div className="flex flex-col items-center border-t">
      <div className="body-lg-Regular flex px-4 items-center w-full">
        <div className="flex justify-between pl-4 w-full items-center"></div>
      </div>
      {getBodyTitle()}
      <div className="text-sm font-normal p-6  mb-2 py-3">
        <div className="flex flex-col">
          <div className="flex items-center">
            <UserAvatar
              userMsId={pausedBy}
              withNameLabel={true}
              className="mr-2"
            />

            <p className="text-gray-500">requested to pause edits on:</p>
          </div>
          <p className="text-sm text-gray-500 ml-8">
            {DateTime.fromISO(pausedOn || "").toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </p>
        </div>
        <p className="text-sm font-normal text-gray-600 mb-2 mt-6 pt-0">
          All users working on this file are kindly asked to wrap their work and
          close any tabs where they have the file open.
        </p>
      </div>
      {activeUsers.length > 0 ? (
        <div className="border-t p-6 py-4 pb-5 w-full">
          <ActiveUsersRow activeUsers={activeUsers} />
        </div>
      ) : (
        <div className="border-t p-6 py-4 pb-5 w-full">
          <div className="text-sm flex items-center w-full justify-between text-gray-600">
            This file is currently not being used by any user
          </div>
        </div>
      )}
      {type === "unpause" && canUnpause && (
        <div className="flex w-full border-t items-center justify-end p-4 ">
          <Button
            type="button"
            styles={{ root: { height: "32px" } }}
            variant="outline"
            className="font-medium hover:bg-DS-gray-25 text-DS-gray-600 border-DS-gray-300"
            onClick={() => modals.closeAll()}
          >
            Cancel
          </Button>
          <Button
            disabled={unpauseEditsMutation.isPending}
            onClick={() => {
              unpauseEditsMutation.mutate(fileId || "");
            }}
            type="submit"
            styles={{ root: { height: "32px" } }}
            variant="filled"
            className="ml-5 disabled:bg-gray-50 font-medium hover:bg-DS-brand-500 bg-DS-brand-400"
          >
            Un-pause edits
          </Button>
        </div>
      )}
    </div>
  );
};
