import ExcelJS from "exceljs";
import React from "react";
import { ChangeRecord } from "../../pages/version-diffs/helpers/transformData";
import { TWorkbookDiffSheet } from "../../typings";
import { GridRow, SelectedSheet } from "./types.ts";

export const MIN_COLUMNS = 24;
export const MIN_ROWS = 100;

export const rowKeyGetter = (row: GridRow) => {
  return row.id;
};

export const getSheetColumnCount = (ws: ExcelJS.Worksheet | undefined) => {
  if (!ws) return MIN_COLUMNS;
  return Math.max(ws.columnCount, MIN_COLUMNS);
};

export const generateExcelColumnNames = (N: number) => {
  const columnNames = [""];
  for (let i = 1; i <= N; i++) {
    let columnName = "";
    let columnNumber = i;
    while (columnNumber > 0) {
      // Adjust for 1-based indexing
      const remainder = (columnNumber - 1) % 26;
      // Generate column letter from remainder
      columnName = String.fromCharCode(65 + remainder) + columnName;
      columnNumber = Math.floor((columnNumber - 1) / 26);
    }
    columnNames.push(columnName);
  }
  return columnNames;
};

export const getChangedColumns = (
  changes: TWorkbookDiffSheet[] | null | undefined,
  selectedSheet: SelectedSheet
) => {
  const changedColumns = new Set<string>();
  if (!changes) return changedColumns;
  changes.forEach((change, idx) => {
    if (
      change.sheetName === selectedSheet.wsName ||
      (selectedSheet.wsName === "" && idx === 0)
    ) {
      change.added?.map((cell) => changedColumns.add(cell.cell));
      change.removed?.map((cell) => changedColumns.add(cell.cell));
      change.commonDiffs?.map((cell) => changedColumns.add(cell.cell));
    }
  });
  return changedColumns;
};
export const findChangeByCell = (
  changes: ChangeRecord[],
  selectedCell: string
) => {
  let foundChange;
  changes.forEach((userChanges) => {
    const allChanges = userChanges.changes;
    foundChange = allChanges.find((item) => {
      return item.cell === selectedCell;
    });
  });
  return foundChange;
};

export const applyHighlightStyle = (cell: string) => {
  const target = document.getElementsByClassName(
    `cell-${cell}`
  )[0] as HTMLElement | null;
  const parent = target?.parentElement;

  const highlights = document.querySelectorAll(".highlight-cell");
  highlights.forEach((el) => el.classList.remove("highlight-cell"));
  const parentHighlights = document.querySelectorAll(".highlight-cell-parent");
  parentHighlights.forEach((el) =>
    el.classList.remove("highlight-cell-parent")
  );

  void target?.offsetWidth;
  target?.classList.add("highlight-cell");
  parent?.classList.add("highlight-cell-parent");
};

export function scrollToCell({
  gridRef,
  cell,
}: {
  gridRef: React.RefObject<any>;
  cell: string;
}) {
  if (!gridRef?.current) return;
  const grid = gridRef.current;
  const cellCol = cell.match(/[a-zA-Z]+/g);
  const cellRow = cell.match(/\d+/g);
  const cellColNum = cellCol
    ? cellCol[0]
        .split("")
        .reduce((acc, c) => acc * 26 + c.charCodeAt(0) - 64, 0)
    : 0;

  const cellRowNum = cellRow ? parseInt(cellRow[0]) : 0;

  grid.scrollToCell({ idx: cellColNum, rowIdx: cellRowNum });

  setTimeout(() => {
    applyHighlightStyle(cell);
  }, 300);
}

export const getAllChangedCells = (changes: ChangeRecord[]) => {
  let allCells;
  changes.forEach((userChanges) => {
    const allChanges = userChanges.changes;
    allCells = allChanges.map((item) => {
      return item.cell;
    });
  });
  return allCells || [];
};

export const getValue = (cell: ExcelJS.Cell): string => {
  if (!cell?.value) return "";
  if ((cell?.result as any)?.error) {
    return (cell.result as any)?.error?.toString() || "#ERROR";
  }
  let value: ExcelJS.CellRichTextValue;
  switch (cell.type) {
    case ExcelJS.ValueType.Merge:
      return "";
    case ExcelJS.ValueType.Date:
    case ExcelJS.ValueType.Number:
    case ExcelJS.ValueType.String:
    case ExcelJS.ValueType.Boolean:
    case ExcelJS.ValueType.Null:
      return typeof cell.value === 'object' ? JSON.stringify(cell.value) : String(cell.value || "");
    case ExcelJS.ValueType.Formula:
    case ExcelJS.ValueType.SharedString:
      return cell.result ? cell.result.toString() : "";
    case ExcelJS.ValueType.Hyperlink:
      return cell.text ? cell.text.toString() : "";
    case ExcelJS.ValueType.Error:
      return (cell.value as ExcelJS.CellErrorValue).error.toString();
    case ExcelJS.ValueType.RichText:
      value = cell.value as ExcelJS.CellRichTextValue;
      return value.richText.map((text) => text.text).join("");
    default:
      return typeof cell.value === 'object' ? JSON.stringify(cell.value) : String(cell.value);
  }
};

export function countEmptyValuesAfterKey(row: GridRow, key: string) {
  const keys = Object.keys(row);
  const startIndex = keys.indexOf(key) + 1;
  let count = 1;

  for (let i = startIndex; i < keys.length; i++) {
    let value: string;
    const src_cell = row[keys[i]] || "";
    if (typeof src_cell === "string") {
      value = src_cell;
    } else {
      value = src_cell.value;
    }
    if (value === "") {
      count++;
    } else {
      break;
    }
  }

  return count;
}
