import { Button, Tooltip } from "@mantine/core";
import { ReactNode } from "react";

interface IconAndCountButtonProps {
  onClick: () => void;
  icon?: ReactNode;
  label: string;
  count?: number;
  disabled?: boolean;
  tooltip?: string;
  className?: string;
  variant?: "outline" | "filled";
}

const IconAndCountButton = ({
  onClick,
  icon,
  label,
  count,
  disabled = false,
  tooltip,
  className = "",
  variant = "outline",
}: IconAndCountButtonProps) => {
  const button = (
    <Button
      styles={{
        root: { height: "32px" },
      }}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      className={`ml-4 px-4 h-[36px] text-sm border-DS-gray-300 text-DS-gray-600 disabled:bg-DS-gray-100 disabled:text-DS-gray-400 disabled:border-white disabled:cursor-not-allowed ${className}`}
    >
      {icon && <span className="mr-2">{icon}</span>}
      <span className="flex items-center font-medium">{label}</span>
      {count !== undefined && (
        <span className="font-semibold bg-gray-100 px-2 py-1 rounded-full ml-2">
          {count}
        </span>
      )}
    </Button>
  );

  if (tooltip && !disabled) {
    return (
      <Tooltip
        className="text-xs mr-2 hover:bg-DS-gray-100 p-2 rounded"
        label={tooltip}
      >
        {button}
      </Tooltip>
    );
  }

  return button;
};

export default IconAndCountButton; 