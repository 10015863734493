import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";

export const successNotification = ({
  title,
  message,
  icon,
  autoClose,
}: {
  title?: string;
  message?: string;
  icon?: React.ReactNode;
  autoClose?: boolean | number;
}) => {
  notifications.clean();
  notifications.show({
    loading: false,
    title: title,
    message: message,
    color: "teal",
    icon: icon || <IconCheck size={20} />,
    autoClose: autoClose || true,
  });
};

export const loadingNotification = ({
  title,
  message,
  autoClose,
}: {
  title?: string;
  message?: string;
  icon?: React.ReactNode;
  autoClose?: boolean | number;
}) => {
  notifications.clean();
  notifications.show({
    loading: true,
    title: title,
    message: message,
    autoClose: autoClose || false,
  });
};

export const regularNotification = ({
  title,
  message,
  icon,
  autoClose,
}: {
  title?: string;
  message?: string;
  icon?: React.ReactNode;
  autoClose?: boolean | number;
}) => {
  notifications.clean();
  notifications.show({
    loading: false,
    title: title,
    message: message,
    color: "gray",
    icon: icon,
    autoClose: autoClose ? autoClose : true,
  });
};
