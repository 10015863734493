export enum IssueType {
  RESOLVE = "RESOLVE",
  REVIEW = "REVIEW",
  APPROVE = "APPROVE",
}

export enum IssueStatus {
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  PLANNED = "PLANNED",
  ENROLLED = "ENROLLED",
}

export const IssueTypeTextMapping = {
  [IssueType.RESOLVE]: "Resolve this",
  [IssueType.REVIEW]: "Review this",
  [IssueType.APPROVE]: "Approve this",
};

export const IssueStatusTextMapping = {
  [IssueStatus.TODO]: "To do",
  [IssueStatus.IN_PROGRESS]: "In progress",
  [IssueStatus.DONE]: "Done",
};

export const IssueStatusColorMapping = {
  [IssueStatus.IN_PROGRESS]: { text: "#10D046", bg: "#C5FBED" },
  [IssueStatus.TODO]: { text: "#424A56", bg: "#E5E5E5" },
  [IssueStatus.DONE]: { text: "#496CE8", bg: "#DEEBFF" },
};

export const CHANGES_SEEN_STATUS = "seenChanges";
