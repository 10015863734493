import { IconAlertCircle, IconLoader2, IconReload, IconX } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { ReactComponent as ChevronLeft } from "../../../assets/svg/DS/sys-icons/Chevron Left.svg";
import ButtonTabs from "../../../components/DS/ButtonTabs";
import IconButton from "../../../components/DS/IconButton";
import { useMyDrive } from "../../../hooks/utility";
import { getFolderContent, getSharedWithMe } from "../../../services/graph";
import { useActions, useFileManagerActiveTab, useShowFileManagerDelayWarning } from "../../../store";
import { FileData, IWorkspaceItem } from "../../../typings";
import { EnrollFilesTabs } from "./EnrollFilesDrawer";
import FileRow from "./EnrollFilesDrawerRow";
import { Button } from '@mantine/core';

type FolderContentType = Partial<FileData> & {
  files: (FileData & { checked?: boolean })[];
};

function parseFolderContent(data: FolderContentType) {
  return {
    ...data,
    files: data.files.filter((item) => item.isDir || item.extension === "xlsx"),
  };
}

// Extracted LoadingSpinner component
const LoadingSpinner = () => (
  <div className="w-full h-[60vh] flex items-center justify-center">
    <IconLoader2 size={16} className="w-6 h-6 animate-spin text-primary-blue" />
  </div>
);

interface EnrollFilesDrawerProps {
  setCheckedWorkspaceItems: React.Dispatch<
    React.SetStateAction<IWorkspaceItem[]>
  >;
  refetch: any;
}

const EnrollFilesDrawer: React.FC<EnrollFilesDrawerProps> = ({
  setCheckedWorkspaceItems,
  refetch,
}) => {
  const { setDrawerOpened, setFileManagerActiveTab, setShowFileManagerDelayWarning } = useActions();
  const showFileManagerDelayWarning = useShowFileManagerDelayWarning();
  const stateActiveTab = useFileManagerActiveTab();
  const [activeTab, setActiveTab] = useState(stateActiveTab);
  const { drive } = useMyDrive();
  const [folderContent, setFolderContent] = useState<FolderContentType>({
    files: [],
  });
  const [myDriveBreadcrumbs, setMyDriveBreadcrumbs] = useState<
    Partial<FileData>[]
  >([]);

  const [sharedWithMeBreadcrumbs, setSharedWithMeBreadcrumbs] = useState<
    Partial<FileData>[]
  >([]);

  const [currentFolder, setCurrentFolder] = useState<
    Partial<FileData> | undefined
  >(undefined);

  const isRoot: boolean =
    (activeTab === 0 && !myDriveBreadcrumbs.length) ||
    (activeTab === 1 && !sharedWithMeBreadcrumbs.length);

  const {
    isLoading: isLoadingDrive,
    isFetching: isFetchingDrive,
    data: dataDrive,
    isSuccess: isSuccessDrive,
    refetch: refetchDrive,
  } = useQuery({
    queryKey: ["folder", currentFolder?.id, drive?.id],
    queryFn: () =>
      getFolderContent(
        currentFolder?.id,
        currentFolder?.parentReference?.driveId || drive?.id
      ),
  });

  const {
    isLoading: isLoadingShared,
    isFetching: isFetchingShared,
    data: dataShared,
    isSuccess: isSuccessShared,
    refetch: refetchShared,
  } = useQuery({
    queryKey: ["shared-with-me"],
    queryFn: getSharedWithMe,
  });

  const loading =
    isLoadingShared || isLoadingDrive || isFetchingShared || isFetchingDrive;

  const onClickItem = (file: FileData & { checked?: boolean }) => {
    if (!file.isDir) {
      // if it's a file, toggle the checked state
      setFolderContent({
        ...folderContent,
        files: folderContent.files.map((f) =>
          f.id === file.id ? { ...f, checked: !f.checked } : f
        ),
      });
    } else {
      // if it's a folder, set it in the breadcrumbs
      if (activeTab === 0) {
        setMyDriveBreadcrumbs([...myDriveBreadcrumbs, file]);
        setCurrentFolder(file);
      } else {
        setSharedWithMeBreadcrumbs([...sharedWithMeBreadcrumbs, file]);
        setCurrentFolder(file);
      }
    }
  };

  const handleTabSwitch = (newTab: SetStateAction<number>) => {
    setActiveTab(newTab);
    setFileManagerActiveTab(newTab as number);
    setCurrentFolder(
      newTab === 0
        ? myDriveBreadcrumbs[myDriveBreadcrumbs.length - 1]
        : sharedWithMeBreadcrumbs[sharedWithMeBreadcrumbs.length - 1]
    );
  };

  useEffect(() => {
    setCheckedWorkspaceItems(
      folderContent.files
        .filter((f) => !f.isDir && f.checked)
        .map((f) => ({
          driveMsId: f.parentReference.driveId,
          modified: f.modified,
          msId: f.id,
          name: f.name,
        })) as IWorkspaceItem[]
    );
  }, [folderContent, setCheckedWorkspaceItems]);

  useEffect(() => {
    if (activeTab === 0) {
      setCurrentFolder(myDriveBreadcrumbs[myDriveBreadcrumbs.length - 1]);
    } else {
      setCurrentFolder(
        sharedWithMeBreadcrumbs[sharedWithMeBreadcrumbs.length - 1]
      );
    }
  }, [activeTab, myDriveBreadcrumbs, sharedWithMeBreadcrumbs]);

  const refreshFiles = useCallback(() => {
    if (activeTab === 0) {
      refetchDrive();
    } else {
      refetchShared();
    }
  }, [activeTab, refetchDrive, refetchShared]);

  useEffect(() => {
    if (currentFolder) {
      refreshFiles();
    }
  }, [currentFolder, refreshFiles]);

  useEffect(() => {
    if (activeTab === 0) {
      if (isSuccessDrive) {
        setFolderContent(parseFolderContent(dataDrive));
      }
    } else if (activeTab === 1) {
      if (isRoot) {
        if (isSuccessShared) {
          setFolderContent(parseFolderContent(dataShared));
        }
      } else if (isSuccessDrive) {
        setFolderContent(parseFolderContent(dataDrive));
      }
    }
  }, [
    isSuccessShared,
    isSuccessDrive,
    dataShared,
    dataDrive,
    activeTab,
    isRoot,
  ]);

  return (
    <div>
      <div
        className="flex py-4 px-8 grow-0 items-center justify-between"
        id="file-manager-header"
      >
        <ButtonTabs
          items={EnrollFilesTabs}
          activeTab={activeTab}
          setActiveTab={handleTabSwitch}
        />
        <Button
          variant="default"
          color="gray"
          size='xs'
          onClick={refreshFiles}
          loading={loading}
          loaderProps={{ type: 'dots' }}
        >
          <IconReload size={14} />
        </Button>
      </div>
      {showFileManagerDelayWarning && (
        <div id="file-manager-delay-warning" className="flex items-center gap-2 bg-yellow-50 text-sm text-DS-gray-450 px-8 py-2">
          <div>
            <IconAlertCircle size={16} />
          </div>
          <div className="flex-grow">
            Files may sometimes take up to 30 minutes to load from Microsoft servers
          </div>
          <div 
            className="cursor-pointer" 
            onClick={() => setShowFileManagerDelayWarning(false)}
          >
            <IconX size={16} />
          </div>
        </div>
      )}
      {loading && <LoadingSpinner />}
      {!loading && (
        <table className="table-auto text-sm text-DS-gray-800 min-w-full">
          <thead className="bg-DS-gray-50 border-b w-full">
            <tr className="border-t text-DS-gray-450 text-left">
              <th className="py-2 font-normal px-2" colSpan={2}>
                <div className="pl-6">Name</div>
              </th>
              <th className="py-2 font-normal">
                <div>Modified</div>
              </th>
              <th className="py-2 font-normal">
                <div className="text-right pr-8">File size</div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!isRoot && (
              <tr
                key={folderContent.parentId}
                itemID={`${folderContent.parentId}`}
                className="h-16 border-b"
                draggable={false}
              >
                <td className="py-2">
                  <div className="pl-[26px]">
                    <IconButton
                      variant="ghost"
                      onClick={() => {
                        if (activeTab === 0) {
                          setMyDriveBreadcrumbs(
                            myDriveBreadcrumbs.slice(0, -1)
                          );
                        } else {
                          setSharedWithMeBreadcrumbs(
                            sharedWithMeBreadcrumbs.slice(0, -1)
                          );
                        }
                      }}
                      Icon={ChevronLeft}
                    />
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex items-center">
                    <div>{currentFolder?.name || "Go back"}</div>
                  </div>
                </td>
                <td className="py-2"></td>
                <td className="py-2"></td>
              </tr>
            )}
            {folderContent?.files?.map((file: FileData, idx: number) => (
              <FileRow
                refetch={refetch}
                key={file.id}
                file={file}
                idx={idx}
                onClick={onClickItem}
                closeDrawer={() => setDrawerOpened(false)}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EnrollFilesDrawer;
