import {
  Button,
  CSSVariablesResolver,
  MantineTheme,
  Tooltip,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";

/**
 * CSS variables resolver for Mantine theme
 */
export const cssVariablesResolver: CSSVariablesResolver = () => ({
  variables: {
    "--mantine-color-placeholder": "#808A9E",
  },
  light: {
    "--mantine-color-placeholder": "#808A9E",
  },
  dark: {
    "--mantine-color-placeholder": "#808A9E",
  },
});

/**
 * Modal overlay props configuration
 */
export const modalOverlayProps = {
  backgroundOpacity: 0.16,
  color: "#222533",
};

/**
 * Mantine theme configuration
 */
export const mantineTheme: Partial<MantineTheme> = {
  components: {
    Tooltip: Tooltip.extend({
      defaultProps: { color: "#344054" },
    }),
    Button: Button.extend({
      classNames: {
        inner: "font-[500]",
      },
    }),
    Notifications: Notifications.extend({
      styles: () => ({
        root: { width: "fit-content" },
        notification: {
          zIndex: 1000000,
          backgroundColor: "#344054",
          borderRadius: "6px",
          padding: "8px 8px 8px 24px",
        },
      }),
    }),
  },
};
