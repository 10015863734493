import { CurrentUserAvatar } from "../Avatar";
import Logo from "../LogoNew";
import { useNavigate } from "react-router-dom";
import { Menu, Text } from "@mantine/core";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMe } from "../../../hooks/utility";

function TopSection() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const me = useMe();

  return (
    <div className="px-5 py-[9px] flex shrink-0 justify-between items-center">
      <Logo onClick={() => navigate("/")} />
      {isAuthenticated && (
        <Menu>
          <Menu.Target>
            <div className="flex items-center">
              <CurrentUserAvatar />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              className="body-md-Regular"
              onClick={() => navigate("/logout")}
            >
              Log out
            </Menu.Item>
            <Menu.Divider />
            <Menu.Label className="body-md-Regular">Logged in as:</Menu.Label>
            <Menu.Item
              className="body-md-Regular"
              onClick={() => navigate("/logout")}
            >
              <div className="flex flex-col">
                <Text size="sm" fw={500}>
                  {me.displayName}
                </Text>
                <Text c="dimmed" size="xs">
                  {me.email}
                </Text>
              </div>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
    </div>
  );
}

export default TopSection;
