import React, { useCallback } from "react";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

type WelcomeAndInviteTeamModalProps = {
  opened: boolean;
  onClose: () => void;
  onInvite: () => void;
};

const WelcomeAndInviteTeamModal: React.FC<WelcomeAndInviteTeamModalProps> = ({
  opened,
  onClose,
  onInvite,
}) => {

  const openModal = useCallback(() => {
    modals.open({
      id: "welcome-modal",
      onClose,
      size: "auto",
      title: (
        <div className="text-DS-gray-800 text-xl font-semibold w-full">
          Welcome to Rockhopper!
        </div>
      ),
      centered: true,
      withCloseButton: false, // Disable the close "x" button
      styles: {
        title: {
          textAlign: "center", // Center the title text
          width: "100%", // Ensure it takes full width
        },
        root: {
          padding: "2.5rem", // Set the modal padding to 2.5rem
        },
      },
      children: (
        <div className="text-center w-[22.5rem] text-sm">
          <p className="text-DS-gray-500">
            Invite your team to get started. You can also do this later on.
          </p>
          <div className="flex justify-center gap-6 mt-4">
            <Button
              variant="outline"
              className="border-DS-gray-300 font-medium px-3 hover:bg-DS-gray-100 text-DS-gray-600 focus:outline-none focus-visible:ring-0 text-sm"
              onClick={onClose}
            >
              Skip for now
            </Button>
            <Button
              variant="filled"
              className="bg-DS-brand-400 hover:bg-DS-brand-500 font-medium px-3 focus:outline-none focus-visible:ring-0 text-sm"
              onClick={() => {
                onInvite();
              }}
            >
              Invite team
            </Button>
          </div>
        </div>
      ),
    });
  }, [onClose, onInvite]);

  React.useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [opened, openModal]);

  return null; // The component itself doesn't render anything directly
};

export default WelcomeAndInviteTeamModal;
