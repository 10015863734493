export default {
  "&singleLine": {
    control: {
      display: "inline-block",
    },
  },

  "&multiLine": {
    control: {
      overflow: "hidden",
    },
    highlighter: {
      color: "#3C6FF6",
      backgroundColor: "transparent",
      lineHeight: "inherit",
      overflow: "hidden",
    },

    input: {
      input: "100%",
      outline: 0,
      border: 0,
      padding: 25,
      paddingBottom: 0,
      overflow: "hidden",
    },
  },

  suggestions: {
    list: {
      width: 200,
      backgroundColor: "white",
      border: "0px",
      borderRadius: 4,
      fontSize: 10,
      boxShadow:
        "10px 9px 10px 8px rgba(0, 0, 0, 0.05), 0px 6px 10px 0px rgba(0, 0, 0, 0.08)",
      overflow: "auto",
      maxHeight: 140,
      zIndex: 1000000,
    },

    item: {
      padding: "5px 15px",
      fontSize: 13,
      fontWeight: 500,
      color: "#344054",
      borderBottom: "0.6px solid #34405410",
      "&focused": {
        backgroundColor: "#F9FAFB",
      },
    },
  },
};
