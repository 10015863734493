import { useAccount } from "@azure/msal-react";
import { Drive } from "@microsoft/microsoft-graph-types-beta";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectEvent } from "use-effect-event";
import { getMyDrive } from "../services/graph";
import { getInternalUserByMsId } from "../services/user";
import { UseMeType } from "../typings";
import { useQuery } from "@tanstack/react-query";

export const useMyDrive = () => {
  const [drive, setDrive] = useState<Drive | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getDrive = async () => {
      try {
        const drive = await getMyDrive();
        setDrive(drive);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    getDrive();
  }, []);

  return { drive, loading, error };
};

export const useMe = (): UseMeType => {
  const account = useAccount();
  const me = useMemo(
    () => ({
      email: account?.username || "",
      id: account?.localAccountId || "",
      displayName: account?.name || account?.username || "",
    }),
    [account]
  );
  return me;
};

export const useInternalMe = () => {
  const me = useMe();
  const {
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = useQuery({
    queryKey: [`user_${me.id}`],
    queryFn: () => getInternalUserByMsId(me.id),
  });

  return { userData, userLoading, userError, me };
};

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export function useInterval(
  callback: () => void,
  options: { delay?: number | null; immediate?: boolean }
) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Run the callback once immediately if immediate is set.
  useEffect(() => {
    if (!options.immediate) {
      return;
    }

    savedCallback.current();
  }, [options.immediate]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!options.delay && options.delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), options.delay);

    return () => clearInterval(id);
  }, [options]);
}

export interface IUseIntervalWhenOptions {
  ms: number;
  when: boolean;
  startImmediately?: boolean;
}

/**
 *
 * @param cb
 * @param options.ms interval in milliseconds
 * @param options.when run when true, pause when false
 * @param options.startImmediately  run the callback immediately when true
 * @returns
 */
export function useIntervalWhen(
  cb: (...args: any[]) => void,
  { ms, when, startImmediately }: IUseIntervalWhenOptions
) {
  const id = useRef<any>(null);
  const onTick = useEffectEvent(cb);
  const immediatelyCalled = useRef(startImmediately === true ? false : null);

  const handleClearInterval = useCallback(() => {
    window.clearInterval(id.current);
    immediatelyCalled.current = false;
  }, []);

  useEffect(() => {
    if (when === true) {
      id.current = window.setInterval(onTick, ms);

      if (startImmediately === true && immediatelyCalled.current === false) {
        onTick();
        immediatelyCalled.current = true;
      }

      return handleClearInterval;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms, when, startImmediately, handleClearInterval]);

  return handleClearInterval;
}
