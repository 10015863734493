import { useLocation, useNavigate } from "react-router-dom";
import Separator from "../../../components/DS/Separator";
import { IFileVersion, IWorkspaceItem } from "../../../typings";
import { CreateFileBody, CreateFileHeader } from "./createNewFileFromVersion";
import { RestoreChangeBody, RestoreChangeHeader } from "./restoreChanges";
import { Button, Popover, Tooltip } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { getVersionText } from "../utils";
import { useEnrolledFile, useVersions } from "../../../store";
import {
  findLatestVersionId,
  getPreviousNonDiscardedVersion,
} from "../../../store/utils";
import {
  RequestPauseBody,
  RequestPauseHeader,
} from "../../PauseEdits/requestPauseModal";
import {
  EditsPausedBody,
  EditsPausedHeader,
} from "../../PauseEdits/editsPausedModal";
import { useQuery } from "@tanstack/react-query";
import { useMe } from "../../../hooks/utility";
import { getFileActiveUsers } from "../../../services/file";

type LatestVersionOption = {
  label: string;
  value: {
    majorVersion: number;
    minorVersion: number;
    patchVersion: number;
  };
};

export function VersionDiffActions({
  version,
  versionOptions,
  refetchVersions,
  refetchFile,
}: {
  version: IFileVersion;
  versionOptions?: LatestVersionOption[];
  refetchVersions?: () => void;
  refetchFile?: () => void;
}) {
  const isDiffPage = useLocation().pathname.includes("/changes");
  const enrolledFile = useEnrolledFile();
  const versions = useVersions();
  const navigate = useNavigate();
  const me = useMe();
  const { data: activeUsers } = useQuery({
    queryKey: ["activeUsers", enrolledFile?.msId],
    queryFn: () => getFileActiveUsers(enrolledFile?.msId || ""),
    enabled: !!enrolledFile?.msId,
  });

  const canPerformActions = activeUsers?.length === 0;
  const otherUserRequestedPause = enrolledFile?.pauseEditsRequestedBy
    ? enrolledFile?.pauseEditsRequestedBy !== me?.id
    : false;

  const { pathname } = useLocation();

  const latestVersion = versions.find(
    (version) =>
      version.internalId ===
      findLatestVersionId(enrolledFile as IWorkspaceItem, versions)
  );

  const prevVersion = getPreviousNonDiscardedVersion(
    versions,
    version.internalId
  );

  const revertToVersion = getVersionText(
    isDiffPage && prevVersion ? prevVersion : version
  );

  const getRevertModalContent = () => {
    if (otherUserRequestedPause) {
      return {
        header: <EditsPausedHeader version={revertToVersion} type="revert" />,
        body: (
          <EditsPausedBody
            activeUsers={activeUsers || []}
            pausedOn={enrolledFile?.pauseEditsRequestedAt || ""}
            pausedBy={enrolledFile?.pauseEditsRequestedBy || ""}
            onSuccess={() => {
              console.log("success");
            }}
            type="revert"
          />
        ),
      };
    }
    if (!canPerformActions) {
      return {
        header: <RequestPauseHeader version={revertToVersion} type="revert" />,
        body: (
          <RequestPauseBody
            onSuccess={() => {
              modals.closeAll();
              if (isDiffPage) {
                navigate(-1);
              } else {
                if (refetchFile) refetchFile();
              }
            }}
            activeUsers={activeUsers || []}
            type="revert"
          />
        ),
      };
    }
    return {
      header: <RestoreChangeHeader title={`Revert to ${revertToVersion}`} />,
      body: (
        <RestoreChangeBody
          latestVersion={latestVersion}
          disclaimer={`This will create a new version of the file with the contents of ${getVersionText(
            isDiffPage && prevVersion ? prevVersion : version
          )} Don’t worry, you can still access any other versions created before reverting.`}
          versionInternalId={
            isDiffPage && prevVersion
              ? prevVersion.internalId
              : version.internalId
          }
          versionOptions={versionOptions as LatestVersionOption[]}
          onFinish={() => {
            if (pathname.includes("versions")) {
              if (refetchVersions) refetchVersions();
            }
          }}
        />
      ),
    };
  };

  return (
    <Popover
      classNames={{ dropdown: "p-0 mr-4 z-[1000]" }}
      position="bottom-end"
      withArrow={false}
      shadow="md"
    >
      <Popover.Target>
        <div className="h-full hover:bg-DS-gray-100 p-2 rounded relative z-[100000] cursor-pointer flex justify-center items-center">
          <IconDots size={18} />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        {version.wasDiscarded ? (
          <Tooltip
            className="w-[200px] h-auto !break-words whitespace-pre-wrap"
            position="bottom"
            hidden={Boolean(!enrolledFile?.hasUncommittedChanges)}
            label={
              "Cannot restore discarded changes while this file has uncommitted changes."
            }
          >
            <Button
              className="w-full hover:bg-DS-brand-50 text-DS-gray-800 font-normal disabled:!text-gray-500 body-md-Regular py-2  cursor-pointer"
              disabled={Boolean(enrolledFile?.hasUncommittedChanges)}
            >
              Restore discarded changes
            </Button>
          </Tooltip>
        ) : (
          <Tooltip
            className="w-[200px] h-auto !break-words whitespace-pre-wrap"
            position="bottom"
            hidden={Boolean(!enrolledFile?.hasUncommittedChanges)}
            label={
              "Cannot revert to a previous version while this file has uncommitted changes."
            }
          >
            <Button
              className="w-full flex !text-left hover:bg-DS-brand-50 text-DS-gray-800 font-normal disabled:!text-gray-500 body-md-Regular py-2  cursor-pointer"
              disabled={Boolean(enrolledFile?.hasUncommittedChanges)}
              onClick={() => {
                modals.open({
                  size: 540,
                  yOffset: 200,
                  styles: { body: { padding: 0, height: "fit-content" } },
                  title: getRevertModalContent()?.header,
                  children: getRevertModalContent()?.body,
                });
              }}
            >
              Revert to this version
            </Button>
          </Tooltip>
        )}
        <Separator />
        <div
          onClick={() => {
            modals.open({
              size: 540,
              yOffset: 200,
              styles: { body: { padding: 0, height: "fit-content" } },
              title: <CreateFileHeader version={version} />,
              children: (
                <CreateFileBody
                  version={version}
                  file={enrolledFile as IWorkspaceItem}
                />
              ),
            });
          }}
          className="body-md-Regular py-2 hover:bg-DS-brand-50 px-4 cursor-pointer"
        >
          Create new file from version
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}
