import { StateCreator } from "zustand";
import { getCommentWithCellRefs } from "../../helpers/strings";
import { ExtendedIdentity } from "@services/user/user.types";
import {
  IApiUser,
  IFileChat,
  IFileVersion,
} from "../../typings";

type LoadingStatus =
  | "idle"
  | "loading"
  | "success"
  | "error"
  | "sent"
  | "sending";

export type CommentSlice = {
  comments: IFileChat[];
  collaborators: ExtendedIdentity[] | undefined;
  visibleComments: IFileChat[];
  status: LoadingStatus;
  commentInput: string;
  appliedCommentsFilter: {
    type: "version" | "user";
    value: IFileVersion | IApiUser;
  } | null;
  isEditing: IFileChat | null;
  targetRef: React.RefObject<HTMLDivElement>;
  actions: {
    setVisibleComments: (comments: IFileChat[]) => void;
    setInitialCommentsData: (
      comments: IFileChat[],
      collaborators: ExtendedIdentity[] | undefined
    ) => void;
    sendComment: (comment: IFileChat) => void;
    setIsEditing: (comment: IFileChat | null) => void;
    setCommentStatus: (status: LoadingStatus) => void;
    deleteComment: (id: string | number) => void;
    setCommentInput: (input: string) => void;
    setCommentFilter: (
      filter: {
        type: "version" | "user";
        value: IFileVersion | IApiUser;
      } | null
    ) => void;
    clearCommentFilter: () => void;
    setTargetRef: (ref: React.RefObject<HTMLDivElement>) => void;
  };
};

export const createCommentsSlice: StateCreator<CommentSlice> = (set) => ({
  comments: [],
  visibleComments: [],
  status: "idle",
  commentInput: "",
  appliedCommentsFilter: null,
  isEditing: null,
  collaborators: undefined,
  targetRef: { current: null },
  actions: {
    setVisibleComments: (comments: IFileChat[]) => {
      const commentsWithCellRefs = comments.map((comment) =>
        getCommentWithCellRefs(comment)
      );
      set({ visibleComments: commentsWithCellRefs });
    },
    setTargetRef: (ref: React.RefObject<HTMLDivElement>) => {
      set({ targetRef: ref });
    },
    setInitialCommentsData: (
      comments: IFileChat[],
      collaborators: ExtendedIdentity[] | undefined
    ) => {
      const commentsWithCellRefs = comments.map((comment) =>
        getCommentWithCellRefs(comment)
      );

      set((state) => ({
        ...state,
        comments: commentsWithCellRefs,
        visibleComments: commentsWithCellRefs,
        collaborators,
      }));
    },
    sendComment: (comment: IFileChat) => {
      set((state) => ({
        comments: [...state.comments, getCommentWithCellRefs(comment)],
        visibleComments: [
          ...state.visibleComments,
          getCommentWithCellRefs(comment),
        ],
      }));
    },
    setIsEditing: (comment: IFileChat | null) => {
      set({ isEditing: comment });
    },
    setCommentStatus: (status: LoadingStatus) => {
      set({ status });
    },
    deleteComment: (id: string | number) => {
      set((state) => ({
        comments: state.comments.filter((c) => c.internalId !== id),
        visibleComments: state.visibleComments.filter(
          (c) => c.internalId !== id
        ),
      }));
    },
    setCommentInput: (input: string) => {
      set({ commentInput: input });
    },
    setCommentFilter: (
      filter: {
        type: "version" | "user";
        value: IFileVersion | IApiUser;
      } | null
    ) => {
      set((state) => {
        let filtered: IFileChat[] = [];

        if (!filter) {
          filtered = state.comments;
        }
        if (filter?.type === "user") {
          filtered = state.comments?.filter(
            (comment) =>
              comment.byUser.msId === (filter?.value as IApiUser).msId
          );
        }
        if (filter?.type === "version") {
          filtered = state.comments?.filter(
            (comment) =>
              comment.version.internalId ===
              (filter?.value as IFileVersion).internalId
          );
        }

        return {
          ...state,
          visibleComments: filtered,
          appliedCommentsFilter: filter,
        };
      });
    },
    clearCommentFilter: () => {
      set((state) => ({
        ...state,
        appliedCommentsFilter: null,
        visibleComments: state.comments,
      }));
    },
  },
});
