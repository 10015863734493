import { Button, Textarea } from "@mantine/core";
import { ReactComponent as DiscardIcon } from "../../../assets/svg/DS/version-lines/discardIcon.svg";
import { modals } from "@mantine/modals";
import React, { useState } from "react";
import {
  loadingNotification,
  successNotification,
} from "../../../helpers/notifications";
import { discardFileVersion } from "../../../services/versions";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query';

export const DiscardModalHeader = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="body-lg-Regular flex pr-4 pl-2 items-center">
        <DiscardIcon className="mr-4" />{" "}
        <span className="font-semibold text-sm">Discard changes?</span>
      </div>
    </div>
  );
};

export const DiscardModalBody = ({
  latestVersion,
}: {
  latestVersion: string;
}) => {
  const [status, setStatus] = useState<
    "idle" | "success" | "error" | "loading"
  >("idle");
  const [notes, setNotes] = React.useState("");
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const discardLiveVersion = async () => {
    try {
      if (status === "loading") return;
      setStatus("loading");
      loadingNotification({
        title: "Discarding your changes. Please wait...",
      });
      await discardFileVersion(params.fileId || "", notes);
      queryClient.invalidateQueries({ queryKey: ["enrolledFile"] });
      successNotification({ title: "Your changes are discarded" });
      navigate(`/file/${params.fileId}/versions`);
      setStatus("success");
      modals.closeAll();
    } catch (err) {
      setStatus("idle");
      console.log(err);
    }
  };

  return (
    <div className="flex overflow-x-hidden flex-col items-center border-t">
      <div className="body-lg-Regular flex px-4 items-center mt-8">
        <span className="font-normal inline-block ml-3 text-sm text-DS-gray-800">
          All the changes made since {latestVersion} will be discarded. Don’t
          worry, you can still recover these changes if necessary.
        </span>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          discardLiveVersion();
        }}
        className="flex flex-col pt-4 w-full border-t mt-8"
      >
        <div className="w-full border-b">
          <Textarea
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            styles={{
              input: { height: 120, width: "100%", textAlign: "left" },
            }}
            required
            placeholder="Write a short description of why the changes were discarded..."
            className="body-md-Regular pl-3 text-left  mx-1 w-full"
          />
        </div>
        <div className="flex w-full items-center justify-end p-4 ">
          <Button
            type="button"
            styles={{ root: { height: "32px" } }}
            variant="outline"
            className="font-medium text-DS-gray-600 border-DS-gray-300"
            onClick={() => modals.closeAll()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            styles={{ root: { height: "32px" } }}
            variant="blue"
            className="ml-5 bg-DS-red-400 disabled:bg-gray-50  font-medium"
          >
            Discard changes
          </Button>
        </div>
      </form>
    </div>
  );
};
