// import { Link } from "react-router-dom";
// import { ReactComponent as HelpIcon } from "../../../assets/svg/DS/sys-icons/Help.svg";
// import { ReactComponent as Help2Icon } from "../../../assets/svg/DS/sys-icons/Help 2.svg";

function TopSection() {
  return (
    <div className="flex flex-col gap-3 p-3 pb-8">
      {/* <Link
        to="/docs"
        className="flex items-center gap-3 px-[0.625rem] py-[0.375rem] h-8"
      >
        <HelpIcon />
        <span className="text-sm">Documentation</span>
      </Link>
      <Link
        to="/help"
        className="flex items-center gap-3 px-[0.625rem] py-[0.375rem] h-8"
      >
        <Help2Icon />
        <span className="text-sm">Help & Support</span>
      </Link> */}
    </div>
  );
}

export default TopSection;
