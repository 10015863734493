import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { UserChanges } from "../../../pages/version-diffs/components/ChangeLog/components/UserChange";
import { getCellHistory } from "../../../services/versions";
import { useActions, useEnrolledFile, useSelectedSheet } from "../../../store";
import { UserNameWithAvatar } from "../../DS/Avatar";
const getVersionName = (versionName: string) => {
  if (versionName?.toLowerCase()?.includes("uncommitted")) {
    return (
      <p className="bg-DS-yellow-100 px-2 rounded py-[2px] text-DS-yellow-700">
        {versionName}
      </p>
    );
  }
  if (versionName?.toLowerCase()?.includes("discarded")) {
    return (
      <p className="bg-DS-red-25 px-2 rounded py-[2px] text-DS-red-500">
        {versionName}
      </p>
    );
  }
  return (
    <p className="bg-DS-brand-25 px-2 rounded py-[2px] text-DS-brand-500">
      {versionName}
    </p>
  );
};

interface CellHistoryProps {
  cellRef: React.MutableRefObject<any>;
  versionName: string;
  cellId: string;
}

const CellHistory: React.FC<CellHistoryProps> = ({ cellRef, cellId }) => {
  const { setSelectedCellHistory, clearSelectedCellHistory } = useActions();

  const enrolledFile = useEnrolledFile();
  const { wsName } = useSelectedSheet();

  // fetch cell history
  const { data: historyData, isSuccess } = useQuery({
    queryKey: ["cellHistory", cellId, wsName, enrolledFile?.msId],
    queryFn: () => {
      if (enrolledFile) {
        return getCellHistory(cellId, wsName, enrolledFile.msId);
      }
    },
    enabled: true,
  });

  const [selectedCellHistoryIndex, setSelectedCellHistoryIndex] =
    useState<number>(0);

  const handleNextHistory = () => {
    setSelectedCellHistoryIndex(selectedCellHistoryIndex + 1);
  };

  const handlePreviousHistory = () => {
    setSelectedCellHistoryIndex(selectedCellHistoryIndex - 1);
  };

  useEffect(() => {
    if (isSuccess && historyData) {
      setSelectedCellHistory(historyData);
    }
  }, [isSuccess, historyData, setSelectedCellHistory]);

  // clear history on unmount
  useEffect(() => {
    return () => {
      clearSelectedCellHistory();
    };
  }, [clearSelectedCellHistory]);

  return (
    <div ref={cellRef} className="bg-white p-0 pb-4">
      <div className="p-4 px-6 border-b flex justify-between items-center">
        <div className="flex items-center">
          <p className="text-DS-gray-900 font-semibold">Edit History</p>
          <div className="text-xs ml-4">
            {historyData &&
              getVersionName(
                `${historyData[selectedCellHistoryIndex]?.majorVersion}.${historyData[selectedCellHistoryIndex]?.minorVersion}.${historyData[selectedCellHistoryIndex]?.patchVersion}`
              )}
          </div>
        </div>
        <div className="flex items-center">
          {selectedCellHistoryIndex !== 0 && (
            <IconChevronLeft
              onClick={handlePreviousHistory}
              size={18}
              className="text-DS-gray-500 cursor-pointer"
            />
          )}
          {historyData?.length &&
            selectedCellHistoryIndex !== historyData?.length - 1 && (
              <IconChevronRight
                onClick={handleNextHistory}
                size={18}
                className="text-DS-gray-500 ml-4 cursor-pointer"
              />
            )}
        </div>
      </div>
      {historyData && historyData[selectedCellHistoryIndex]?.changes[0] && (
        <div className="p-4 max-w-md">
          <UserNameWithAvatar
            user={{
              msId:
                historyData[selectedCellHistoryIndex]?.changes[0]?.user?.id ||
                "",
            }}
          />
          <div className="mt-3 ml-5">
            <UserChanges
              change={historyData[selectedCellHistoryIndex]?.changes[0]}
              gridRef={null}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CellHistory;
