import { Avatar as CoreAvatar } from "@mantine/core";
import { NullableOption } from "@microsoft/microsoft-graph-types-beta";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { FC, memo, useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../assets/svg/DS/sys-icons/User.svg";
import { formatDateTime } from "../../helpers/formatDate";
import { getInitials } from "../../helpers/strings";
import { truncateString } from "../../helpers/truncateString";
import { useMe } from "../../hooks/utility";
import { getProfilePhoto, getUserDetails } from "../../services/user";
import { ExtendedIdentity } from "@services/user/user.types";
import { IApiUser } from "../../typings";

const INITIALS_BG_COLORS = [
  "#717BBC",
  "#B683C8",
  "#76B3D6",
  "#60B48C",
  "#A8867B",
];

export const EmptyAvatar: FC = () => {
  return (
    <div className="w-6 h-6 rounded-full flex items-center justify-center border-[1.5px] border-DS-gray-400 border-dashed">
      <UserIcon className="w-[0.875rem] h-[0.875rem] text-DS-gray-500" />
    </div>
  );
};

export const Avatar: FC<{
  className?: string;
  user?: { id?: string; displayName: string };
}> = ({ className, user }) => {
  const { data: avatarUrl } = useQuery<string | null>({
    queryKey: ["avatar", user?.id],
    queryFn: () => getProfilePhoto(user?.id || ""),
    enabled: !!user?.id,
  });

  return (
    <div className={clsx("flex items-center", className)}>
      <CoreAvatar
        src={avatarUrl || null}
        alt={user?.displayName || ""}
        size={24}
        styles={{
          placeholder: {
            backgroundColor: "transparent",
          },
        }}
      >
        {!avatarUrl && !user?.displayName && <EmptyAvatar />}
        {!!user?.displayName && (
          <div
            className="p-4 text-white shrink-0 whitespace-nowrap"
            style={{
              backgroundColor:
                INITIALS_BG_COLORS[
                  user?.displayName.charCodeAt(0) % INITIALS_BG_COLORS.length
                ],
            }}
          >
            {getInitials(user?.displayName || "")}
          </div>
        )}
      </CoreAvatar>
    </div>
  );
};

export const CurrentUserAvatar: FC<{
  userMsId?: string;
  identity?: NullableOption<ExtendedIdentity> | undefined;
  className?: string;
}> = memo(({ identity, userMsId, className }) => {
  const [user, setUser] = useState<ExtendedIdentity>();
  const me = useMe();

  useEffect(() => {
    if (identity) {
      setUser(identity);
    } else if (userMsId) {
      getUserDetails(userMsId, me.id).then((user) => setUser(user));
    } else {
      setUser(me as ExtendedIdentity);
    }
  }, [identity, me, userMsId]);

  return (
    <div className={clsx("flex items-center", className)}>
      <Avatar
        user={{
          id: user?.id ?? undefined,
          displayName: user?.displayName?.trim() || user?.email || "",
        }}
      />
    </div>
  );
});

export const UserNameWithAvatar = ({
  user,
  date,
  truncate,
}: {
  user: IApiUser;
  date?: string;
  truncate?: number;
}) => {
  const me = useMe();
  const { data, isLoading } = useQuery<NullableOption<ExtendedIdentity>>({
    queryKey: ["userDetails", user.msId],
    enabled: me.id !== user.msId && !user.firstName && !user.username,
    queryFn: () => {
      return getUserDetails(user.msId);
    },
  });

  const getDisplayName = () => {
    if (me.id === user.msId) return me.displayName;
    if (me.id !== user.msId && !user.firstName && !user.username)
      return data?.displayName || "";
    if (user.username) return user.username;
    if (user.firstName) return `${user?.firstName} ${user?.lastName}`;
    return "";
  };

  const displayName = isLoading ? "" : getDisplayName();

  return (
    <div className="flex items-center min-w-0 grow">
      <Avatar
        user={{ id: user.msId, displayName: displayName }}
        className="mr-2 rounded-full"
      />
      <div className="flex items-center min-w-0">
        <div className="text-DS-gray-700 capitalize body-md-SemiBold truncate max-w-full overflow-hidden">
          {displayName ? (truncate ? truncateString(`${displayName}`, truncate) : displayName) : "Unknown user"}
        </div>
        {date && (
          <span className="ml-2 text-xs shrink-0 whitespace-nowrap">
            {formatDateTime(date, "hh:mm a")}
          </span>
        )}
      </div>
    </div>
  );
};

export const AvatarListFetcher = ({ msIds }: { msIds: string[] }) => {
  const me = useMe();
  const { data } = useQuery<ExtendedIdentity[]>({
    queryKey: ["userAvatarList", msIds],
    queryFn: () => {
      return Promise.all(msIds.map((msId) => getUserDetails(msId, me.id)));
    },
  });

  return (
    <div className="flex items-center">
      {data?.map((user) => (
        <Avatar
          user={{
            id: user.id ?? undefined,
            displayName: user?.displayName || "",
          }}
          className="mr-2 rounded-full"
        />
      ))}
    </div>
  );
};
