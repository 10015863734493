import { ChangesList } from "./components/ChangesList";

export const ChangeLog = () => {
  return (
    <div className="h-full w-full">
      <div className="max-h-full w-full">
        <ChangesList />
      </div>
    </div>
  );
};
