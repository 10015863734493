import { scrollToCell } from "../../../../../components/rk-xlsx-grid/utils";
import { useIntervalWhen } from "../../../../../hooks/utility";
import { getFileProcessingUnattributedChange } from "../../../../../services/file";
import { useActions, useEnrolledFile } from "../../../../../store";
import { ChangeTypes } from "../../../../../store/slices/DifferencesSlice";
import { TWorkbookDiffSheetCellProcessingStatus } from "../../../../../typings";
import { Change } from "../../../helpers/transformData";
import Chip from "./Chip";
import { PuffLoader } from "react-spinners";

type Props = {
  change: Change;
  gridRef?: any;
};

export const UserChanges = (props: Props) => {
  const {
    type,
    cell,
    before,
    after,
    notes,
    version,
    processingStatus,
    sheetName,
  } = props.change;

  const { setCellChange } = useActions();

  const currentFile = useEnrolledFile();

  const isProcessing = processingStatus === "processing";
  const renderContent = () => {
    if (type === ChangeTypes.CREATED_VERSION) {
      return (
        <div className="max-w-full">
          <div className="flex items-center">
            <p className="text-[13px] text-DS-gray-500 shrink-0">created</p>
            <Chip label={version as string} showTooltip={false} />
          </div>
          <p className="text-DS-gray-700 text-sm mt-2">"{notes}"</p>
        </div>
      );
    }
    if (type === ChangeTypes.ADDED) {
      return (
        <div className="text-sm flex items-center max-w-full text-DS-gray-500">
          added
          <Chip
            onClick={() => {
              scrollToCell({
                gridRef: props.gridRef,
                cell: cell as string,
              });
            }}
            classNames="!bg-green-50 !text-green-900"
            label={cell as string}
            truncate={false}
            showTooltip={false}
          />
          <Chip
            onClick={() => {
              scrollToCell({
                gridRef: props.gridRef,
                cell: cell as string,
              });
            }}
            classNames="!bg-DS-alpha-blue-gray-100 !text-DS-gray-600"
            label={after as string}
          />
        </div>
      );
    }
    if (type === ChangeTypes.REMOVED) {
      return (
        <div className="text-sm flex items-center max-w-full text-DS-gray-500">
          removed
          <Chip
            classNames="!bg-red-50 !text-red-900"
            label={cell as string}
            onClick={() =>
              scrollToCell({
                gridRef: props.gridRef,
                cell: cell as string,
              })
            }
            truncate={false}
            showTooltip={false}
          />
          <Chip
            classNames="!bg-DS-alpha-blue-gray-100 line-through !text-DS-gray-600"
            label={before as string}
            onClick={() =>
              scrollToCell({
                gridRef: props.gridRef,
                cell: cell as string,
              })
            }
          />
        </div>
      );
    }
    if (type === ChangeTypes.CHANGED) {
      return (
        <div className="text-sm flex text-DS-gray-500 items-center max-w-full">
          changed
          <Chip
            onClick={() => {
              scrollToCell({
                gridRef: props.gridRef,
                cell: cell as string,
              });
            }}
            truncate={false}
            label={cell as string}
            showTooltip={false}
          />
          <span className="ml-2">from</span>
          <Chip
            classNames="!bg-DS-alpha-blue-gray-100 !text-DS-gray-600"
            label={before as string}
            onClick={() => {
              scrollToCell({
                gridRef: props.gridRef,
                cell: cell as string,
              });
            }}
          />
          <span className="ml-2">to</span>
          <Chip
            onClick={() => {
              scrollToCell({
                gridRef: props.gridRef,
                cell: cell as string,
              });
            }}
            classNames="!bg-DS-alpha-blue-gray-100 !text-DS-gray-600"
            label={after as string}
          />
        </div>
      );
    }
    return null;
  };

  useIntervalWhen(
    async () => {
      if (currentFile?.msId && sheetName && cell) {
        const updatedChange = await getFileProcessingUnattributedChange(
          currentFile.msId,
          sheetName,
          cell
        );

        let changeType: ChangeTypes;
        if (updatedChange?.oldValue && updatedChange.newValue) {
          changeType = ChangeTypes.CHANGED;
        } else if (updatedChange.oldValue) {
          changeType = ChangeTypes.REMOVED;
        } else {
          changeType = ChangeTypes.ADDED;
        }

        // only update if the processing status has changed
        if (
          (updatedChange?.processingStatus as TWorkbookDiffSheetCellProcessingStatus) !==
          processingStatus
        ) {
          setCellChange({
            processingStatus:
              updatedChange?.processingStatus as TWorkbookDiffSheetCellProcessingStatus,
            sheetName: updatedChange.sheetName,
            type: changeType,
            before: updatedChange?.oldValue?.v,
            after: updatedChange?.newValue?.v,
            cell: updatedChange.cellAddress,
            user: { id: updatedChange.byUserMsId },
          });
        }
      }
    },
    {
      ms: 5000,
      when: isProcessing && !!currentFile?.msId && !!sheetName && !!cell,
    }
  );

  return (
    <div className="flex">
      <PuffLoader
        size={20}
        color="#5F8AF7"
        cssOverride={{
          flexShrink: 0,
          marginRight: "0.5rem",
        }}
        loading={isProcessing}
      />
      {renderContent()}
    </div>
  );
};
