import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

export interface SimpleButtonProps {
  to?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: React.ReactNode;
  variant?: "blue" | "green" | "outline";
  size?: "sm" | "md";
  disabled?: boolean;
  className?: string;
  [key: string]: unknown;
}

const SimpleButton: FC<SimpleButtonProps> = ({
  variant = "blue",
  size = "md",
  onClick = () => null,
  disabled = false,
  className,
  children,
  to,
  ...rest
}) => {
  let resultClassName;
  if (disabled) {
    resultClassName = clsx("bg-DS-gray-100 text-gray-400", className);
  } else {
    resultClassName = clsx(
      {
        "bg-DS-brand-400 hover:bg-DS-brand-500 text-white ": variant === "blue",
        "bg-DS-green-500 hover:bg-DS-green-600 text-white": variant === "green",
        "bg-white hover:bg-gray-25 text-DS-gray-600 border border-DS-gray-300":
          variant === "outline",
      },
      "cursor-pointer",
      className
    );
  }

  switch (size) {
    case "sm":
      resultClassName +=
        " px-[0.38rem] py-[0.12rem] text-xs rounded-md leading-[1.125rem]"; //WARNING: preserve the leading space
      break;
    case "md":
      resultClassName += " px-4 py-[6px] text-sm rounded";
      break;
  }

  resultClassName +=
    " transition-colors duration-200 flex items-center !font-medium";

  return !to ? ( // if no to prop, it's a button
    <div onClick={onClick} className={resultClassName} {...rest}>
      <span className="flex items-center !font-medium">{children}</span>
    </div>
  ) : (
    <Link
      to={to}
      className={resultClassName}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      <span className="flex items-center !font-medium">{children}</span>
    </Link>
  );
};

export default SimpleButton;

export interface PlaceholderButtonProps {
  onClick?: () => void;
  text: string;
  className?: string;
  [key: string]: unknown;
}

export const PlaceholderButton: FC<PlaceholderButtonProps> = ({
  onClick = () => null,
  text,
  className,
  ...rest
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        "cursor-pointer text-DS-gray-500 bg-DS-gray-50 hover:bg-DS-gray-100 rounded-2xl px-4 py-2",
        className
      )}
      {...rest}
    >
      <span className="flex items-center !font-medium">{text}</span>
    </div>
  );
};
