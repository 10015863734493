import { Tooltip } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconLoader2 } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { ReactComponent as OpenFileIcon } from "../../../assets/svg/DS/open-file.svg";
import { ReactComponent as DiscardedLine } from "../../../assets/svg/DS/version-lines/discarded.svg";
import { ReactComponent as DiscardedFirstLine } from "../../../assets/svg/DS/version-lines/discardedFirstLine.svg";
import { ReactComponent as LastLine } from "../../../assets/svg/DS/version-lines/lastLine.svg";
import { ReactComponent as LatestLine } from "../../../assets/svg/DS/version-lines/latest.svg";
import { ReactComponent as LatestIcon } from "../../../assets/svg/DS/version-lines/latestIcon.svg";
import { ReactComponent as LatestFull } from "../../../assets/svg/DS/version-lines/latestLine.svg";
import { ReactComponent as PreviousLine } from "../../../assets/svg/DS/version-lines/previous.svg";
import { ReactComponent as RevertedLine } from "../../../assets/svg/DS/version-lines/reverted.svg";
import { ReactComponent as RevertedFirstLine } from "../../../assets/svg/DS/version-lines/revertFirstLine.svg";
import { Avatar } from "../../../components/DS/Avatar";
import IdChip from "../../../components/DS/chips/Id";
import UserFetcher from "../../../components/UserFetcher";
import { VersionDiffActions } from "../../../features/Version/component/versionActions";
import { loadingNotification } from "../../../helpers/notifications";
import { truncateString } from "../../../helpers/truncateString";
import { getVersionFile } from "../../../services/file";
import { useEnrolledFile, useVersions } from "../../../store";
import {
  getPreviousNonDiscardedVersion,
  latestVersionSelectOptions,
} from "../../../store/utils";
import { IFileVersion } from "../../../typings";

const VersionList = ({
  refetchVersions,
  refetchFile,
}: {
  refetchVersions: () => void;
  refetchFile: () => void;
}) => {
  const enrolledFile = useEnrolledFile();
  const allVersions = useVersions();
  const latestVersion = enrolledFile?.latestVersion;
  const navigate = useNavigate();
  const versions = allVersions.filter((version) => version.majorVersion !== 0);

  const versionOptions = !latestVersion
    ? []
    : latestVersionSelectOptions(latestVersion);

  const getVersionDiffLink = (currentVersion: number) => {
    const previousVersion = getPreviousNonDiscardedVersion(
      versions,
      currentVersion
    );
    return `/file/${
      enrolledFile?.msId
    }/changes?currentVersion=${currentVersion}&previousVersion=${
      previousVersion?.internalId || ""
    }`;
  };

  const matchLatest = (version: IFileVersion, latestVersion?: IFileVersion) => {
    if (!latestVersion) return false;
    return (
      version.majorVersion === latestVersion.majorVersion &&
      version.minorVersion === latestVersion.minorVersion &&
      version.patchVersion === latestVersion.patchVersion
    );
  };

  const getVersionLineIcon = (data: IFileVersion, index: number) => {
    if (index === 0 && data.wasDiscarded)
      return <DiscardedFirstLine className="text-DS-gray-500" />;
    if (index === 0 && data.wasReverted) return <RevertedFirstLine />;
    if (index === 0 && matchLatest(data, latestVersion as IFileVersion))
      return versions.length === 1 ? (
        <LatestIcon />
      ) : (
        <LatestLine className="text-DS-green-500" />
      );
    if (index !== 0 && matchLatest(data, latestVersion as IFileVersion))
      return <LatestFull className="" />;

    if (data.wasDiscarded) {
      return <DiscardedLine className="text-DS-gray-500" />;
    }
    if (data.wasReverted) {
      return <RevertedLine className="text-DS-gray-500" />;
    }
    if (index === versions.length - 1) {
      return <LastLine className="text-DS-gray-500" />;
    }
    return <PreviousLine className="text-DS-gray-500" />;
  };

  function downloadBlob(blob: Blob, fileName: string): void {
    const blobUrl = URL.createObjectURL(blob);
    const anchorElement = document.createElement("a");
    anchorElement.href = blobUrl;
    anchorElement.download = fileName;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    URL.revokeObjectURL(blobUrl);
  }

  const downloadVersionFile = async (version: IFileVersion) => {
    loadingNotification({
      title: "Downloading version file...",
    });
    const latestVersion = await getVersionFile(version.internalId.toString());
    downloadBlob(latestVersion, enrolledFile?.name || "");
    notifications.clean();
    return null;
  };

  return (
    <table className="w-full">
      <thead className="py-1 w-full bg-DS-gray-50">
        <tr className="grid grid-cols-10 pl-3 items-center body-md-Regular text-DS-gray-450 justify-start  py-3 border-b">
          <th className="text-left pl-6 col-span-2">Version number</th>
          <th className="text-left col-span-4 w-full ml-[-4px]">Description</th>
          <th className=" text-left mr-8 col-span-3">Created on</th>
          <th className="" />
        </tr>
      </thead>
      <tbody className="w-full">
        {versions.map((data, index) => (
          <tr
            key={index}
            onClick={() => {
              if (enrolledFile) {
                if (
                  data.majorVersion === 1 &&
                  data.minorVersion === 0 &&
                  data.patchVersion === 0
                ) {
                  navigate(
                    `/file/${enrolledFile.msId}/changes?currentVersion=${data.internalId}&initial=true`
                  );
                  return;
                }
                navigate(getVersionDiffLink(data.internalId));
              }
            }}
            className="grid pl-2 grid-cols-10 hover:bg-gray-50 cursor-pointer h-[72px] border-b w-full items-center"
          >
            <td className="pl-6 flex col-span-2 w-full items-center mr-5">
              {getVersionLineIcon(data, index)}
              {!data.wasDiscarded && (
                <IdChip
                  className="w-auto px-2 ml-4 py-0 border rounded"
                  text={`${data.majorVersion}.${data.minorVersion}.${
                    data.patchVersion
                  } ${
                    matchLatest(data, latestVersion as IFileVersion)
                      ? "(latest)"
                      : ""
                  }`}
                />
              )}
            </td>
            <Tooltip
              className="max-w-[400px] h-auto whitespace-pre-wrap"
              hidden={!data.description || data.description?.length < 130}
              label={data.description}
            >
              <td className="text-left w-full pr-6  col-span-4  text-DS-gray-800 body-md-Regular">
                {truncateString(data.description || "", 130)}
              </td>
            </Tooltip>
            <td className="body-md-Regular col-span-3 text-DS-gray-600 flex items-center mr-8">
              <UserFetcher
                msId={data.createdByUserMsId || ""}
                render={({ data, isLoading }) => {
                  if (isLoading) {
                    return (
                      <IconLoader2 className="animate-spin mr-2 text-primary-blue" />
                    );
                  }
                  return (
                    <Tooltip
                      className="px-[6px] cursor-pointer body-sm-Regular py-[2px]"
                      label={
                        <span className="flex w-fit whitespace-pre-wrap text-DS-gray-300 p-0 px-1 flex-col items-start">
                          Created by{" "}
                          <span className="text-white capitalize">
                            {data?.displayName}
                          </span>
                        </span>
                      }
                    >
                      <span>
                        <Avatar
                          className="mr-3"
                          user={{
                            id: data?.id ?? undefined,
                            displayName: data?.displayName || data?.email || "",
                          }}
                        />
                      </span>
                    </Tooltip>
                  );
                }}
              />

              {DateTime.fromISO(data.createdAt).toFormat(
                "MMM dd, yyyy HH:mm a"
              )}
            </td>
            <td className="flex justify-end mr-8">
              <div className="flex justify-end items-center">
                <Tooltip
                  className="text-xs mr-2 hover:bg-DS-gray-100 p-2 rounded"
                  label="Download version file"
                >
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadVersionFile(data);
                    }}
                  >
                    <OpenFileIcon
                      className="text-DS-gray-500 w-[18px] cursor-pointer"
                    />
                  </p>
                </Tooltip>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="h-full z-[100] cursor-pointer relative flex justify-center items-center"
                >
                  <VersionDiffActions
                    refetchFile={refetchFile}
                    refetchVersions={refetchVersions}
                    versionOptions={versionOptions}
                    version={data}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VersionList;
