import React from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import WithInviteCodeRequired from "./hocs/WithInviteCodeRequired";
import { WithRouteDataLoadingOverlay } from "./hocs/WithRouteDataLoadingOverlay";
import AppShellOutlet from "./pages/AppShellOutlet";
import FileVersions from "./pages/file-versions";
import Files from "./pages/files";
import Logout from "./pages/Logout";
import MSALRedirect from "./pages/MsalRedirect";
import GettingStarted from "./pages/onboarding/GettingStarted";
import Root from "./pages/root/RootPage";
import VersionDiffs from "./pages/version-diffs";
const authenticatedRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppShellOutlet />}>
      <Route
        id="file-versions"
        path="/file/:fileId/versions"
        element={
          <WithInviteCodeRequired>
            <WithRouteDataLoadingOverlay>
              <FileVersions />
            </WithRouteDataLoadingOverlay>
          </WithInviteCodeRequired>
        }
      />
      <Route
        id="version-diffs"
        path="/file/:fileId/changes"
        element={
          <WithInviteCodeRequired>
            <WithRouteDataLoadingOverlay>
              <VersionDiffs />
            </WithRouteDataLoadingOverlay>
          </WithInviteCodeRequired>
        }
      />
      <Route
        id="files"
        path="/files"
        element={
          <WithInviteCodeRequired>
            <WithRouteDataLoadingOverlay>
              <Files />
            </WithRouteDataLoadingOverlay>
          </WithInviteCodeRequired>
        }
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/msal" element={<MSALRedirect />} />
      <Route path="/" element={<Root />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);
const unauthenticatedRoutes = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path="/msal" element={<MSALRedirect />} />
      <Route path="/" element={<GettingStarted />} />
      <Route path="*" element={<Navigate to="/" />} />
    </React.Fragment>
  )
);

export const getRouter = {
  authenticated: authenticatedRoutes,
  unauthenticated: unauthenticatedRoutes,
};
