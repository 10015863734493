import { FC } from "react";
import clsx from "clsx";

interface IdChipProps {
  variant?: "outlined" | "default";
  text: string | number;
  className?: string;
}

const IdChip: FC<IdChipProps> = ({ variant = "default", text, className }) => {
  return (
    <div
      className={clsx(
        "text-DS-gray-600 rounded px-1 py-[2px] font-medium",
        {
          "bg-DS-gray-100": variant === "default",
          "border border-DS-gray-600 rounded-full px-2 py-1":
            variant === "outlined",
        },
        className
      )}
    >
      {text}
    </div>
  );
};
export default IdChip;
