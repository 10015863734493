import { useViewportSize } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { regularNotification } from "../../helpers/notifications.tsx";
import { getFileChats } from "../../services/file/index.tsx";
import {
  useActions,
  useCurrentSheet,
  useEnrolledFile,
  useSelectedSheet,
  useVersionBlobFile,
  useVisibleComments,
  useWorkbook,
} from "../../store/index.ts";
import CustomCell from "./components/CustomCell";
import Grid from "./components/Grid.tsx";
import { GridFooter } from "./components/GridFooter";
import "./rk-base-grid.css";
import "./rk-grid.css";
import { getThemeColorScheme } from "./theme-parser.ts";
import { ColorScheme, GridRow } from "./types.ts";
import {
  MIN_ROWS,
  countEmptyValuesAfterKey,
  generateExcelColumnNames,
  getSheetColumnCount,
  getValue,
} from "./utils";

const RkXlsGrid = () => {
  const fileBuffer = useVersionBlobFile();
  const wb = useWorkbook();
  const activeSheet = useCurrentSheet();
  const { setCurrentSheet, setSelectedSheet } = useActions();
  const selectedSheet = useSelectedSheet();
  const [columns, setColumns] = useState<Column<string>[]>([]);
  const [rowsState, setRowsState] = useState<GridRow[]>([]);
  const [sheets, setSheets] = useState<{ value: string; label: string }[]>([]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [workSheets, setWorkSheets] = useState<string[] | null>(null);
  const containerRef = React.useRef(null);
  const [gridWidth, setGridWidth] = useState<string | number>("100%");
  const [gridHeight, setGridHeight] = useState<string | number>("100%");
  const { width: viewportWidth } = useViewportSize();
  const visibleComments = useVisibleComments();

  const enrolledFile = useEnrolledFile();
  const { setInitialCommentsData } = useActions();
  const { data: fileChats } = useQuery({
    queryKey: ["fileChats", enrolledFile?.msId],
    queryFn: () => getFileChats(enrolledFile?.msId || ""),
    enabled: !!enrolledFile?.msId,
    retry: 2,
    throwOnError: () => {
      regularNotification({
        message:
          "Something went wrong trying to load the comments. Please refresh the page and try again.",
      });
      return false;
    },
  });

  const onSheetSelected = useCallback(
    (idx: number, name: string) => {
      setSelectedSheet({ wsIdx: idx, wsName: name });
      setZoomLevel(1);
    },
    [setSelectedSheet]
  );

  useEffect(() => {
    if (fileChats) {
      const chats = fileChats.sort((a, b) =>
        b.createdAt < a.createdAt ? 1 : -1
      );
      setInitialCommentsData(chats, undefined);
    }
  }, [fileChats, setInitialCommentsData]);

  useEffect(() => {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute("content", "width=3024, initial-scale=0.2");
    }
  }, []);

  // effect to select the first sheet when workbook is loaded
  useEffect(() => {
    onSheetSelected(0, workSheets ? workSheets[0] : "");
  }, [onSheetSelected, workSheets]);

  // effect to set current sheet when selected sheet changes
  useEffect(() => {
    if (selectedSheet.wsName) {
      setCurrentSheet(selectedSheet.wsName);
    }
  }, [selectedSheet, setCurrentSheet]);

  // update grid width on zoom level change or viewport width change
  useEffect(() => {
    const handleZoom = (event: any) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "=" || event.key === "-" || event.key === "+")
      ) {
        event.preventDefault(); // Prevent default browser zooming behavior
        // Call your custom function here instead of zooming
        if ((event.key === "+" || event.key === "=") && zoomLevel < 2) {
          setZoomLevel(zoomLevel + 0.1);
        }
        if (event.key === "-" && zoomLevel > 0.4) {
          setZoomLevel(zoomLevel - 0.1);
        }
      }
    };

    // Add event listener for keydown events
    window.addEventListener("keydown", handleZoom);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleZoom);
    };
  }, [zoomLevel, viewportWidth]);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = (containerRef.current as any).offsetWidth;
      const containerHeight = (containerRef.current as any).offsetHeight;
      setGridWidth(containerWidth / zoomLevel);
      setGridHeight((containerHeight - 70) / zoomLevel);
    } else {
      setGridWidth("100%");
      setGridHeight("calc(100% - 70px)");
    }
  }, [zoomLevel]);

  useEffect(() => {
    if (activeSheet && workSheets) {
      const idx = workSheets.findIndex((sheet) => sheet === activeSheet);
      if (idx !== -1) {
        setSelectedSheet({ wsIdx: idx, wsName: activeSheet });
      }
    }
  }, [activeSheet, workSheets, setCurrentSheet, setSelectedSheet]);

  useEffect(() => {
    if (wb) {
      const sheetNames = wb.worksheets.map((ws) => ws.name);

      if (!workSheets) setWorkSheets(sheetNames);

      const wsName = sheetNames[selectedSheet.wsIdx | 0];

      const ws = wb.getWorksheet(wsName);

      const colorScheme: ColorScheme = getThemeColorScheme(wb);

      if (!ws) {
        console.log("Unable to select current sheet");
        return;
      }

      const numberOfColumns = getSheetColumnCount(ws);
      // noinspection JSUnusedGlobalSymbols
      const columns = generateExcelColumnNames(numberOfColumns).map(
        (columnName, index) => {
          const emWidth = ws.getColumn(index)?.width;
          const width = emWidth ? emWidth * 8 : 120;
          const key = columnName.toString() || "rowIdx";
          return {
            key,
            name: columnName.toString() || " ",
            width: columnName ? width : 60,
            resizable: !!columnName,
            colSpan: ({ type, row }: { type: string; row: GridRow }) => {
              if (type === "ROW") {
                let value = "";
                const sr_cell = row[key] || "";
                if (typeof sr_cell === "string") {
                  value = sr_cell;
                } else {
                  value = sr_cell.value;
                  // no colSpan if aligned to the right
                  if (
                    [
                      "center",
                      "right",
                      "fill",
                      "justify",
                      "centerContinuous",
                      "distributed",
                    ].includes(sr_cell.style?.alignment?.horizontal || "")
                  )
                    return 1;
                }
                if (value === "") {
                  return 1;
                }
                return countEmptyValuesAfterKey(row, key);
              }
            },
            renderCell: ({
              row,
              column,
            }: {
              row: GridRow;
              column: Column<string> & { idx: number };
            }) => (
              <CustomCell row={row} column={column} colorScheme={colorScheme} />
            ),
            frozen: !columnName.toString(),
          };
        }
      );

      const rows: GridRow[] = [];

      ws.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        const rowObj: GridRow = { id: (rowNumber - 1).toString() };
        rowObj["rowIdx"] = rowNumber.toString();

        row.eachCell({ includeEmpty: true }, (cell, index) => {
          if (!columns[index]) {
            return;
          }
          // try to find this cell in the visible comments
          const cellRef = `${cell.address}`;
          const chatCommentRef = visibleComments.find((c) =>
            c.referencedCells?.find(
              (rc) => rc.cell === cellRef && rc.sheetName === wsName
            )
          );
          rowObj[columns[index].key] = {
            value: getValue(cell),
            style: cell.style,
            hasComment: {
              excelComment: !!cell.note,
              comment: !!chatCommentRef,
            },
          };
        });
        rows.push(rowObj);
      });

      if (rows.length < MIN_ROWS) {
        for (let i = rows.length; i < MIN_ROWS; i++) {
          const rowObj: GridRow = { id: (i + 1).toString() };
          rowObj["rowIdx"] = (i + 1).toString();
          rows.push(rowObj);
        }
      }

      const newSheets = sheetNames.map((sheet) => ({
        value: sheet,
        label: sheet,
      }));

      setColumns(columns);
      setRowsState(rows);
      setSheets(newSheets);
    }
  }, [
    wb,
    selectedSheet,
    workSheets,
    activeSheet,
    setSelectedSheet,
    visibleComments,
  ]);

  if (!fileBuffer) return null;
  if (columns.length === 0) return null;

  return (
    <div ref={containerRef} className="grid-container overflow-hidden relative">
      <Grid
        rows={rowsState}
        columns={columns}
        gridWidth={gridWidth}
        gridHeight={gridHeight}
        zoomLevel={zoomLevel}
      />
      <div className="w-full absolute bottom-0 left-0">
        <GridFooter
          selectedSheet={selectedSheet}
          sheets={sheets}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          onSheetSelected={onSheetSelected}
        />
      </div>
    </div>
  );
};

export default RkXlsGrid;
