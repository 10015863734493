import { IconLoader2 } from "@tabler/icons-react";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { regularNotification } from "../../helpers/notifications";
import { getFileChats, getFileCollaborators } from "../../services/file";
import { useActions, useEnrolledFile } from "../../store";
import { IWorkspaceItem } from "../../typings";
import AppliedFilter from "./components/AppliedFilter";
import CommentInput from "./components/CommentInput";
import CommentList from "./components/CommentList";

const fetchCollaborators = async (enrolledFile: IWorkspaceItem) => {
  let users = await getFileCollaborators(
    enrolledFile.msId,
    enrolledFile?.driveMsId
  );
  users = users.map((item) => ({
    ...item,
    displayName: !item.displayName?.trim() ? item.email : item.displayName,
  }));
  return users;
};

const Comments = () => {
  const enrolledFile = useEnrolledFile();
  const { setInitialCommentsData } = useActions();
  const {
    data: fileChats,
    isLoading: loadingMessages,
    refetch: refetchComments,
  } = useQuery({
    queryKey: ["fileChats", enrolledFile?.msId],
    queryFn: () => getFileChats(enrolledFile?.msId || ""),
    enabled: !!enrolledFile?.msId,
    refetchInterval: 5000,
    retry: 2,
    throwOnError: () => {
      regularNotification({
        message:
          "Something went wrong trying to load the comments. Please refresh the page and try again.",
      });
      return false;
    },
  });

  const { data: collaborators, isLoading: loadingCollaborators } = useQuery({
    queryKey: ["collaborators", enrolledFile?.msId],
    queryFn: () => fetchCollaborators(enrolledFile as IWorkspaceItem),
    enabled: !!enrolledFile,
    throwOnError: () => {
      regularNotification({
        message:
          "Couldn't load your file collaborators. Please refresh the page and try again.",
      });
      return false;
    },
  });

  useEffect(() => {
    if (fileChats && collaborators) {
      const chats = fileChats.sort((a, b) =>
        b.createdAt < a.createdAt ? 1 : -1
      );
      setInitialCommentsData(chats, collaborators);
    }
  }, [fileChats, collaborators, setInitialCommentsData]);

  const loading = loadingMessages || loadingCollaborators;

  return (
    <div className="w-full h-full">
      {loading ? (
        <div className="w-full flex-col h-full flex items-center justify-center">
          <IconLoader2 className="animate-spin text-primary-blue" size={30} />
          <p className="mt-2 text-sm font-medium">Loading comments...</p>
        </div>
      ) : (
        <div className="w-full relative h-full bg-white flex flex-col justify-between">
          <AppliedFilter />
          <div className="flex-1 py-8 !overflow-y-auto relative z-0 pb-2">
            <CommentList refetchComments={refetchComments} />
          </div>

          <div className="max-h-[360px] z-[1] w-full">
            <CommentInput refetchComments={refetchComments} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Comments;
