import {
  DriveItemVersion,
  Identity,
  IdentitySet,
  Permission,
} from "@microsoft/microsoft-graph-types-beta";
import { apiConfig } from "../../authConfig";
import { IssueStatus } from "../../common/constants";
import { mergeIdentityArrays } from "../../helpers/arrays";
import { ExtendedIdentity } from "@services/user/user.types";
import {
  FileData,
  IApiUser,
  IEnrolledFileType,
  IFileChat,
  IFileVersion,
  ITask,
  ITaskUpdateResult,
  IWorkspaceItem,
  MessagePayload,
} from "../../typings";
import { callApi, getApiHeaders } from "../api-service";
import { getDriveUrl, graphClient, mapDriveItemsToFiles } from "../graph";
import { getCurrentUser, getUsers } from "../user";
import { FilterTypes } from "../../store/slices/FilesSlice";

const knownFilenames: { [key: string]: string } = {};

// get enrolled file
export async function getEnrolledFile(
  fileId?: string
): Promise<IEnrolledFileType> {
  if (!fileId) {
    return Promise.resolve({} as IEnrolledFileType);
  }
  const apiFile = await callApi("GET", `enrolled-files/${fileId}`);
  //get the file details and replace the name with the live file name
  try {
    const fileDetails = await getFileDetails(fileId, apiFile.driveMsId);
    return { ...apiFile, name: fileDetails.name };
  } catch (error) {
    console.log("Error getting file details");
    console.log(error);
    return apiFile;
  }
}

// get enrolled file details
export async function getEnrolledFileInfo(fileId: string): Promise<unknown> {
  return callApi("GET", `enrolled-files/info/${fileId}`);
}

export const getFilesWithUncommittedChanges = ({
  hasUncommittedChanges,
  since,
}: {
  hasUncommittedChanges: boolean;
  since: FilterTypes | null;
}): Promise<unknown> => {
  if (!since)
    return callApi(
      "GET",
      `enrolled-files?hasUncommittedChanges=${hasUncommittedChanges}`
    );
  return callApi(
    "GET",
    `enrolled-files?hasUncommittedChanges=${hasUncommittedChanges}&uncommittedChangesSince=${since}`
  );
};

export async function deleteEnrolledFile(fileId: string): Promise<unknown> {
  return callApi("DELETE", `enrolled-files/${fileId}`);
}

export function getFileTasksByVersion(
  fileId: string,
  fileVersionId: string
): Promise<ITask[]> {
  return callApi("GET", `file-tasks/${fileId}/version/${fileVersionId}`);
}

export function getFileOpenTasks(fileId: string): Promise<ITask[]> {
  return callApi(
    "GET",
    `file-tasks/${fileId}?taskStatus=${IssueStatus.TODO},${IssueStatus.IN_PROGRESS}`
  );
}

export const addFileToWorkspace = (fileId: string): Promise<unknown> => {
  return callApi("POST", `workspaces/files/${fileId}`);
};

export const removeFileFromWorkspace = (fileId: string): Promise<unknown> => {
  return callApi("DELETE", `workspaces/files/${fileId}`);
};

export const requestFilePauseEdit = (fileId: string): Promise<unknown> => {
  return callApi("GET", `enrolled-files/request-pause-edits/${fileId}`);
};

export const getFileActiveUsers = (fileId: string): Promise<string[]> => {
  return callApi("GET", `enrolled-files/active-users/${fileId}`);
};

export const cancelPauseEdit = (fileId: string): Promise<unknown> => {
  return callApi("GET", `enrolled-files/cancel-pause-edits/${fileId}`);
};

export function getFileTasks(
  fileId: string,
  statuses: IssueStatus[] = []
): Promise<ITask[]> {
  const statusQuery = statuses.length
    ? `?taskStatus=${statuses.join(",")}`
    : "";
  return callApi("GET", `file-tasks/${fileId}${statusQuery}`);
}

export async function getFileVersionDetails(
  fileId: string,
  fileVersionId: string
): Promise<IFileVersion> {
  const res = await callApi(
    "GET",
    `file-versions/file/${fileId}/version/${fileVersionId}`
  );
  return {
    ...res,
    signedOffByUserMsId: res?.signedOffByUser?.msId,
  };
}

export async function getFileChats(fileId: string): Promise<IFileChat[]> {
  const res = await callApi("GET", `file-chat/${fileId}`);
  return res;
}

export const sendFileChat = (message: MessagePayload): Promise<IFileChat> => {
  return callApi("POST", `file-chat`, message);
};

export const updateFileChat = ({
  message,
  chatId,
}: {
  message: string;
  chatId: string;
}): Promise<IFileChat> => {
  return callApi("PATCH", `file-chat/${chatId}`, { message });
};

export const updateChatSeen = (id: string): Promise<unknown> => {
  return callApi("POST", `file-chat/mark-as-seen/${id}`);
};

export const deleteFileChat = (
  internalId: string | number
): Promise<unknown> => {
  return callApi("DELETE", `file-chat/${internalId}`);
};

export function getFileTaskById(taskId: string): Promise<ITask> {
  return callApi("GET", `file-tasks/by-task-id/${taskId}`);
}

export function createFileChatMessage(
  fileId: string,
  chat: Partial<IFileChat>
): Promise<unknown> {
  const payload = {
    enrolledFileMsId: fileId,
    ...chat,
  };
  return callApi("POST", "file-chat", payload);
}

export function addFilesToWorkspace(files: IWorkspaceItem[]): Promise<unknown> {
  return Promise.allSettled(
    files &&
      files.map((file) => {
        return callApi("POST", `workspaces/files/${file.msId}`, {
          driveMsId: file.driveMsId,
        });
      })
  );
}

export function removeFilesFromWorkspace(
  workspaceId: string,
  fileIds: string[]
): Promise<unknown> {
  return Promise.allSettled(
    fileIds.map((fileId) => {
      return callApi("DELETE", `workspaces/${workspaceId}/files/${fileId}`);
    })
  );
}

export async function createEnrolledFile({
  userMsId,
  file,
}: {
  userMsId: string;
  file: IWorkspaceItem;
}): Promise<unknown> {
  return callApi("POST", `enrolled-files`, {
    driveMsId: file.driveMsId,
    msId: file.msId,
    name: file.name,
    assigneeMsId: userMsId,
  }, {
    throwOnErrors: true,
  });
}

export async function updateFileTask(
  task: ITask,
  file: IEnrolledFileType
): Promise<ITaskUpdateResult> {
  try {
    const res = await callApi("POST", `file-tasks/${task.id}`, {
      ...task,
      fileMsId: file.msId,
      driveMsId: file.driveMsId,
    });

    return {
      ...res,
      oldTask: { ...task },
      newTask: { ...res?.data },
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getLatestFileVersion(
  file: IEnrolledFileType
): Promise<Blob> {
  const url = `${apiConfig.apiEndpoint}/file-handler/latest/${file.driveMsId}/${file.msId}`;

  const options: RequestInit = {
    method: "GET",
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error("Error getting latest file version");
  }

  const res = await response.blob();

  return res;
}

export async function getVersionFile(versionId: string): Promise<Blob> {
  const url = `${apiConfig.apiEndpoint}/file-handler/version/${versionId}`;
  const options: RequestInit = {
    method: "GET",
    headers: await getApiHeaders(),
  };
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error("Error getting file version");
  }
  return await response.blob();
}

export async function getFileDetails(
  fileId: string,
  driveId: string | undefined
): Promise<FileData> {
  if (!fileId || !driveId) {
    return Promise.resolve({} as FileData);
  }
  const file = await graphClient
    .api(getDriveUrl(`items/${fileId}`, driveId))
    .get();
  // update the filename
  const knownFilename = knownFilenames[fileId];
  if (!knownFilename || knownFilename !== file.name) {
    knownFilenames[fileId] = file.name;
    await callApi("PATCH", `enrolled-files/${fileId}`, {
      name: file.name,
    });
  }
  //just one file
  return mapDriveItemsToFiles([file])[0];
}

export async function getMsFileVersions(
  fileId: string,
  driveId: string | undefined
): Promise<DriveItemVersion[]> {
  const versions = (
    await graphClient
      .api(getDriveUrl(`items/${fileId}/versions`, driveId))
      .get()
  ).value as DriveItemVersion[];
  return versions;
}

//update the file with new content
export async function updateFile(
  enrolledFile: IEnrolledFileType,
  content: Uint8Array | Blob | File
): Promise<unknown> {
  const version = await graphClient
    .api(
      getDriveUrl(`items/${enrolledFile.msId}/content`, enrolledFile.driveMsId)
    )
    .put(content);
  return version;
}

export async function getFileCollaborators(
  fileId: string,
  driveId: string | undefined
): Promise<ExtendedIdentity[]> {
  const filePermissions = await getFilePermissions(fileId, driveId);
  const currentTenantUsers = await getUsers();
  const me = await getCurrentUser();
  const owner = await getFileOwner(fileId, driveId);
  const collaboratorsByWorkspaces = await getCollaboratorsByWorkspaces(fileId);
  const permissions = mergeIdentityArrays(
    filePermissions as ExtendedIdentity[],
    currentTenantUsers,
    collaboratorsByWorkspaces,
    [me, owner]
  );

  return permissions.filter((permission) => permission.email);
}

//get a list of users with access to the file
export async function getFilePermissions(
  fileId: string,
  driveId: string | undefined
): Promise<Identity[]> {
  const permissions = await graphClient
    .api(getDriveUrl(`items/${fileId}/permissions`, driveId))
    .get();

  const filePermissions = permissions.value.reduce(
    (acc: ExtendedIdentity[], permission: Permission) => {
      if (permission?.grantedTo?.user) {
        const user = permission.grantedTo.user as ExtendedIdentity;
        const identity = {
          email: user?.email || permission.invitation?.email,
          displayName:
            user.displayName?.trim() ||
            user?.email ||
            permission.invitation?.email,
          id: user.id,
        };
        acc.push(identity);
      } else if (permission?.grantedToIdentities) {
        const innerAcc = permission.grantedToIdentities.reduce(
          (acc: ExtendedIdentity[], identity: IdentitySet) => {
            if (identity?.user) {
              const user = identity.user as ExtendedIdentity;
              const resIdentity = {
                email: user.email,
                displayName: user.displayName || user.email,
                id: user.id,
              };
              acc.push(resIdentity);
            }
            return acc;
          },
          []
        );
        acc.push(...innerAcc);
      }
      return acc;
    },
    []
  );

  return filePermissions as ExtendedIdentity[];
}

export async function getFileOwner(
  fileId: string,
  driveId: string | undefined
) {
  const driveInfo = await graphClient
    .api(getDriveUrl(`items/${fileId}`, driveId))
    .get();

  return driveInfo.createdBy.user as ExtendedIdentity;
}

export async function getFileUnsyncedChanges(
  driveId: string | undefined,
  fileId: string | undefined
): Promise<unknown[]> {
  if (!fileId || !driveId) {
    return Promise.resolve([]);
  }
  const res = callApi(
    "GET",
    `file-handler/uncommitted-changes/${driveId}/${fileId}`
  );
  return res;
}

export async function getFileProcessingUnattributedChange(
  fileMsId: string,
  sheetName: string,
  cellAddress: string
): Promise<{
  id: number;
  oldValue: { v: string; w: string } | null;
  newValue: { v: string; w: string } | null;
  sheetName: string;
  cellAddress: string;
  byUserMsId: string | null;
  processingStatus: string;
}> {
  const res = callApi(
    "GET",
    `unattributed-changes/${fileMsId}/${sheetName}/${cellAddress}`
  );
  return res;
}

export async function getCollaboratorsByWorkspaces(
  fileId: string
): Promise<ExtendedIdentity[]> {
  const collaborators: IApiUser[] = await callApi(
    "GET",
    `enrolled-files/collaborators/${fileId}`
  );
  return collaborators.map((item) => ({
    ...item,
    displayName: !`${item.firstName} ${item.lastName}`.trim()
      ? item.email
      : `${item.firstName} ${item.lastName}`,
    email: item.email,
    id: item.msId,
  }));
}
