import { useIsAuthenticated } from "@azure/msal-react";
import { Text } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMyDrive } from "../../hooks/utility";

function RootAuth() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { drive, loading } = useMyDrive();

  useEffect(() => {
    if (isAuthenticated && !loading && !!drive?.id) {
      navigate("/files");
    }
  }, [isAuthenticated, loading, drive, navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-full gap-4 max-w-md mx-auto">
      <Text c="dimmed" className="">
        Logged in, redirecting...
      </Text>
    </div>
  );
}

export default RootAuth;
