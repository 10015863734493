import { IFileVersion } from "../../../typings";

export function getVersionText(version: IFileVersion) {
  if (version.majorVersion < 0) {
    return "Discarded Changes";
  }
  if (
    version.majorVersion === 0 &&
    version.minorVersion === 0 &&
    version.patchVersion === 0
  ) {
    return "Uncommitted";
  }
  return `v${version.majorVersion}.${version.minorVersion}.${version.patchVersion}`;
}

export const isVersionDiscarded = (version: IFileVersion) => {
  return version.wasDiscarded;
};
