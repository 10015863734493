import { IFileChat } from "../typings";

// get initials from name
export function getInitials(name: string): string {
    const names = name.split(" ");
    if (names.length === 1) {
        return names[0].charAt(0);
    }
    return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
}

// a function to convert to number is possible else return string. It accepts string and number
export function toNumber(value: string | number): string | number {
    if (typeof value === "string") {
        const num = Number(value);
        return isNaN(num) ? value : num;
    }
    return value;
}

export function extractSheetCellReferences(message: string): { sheetName: string; cell: string }[] {
    // Regular expression to match references in the specified format
    const refRegex = /=\{\{([^!]+)!([A-Z]+\d+)\}\}/g;
    let matches;
    const references = [];
  
    // Loop through all matches and extract the sheet name and cell reference
    while ((matches = refRegex.exec(message)) !== null) {
      references.push({
        sheetName: matches[1],
        cell: matches[2],
      });
    }
  
    return references;
  }

export function getCommentWithCellRefs(comment: IFileChat): IFileChat {
    if (comment.message) {
        const referencedCells = extractSheetCellReferences(comment.message);
        return { ...comment, referencedCells };
    }
    return comment;
}