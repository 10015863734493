import { apiConfig } from "../../authConfig";
import {
  ChangeRecord,
  SheetChange,
  transformData,
} from "../../pages/version-diffs/helpers/transformData";
import {
  CreateFileVersionType,
  IFileVersion,
  IVersionCompareResult,
} from "../../typings";
import { callApi } from "../api-service";
import { getGraphIdToken } from "../graph";

export async function compareLatestLiveVersions(
  driveMsId: string,
  fileMsId: string
): Promise<{ diffs: IVersionCompareResult; liveFile: Blob }> {
  const endpointUrl = `file-handler/compare-versions-live-latest/${driveMsId}/${fileMsId}`;
  const idToken = await getGraphIdToken();

  const options: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };

  const response = await fetch(
    `${apiConfig.apiEndpoint}/${endpointUrl}`,
    options
  );

  if (!response.ok) {
    throw new Error("Error comparing file versions");
  }
  const result = await response.formData();
  const diffs = JSON.parse(
    result.get("diffs") as string
  ) as IVersionCompareResult;
  return {
    diffs,
    liveFile: result.get("liveFile") as Blob,
  };
}

export async function compareVersions(
  driveMsId: string,
  fileMsId: string,
  olderVersionId: string, // older version
  newerVersionId: string // newer version
): Promise<IVersionCompareResult> {
  const endpointUrl = `file-handler/compare-versions/${driveMsId}/${fileMsId}/${olderVersionId}/${newerVersionId}`;
  const idToken = await getGraphIdToken();

  const options: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };

  const response = await fetch(
    `${apiConfig.apiEndpoint}/${endpointUrl}`,
    options
  );

  if (!response.ok) {
    throw new Error("Error comparing file versions");
  }

  const result = await response.json();
  return result;
}

// Function to create a version
export const createVersion = async (data: CreateFileVersionType) => {
  return await callApi("POST", "file-versions", data);
};

// Function to get the versions of a file
export const getFileVersions = async (
  fileMsId: string
): Promise<IFileVersion[]> => {
  return await callApi("GET", `file-versions/file/${fileMsId}`);
};

// Function to get a specific version
export const getVersion = async (versionInternalId: string) => {
  return await callApi(
    "GET",
    `file-versions/file/version/${versionInternalId}`
  );
};

// Function to mark changes to a version as seen
export const markVersionAsSeen = async (
  fileMsId: string,
  versionInternalId: string
) => {
  return await callApi(
    "POST",
    `file-versions/file/${fileMsId}/version/${versionInternalId}/seen`
  );
};

// Function to discard a file version
export const discardFileVersion = async (
  fileMsId: string,
  description?: string
) => {
  return await callApi("POST", `file-versions/file/discard-live/${fileMsId}`, {
    description,
  });
};

export const revertToVersion = async (
  fileMsId: string,
  version: IFileVersion
) => {
  return await callApi(
    "POST",
    `file-versions/file/revert-to-version/${version.internalId}`,
    {
      enrolledFileMsId: fileMsId,
      version: {
        majorVersion: version.majorVersion,
        minorVersion: version.minorVersion,
        patchVersion: version.patchVersion,
        description: version.description,
      },
    }
  );
};

// get the cell history
export const getCellHistory = async (
  cell: string,
  sheetName: string,
  fileMsId: string
): Promise<
  Array<
    ChangeRecord &
      Partial<{
        majorVersion: string;
        minorVersion: string;
        patchVersion: string;
      }>
  >
> => {
  const result = await callApi(
    "GET",
    `file-versions/file/${fileMsId}/cell-history?cell=${cell}&sheetName=${encodeURIComponent(
      sheetName
    )}`
  );

  return transformData({
    diffs: result as SheetChange[],
    versionCreatorId: "",
  });
};
