import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Comments from "../../features/Comments";
import { getFileVersions } from "../../services/versions";
import { useActions, useShowComments } from "../../store";
import { IFileVersion } from "../../typings";
import { ChangesHeader } from "./components/ChangesHeader";
import Header from "./components/Header";
import VersionList from "./components/VersionList";
import { useSetEnrolledFile } from "../../hooks/file";

const FileVersions = () => {
  const showComments = useShowComments();
  const { fileId } = useParams<{ fileId: string }>();
  const { setInitialVersionsData } = useActions();
  const { refetchFile } = useSetEnrolledFile();

  const { data: versions, refetch } = useQuery({
    queryKey: ["fileVersions", fileId],
    queryFn: () => getFileVersions(fileId || ""),
    enabled: !!fileId,
  });

  useEffect(() => {
    if (versions) {
      const versionsSorted = versions.sort((a: IFileVersion, b: IFileVersion) =>
        a.createdAt > b.createdAt ? -1 : 1
      );
      setInitialVersionsData(versionsSorted);
    }
  }, [versions, setInitialVersionsData]);

  return (
    <div className="w-full max-h-screen overflow-hidden flex justify-between">
      <div className={`w-full flex h-full flex-col`}>
        <div className="px-8 h-[64px] flex items-center border-b border-DS-gray-200">
          <Header hasPauseEdits />
        </div>
        <div className="px-9 w-full h-[68px] flex items-center border-b">
          <ChangesHeader />
        </div>

        <div className="w-full flex-1 overflow-auto">
          <VersionList refetchFile={refetchFile} refetchVersions={refetch} />
        </div>
      </div>

      {showComments && (
        <div className="w-[43%] flex flex-col bg-white h-screen">
          <div className="px-8 flex border-l h-[64px] items-center border-b border-DS-gray-200 shrink-0">
            <h2 className="body-lg-SemiBold text-DS-gray-800">Comments</h2>
          </div>
          <div className="bg-white border-l flex-1 h-full min-h-0">
            <Comments />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileVersions;
