import { PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { Drive, DriveItem } from "@microsoft/microsoft-graph-types-beta";
import { GRAPH_SCOPES, graphConfig, msalConfig } from "../authConfig";
import { FileData } from "../typings";
import { acquireTokens } from "./acquireTokens";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
  };
  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function mapDriveItemsToFiles(driveItems: DriveItem[] = []): FileData[] {
  const ret = driveItems.map((driveItem) => {
    return {
      id: driveItem.id as string,
      parentId: driveItem.parentReference?.id as string,
      key: driveItem.id as string,
      modified:
        driveItem.lastModifiedDateTime ||
        driveItem.fileSystemInfo?.lastModifiedDateTime ||
        "",
      size: !driveItem.folder ? driveItem.size || undefined : undefined,
      name: driveItem.name as string,
      isDir: driveItem.folder ? true : false,
      extension: driveItem.name?.split(".").pop() as string,
      webUrl: driveItem?.webUrl as string,
      parentReference: driveItem.parentReference as {
        driveId: string;
      },
    };
  });
  return ret;
}

export const msalInstance = new PublicClientApplication(msalConfig);

export const activeAccount = msalInstance.getActiveAccount();

export const me = {
  displayName: activeAccount?.name,
  email: activeAccount?.username,
  id: activeAccount?.localAccountId,
};

export const getGraphAccessToken = async (): Promise<string> => {
  const tokens = await acquireTokens(GRAPH_SCOPES);
  return tokens.accessToken;
};

export const getGraphIdToken = async (): Promise<string> => {
  const tokens = await acquireTokens(GRAPH_SCOPES);
  return tokens.idToken;
};

export const graphClient = Client.initWithMiddleware({
  authProvider: {
    getAccessToken: getGraphAccessToken,
  },
});

export const getMyDrive = async (): Promise<Drive> => {
  const myDrive = graphClient.api("/me/drive").get();
  return myDrive;
};

export const getDriveUrl = (segment: string, driveId: string | undefined) => {
  if (driveId) {
    return `/drives/${driveId}/${segment}`;
  } else {
    return `/me/drive/${segment}`;
  }
};

// get files shared with me
export async function getSharedWithMe(): Promise<{
  files: FileData[];
}> {
  const files = (
    await graphClient.api("/me/drive/sharedWithMe").get()
  ).value.map((file: DriveItem) => file.remoteItem);
  return { files: mapDriveItemsToFiles(files) };
}

export async function getFolderContent(
  folderId: string | undefined,
  driveId: string | undefined
): Promise<{
  files: FileData[];
  parentId?: string;
}> {
  let files: [DriveItem] | [] = [];
  if (folderId) {
    files = (
      await graphClient
        .api(getDriveUrl(`items/${folderId}/children`, driveId))
        .get()
    ).value;
  } else {
    files = (await graphClient.api(getDriveUrl("root/children", driveId)).get())
      .value;
  }
  // if we have an id, we need to get the parent id
  if (folderId) {
    const parent = await graphClient
      .api(getDriveUrl(`items/${folderId}`, driveId))
      .get();
    return {
      files: mapDriveItemsToFiles(files),
      parentId: parent.parentReference?.id,
    };
  }

  return { files: mapDriveItemsToFiles(files) };
}

export async function downloadFile(
  fileId: string,
  driveId: string | undefined,
  versionId?: string
): Promise<ReadableStream> {
  //at first, try to get the file stream
  const versionUrlPart = versionId ? `/versions/${versionId}` : "";
  try {
    const stream = await graphClient
      .api(getDriveUrl(`items/${fileId}${versionUrlPart}/content`, driveId))
      .get();
    return stream;
  } catch (_error) {
    //if error, it might be a personal account and we can get the download url
    const res = await graphClient
      .api(getDriveUrl(`items/${fileId}${versionUrlPart}`, driveId))
      .get();
    const url = res["@microsoft.graph.downloadUrl"];
    const stream = await fetch(url).then((response) => response.body);
    return stream as ReadableStream;
  }
}

export const uploadFileToDrive = async ({
  file,
  fileName,
  driveId,
  fileId,
  isShared,
}: {
  file: Blob;
  fileName: string;
  driveId: string;
  fileId: string;
  isShared: boolean;
}): Promise<any> => {
  let folderID: string;
  const formattedName = fileName.endsWith(".xlsx")
    ? fileName
    : `${fileName}.xlsx`;

  if (isShared) {
    try {
      const folderData = await graphClient
        .api(`/me/drive/root:/Rockhopper Copies`)
        .get();
      folderID = folderData.id;
    } catch (_error) {
      // If the folder doesn't exist, create it
      const newFolder = {
        name: "Rockhopper Copies",
        folder: {},
        "@microsoft.graph.conflictBehavior": "rename",
      };
      const folderData = await graphClient
        .api(`/me/drive/root/children`)
        .post(newFolder);
      folderID = folderData.id;
    }
  } else {
    const fileData = await graphClient.api(`/me/drive/items/${fileId}`).get();
    folderID = fileData.parentReference?.id;
  }

  const response = await graphClient
    .api(getDriveUrl(`items/${folderID}:/${formattedName}:/content`, driveId))
    .put(file);

  return response;
};
