import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import UnauthRoot from "./Unauth";
import AuthRoot from "./Auth";

function Root() {

  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthRoot />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AuthRoot />
      </AuthenticatedTemplate>
    </>
  );
}

export default Root;
