import { getTeam } from "@/services/teams";
import { modals } from "@mantine/modals";
import { IconLoader2 } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as FileIcon } from "../../assets/svg/DS/file.svg";
import { PlaceholderButton } from "../../components/DS/Button";
import LogoComponent from "../../components/DS/LogoNew";
import { regularNotification } from "../../helpers/notifications";
import { useUpdateOnboarding } from "../../hooks/useUpdateOnboarding";
import { useInternalMe } from "../../hooks/utility";
import { getUserWorkspaceFiles } from "../../services/user";
import {
  useActions,
  useAppliedFilter,
  useIsFetchingFiles,
  useVisibleFiles,
} from "../../store";
import { IWorkspaceItem } from "../../typings";
import InviteTeamModal from "../onboarding/InviteTeamModal";
import WelcomeAndInviteTeamModal from "../onboarding/WelcomeAndInviteTeamModal";
import WelcomeModal from "../onboarding/WelcomeModal";
import FilesList from "./components/FilesList";
import FilesHeader from "./components/Header";

const FilesPage = () => {
  const isFetchingFiles = useIsFetchingFiles();
  const appliedFilter = useAppliedFilter();
  const { setInitialFiles, setDrawerOpened } = useActions();
  const items = useVisibleFiles();
  const { me, userData, userLoading, userError } = useInternalMe();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [hasTeam, setHasTeam] = useState(false);

  const userId = userData?.msId;

  const { data, isLoading, error, refetch, isFetched } = useQuery({
    queryKey: ["files", userId],
    queryFn: () => getUserWorkspaceFiles(userData?.msId),
    enabled: !!userId && !!me.id,
  });

  const { data: teamData, isLoading: isTeamLoading } = useQuery({
    queryKey: ["team", userData?.teamMembers?.[0]?.team?.internalId],
    queryFn: () =>
      getTeam(userData?.teamMembers?.[0]?.team?.internalId?.toString() || ""),
    enabled: !!userData?.teamMembers?.[0]?.team?.internalId,
  });

  const updateOnboarding = useUpdateOnboarding();

  useEffect(() => {
    if (data) {
      const response = data as any;
      if (
        response.statusCode &&
        (response.statusCode < 200 || response.statusCode >= 300)
      ) {
        regularNotification({
          title: "Error",
          message: `Something went wrong. Please try again later.`,
        });
        return;
      }
      setInitialFiles(data as IWorkspaceItem[]);
    }
  }, [data, setInitialFiles, isFetched]);

  // if search param browse is present, open the drawer
  useEffect(() => {
    if (searchParams.get("browse") === "true") {
      setDrawerOpened(true);
    }
  }, [searchParams, setDrawerOpened]);

  useEffect(() => {
    // Check if team has 2 or more members
    const hasTeamMembers = (teamData?.teamMembers?.length ?? 0) >= 2;
    setHasTeam(hasTeamMembers);
  }, [teamData?.teamMembers]);

  // userData?.showOnboarding is true, open the welcome modal
  useEffect(() => {
    if (userData?.shouldShowOnboarding && !isTeamLoading) {
      setModalOpen(true);
    }
  }, [userData?.shouldShowOnboarding, isTeamLoading]);

  const handleWelcomeModalClose = useCallback(async () => {
    await updateOnboarding();
    // Then close modals
    setModalOpen(false);
    setInviteModalOpen(false);
    modals.closeAll();
    // Finally update the onboarding status in the backend
    
  }, [updateOnboarding]);

  const handleInviteModalClose = useCallback(async () => {
    if (userData?.shouldShowOnboarding) {
      await updateOnboarding();
    }
    // Then close modals
    setInviteModalOpen(false);
    setModalOpen(false);
    modals.closeAll();
  }, [updateOnboarding, userData?.shouldShowOnboarding]);

  const handleInviteClick = useCallback(async () => {
    if (userData?.shouldShowOnboarding) {
      await updateOnboarding();
    }
    setInviteModalOpen(true);
  }, [updateOnboarding, userData?.shouldShowOnboarding]);

  if (userData?.workspace && !userData?.workspace.internalId) {
    return (
      <div className="flex flex-col w-full h-full justify-center items-center">
        <LogoComponent />
        <div className="text-gray-500 font-medium mt-1 text-sm flex items-center">
          You are not yet enrolled in any workspace.
        </div>
      </div>
    );
  }

  if (error || userError)
    return (
      <div className="flex flex-col w-full h-full justify-center items-center">
        <LogoComponent />
        <div className="text-gray-500 text-sm mt-1 flex items-center">
          Something went wrong. Please try again later.
        </div>
      </div>
    );

  if (userLoading || isLoading || isFetchingFiles)
    return (
      <div className="flex flex-col w-full bg-white">
        <FilesHeader refetch={undefined} />
        <div className="flex w-full h-full justify-center items-center">
          <IconLoader2 size={30} className="text-primary-blue animate-spin" />
        </div>
      </div>
    );

  return (
    <>
      {hasTeam ? (
        <WelcomeModal 
          opened={isModalOpen} 
          onClose={handleWelcomeModalClose} 
          teamName={teamData?.name}
        />
      ) : (
        <WelcomeAndInviteTeamModal
          opened={isModalOpen}
          onClose={handleWelcomeModalClose}
          onInvite={handleInviteClick}
        />
      )}
      <InviteTeamModal
        opened={isInviteModalOpen}
        onClose={handleInviteModalClose}
      />
      <div className="flex flex-col w-full bg-white">
        <FilesHeader
          refetch={refetch}
          onTeamMembersClick={() => setInviteModalOpen(true)}
          teamData={teamData}
        />
        {items && items.length === 0 && (
          <div className="flex grow items-center justify-center">
            <div className="flex flex-col gap-4">
              <FileIcon className="text-DS-gray-300 h-8 w-8 mx-auto" />
              <div className=" text-DS-gray-400 text-sm2">
                {appliedFilter && appliedFilter !== "ALL_FILES"
                  ? `You have no uncommitted changes ${appliedFilter
                      .replace(/_/g, " ")
                      .toLowerCase()}`
                  : "No files added yet"}
              </div>
              <div className="flex justify-center">
                <PlaceholderButton
                  text="File manager"
                  className="grow-0"
                  onClick={() => {
                    setDrawerOpened(true);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {items && items.length > 0 && (
          <FilesList
            refetch={refetch}
            onClick={(file) => navigate(`/file/tasks/${file.msId}`)}
            files={items}
          />
        )}
      </div>
    </>
  );
};

export default FilesPage;
