import { StateCreator } from "zustand";
import { persist } from "zustand/middleware";

export type PersistentSettingsSlice = {
  showFileManagerDelayWarning: boolean;
  actions: {
    setShowFileManagerDelayWarning: (show: boolean) => void;
  };
};

const persistConfig = {
  name: "rockhopper-settings",
  partialize: (state: PersistentSettingsSlice) => ({ 
    showFileManagerDelayWarning: state.showFileManagerDelayWarning 
  }),
};

export const createPersistentSettingsSlice: StateCreator<
  PersistentSettingsSlice,
  [],
  [["zustand/persist", Pick<PersistentSettingsSlice, 'showFileManagerDelayWarning'>]]
> = persist(
  (set) => ({
    showFileManagerDelayWarning: true,
    actions: {
      setShowFileManagerDelayWarning: (show: boolean) =>
        set((state) => ({ ...state, showFileManagerDelayWarning: show })),
    },
  }),
  persistConfig
); 