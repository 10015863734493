import {
  cssVariablesResolver,
  mantineTheme,
  modalOverlayProps,
} from "@/helpers/theme";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { FC } from "react";

interface IMantineWrapperProps {
  children: React.ReactNode;
}

/**
 * Higher-Order Component that provides Mantine UI context to the application
 * Includes MantineProvider, Notifications, and ModalsProvider
 */
const MantineWrapper: FC<IMantineWrapperProps> = ({ children }) => {
  return (
    <MantineProvider
      cssVariablesResolver={cssVariablesResolver}
      theme={mantineTheme}
    >
      <Notifications position="bottom-center" />
      <ModalsProvider
        modalProps={{
          overlayProps: modalOverlayProps,
        }}
      >
        {children}
      </ModalsProvider>
    </MantineProvider>
  );
};

export default MantineWrapper;
