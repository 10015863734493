import { LoadingOverlay } from "@mantine/core";
import { FC } from "react";
import { useNavigation } from "react-router-dom";
import { WithLoadingOverlayProps } from "../typings";

export const WithRouteDataLoadingOverlay: FC<WithLoadingOverlayProps> = ({
  children,
}) => {
  const { state } = useNavigation();
  const isLoading = state === "loading";
  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {children}
    </>
  );
};
