import { useActions } from "@/store";
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { User } from "@microsoft/microsoft-graph-types-beta";
import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "../helpers/NavigationClient";
import { useSocket } from "../hooks/sockets";
import { graphClient } from "../services/graph";
import MantineWrapper from "../wrappers/MantineWrapper";

interface IWithMsAuthProps {
  children: React.ReactNode;
}

const WithMsAuth: FC<IWithMsAuthProps> = ({ children }) => {
  const { instance: pca } = useMsal();
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  const processedUserIdsRef = useRef<Set<string>>(new Set());
  const { setCurrentGraphUser, setCurrentMsalAccount } = useActions();
  pca.setNavigationClient(navigationClient);

  // Initialize socket
  useSocket();

  useEffect(() => {
    // This will be run on component mount
    pca.enableAccountStorageEvents();
    const callbackId = pca.addEventCallback(async (message: EventMessage) => {
      if (message.eventType === EventType.ACCOUNT_REMOVED) {
        // check if the account removed is the one currently in context or the active account has been removed
        // navigate to login if true
        const currentAccount = pca.getActiveAccount();
        const removedAccount = message.payload as AccountInfo;
        if (
          !currentAccount ||
          (currentAccount &&
            removedAccount &&
            currentAccount.homeAccountId === removedAccount.homeAccountId)
        ) {
          pca.setActiveAccount(null);
          navigate("/logout");
        }
      }

      if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
        const payload = message.payload as AuthenticationResult;
        const account = payload.account;
        pca.setActiveAccount(account);
      }
      if (
        message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
        message.payload
      ) {
        const payload = message.payload as AuthenticationResult;
        const { uniqueId, account } = payload;
        setCurrentMsalAccount(account);

        // Check if this user has already been processed
        if (processedUserIdsRef.current.has(uniqueId)) {
          return;
        }
        processedUserIdsRef.current.add(uniqueId);

        const graphUser = (await graphClient
          .api(`/users/${uniqueId}`)
          .get()) as User;
        setCurrentGraphUser(graphUser);
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        pca.removeEventCallback(callbackId);
      }
    };
  }, [navigate, pca, setCurrentGraphUser, setCurrentMsalAccount]);

  return <MantineWrapper>{children}</MantineWrapper>;
};

export default WithMsAuth;
