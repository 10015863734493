type Props = {
  className?: string;
};

const Separator = ({ className }: Props) => {
  return (
    <div className={`${className} w-full h-[1px] bg-gray-100 rounded-sm`}></div>
  );
};

export default Separator;
