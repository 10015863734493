import React from "react";
import ExcelJS from "exceljs";
import { format } from "numfmt";
import { Column } from "react-data-grid";
import { toNumber } from "../../../helpers/strings";
import { getColor } from "../theme-parser";
import { ColorScheme, GridRow } from "../types";
import { INDEXED_COLORS } from "./IndexedColors";

type CellStyle = {
  backgroundColor?: string;
  color?: string;
};

const Cell = React.memo(
  ({
    cellAddress,
    isHighlightedCell,
    colorScheme,
    row,
    column,
  }: {
    cellAddress: string;
    isHighlightedCell: boolean;
    colorScheme: ColorScheme;
    row: GridRow;
    column: Column<string> & { idx: number };
  }) => {
    const src_cell = row[column.key] || "";

    let className = "rk-cell";
    const style: CellStyle = {};
    if (column.key === "rowIdx") {
      className += " rk-cell-idx";
    }

    let value: string;
    let parsedStyle: Partial<ExcelJS.Style> = {};
    const hasComment = {
      excelComment: false,
      comment: false,
    };
    if (typeof src_cell === "string") {
      value = src_cell;
    } else {
      value = src_cell.value;
      parsedStyle = src_cell.style;
      hasComment.excelComment = src_cell?.hasComment?.excelComment || false;
      hasComment.comment = src_cell?.hasComment?.comment || false;
    }

    if (parsedStyle?.font?.bold) className += " font-bold";
    if (parsedStyle?.font?.italic) className += " italic";
    if (parsedStyle?.font?.underline) className += " underline";
    if (parsedStyle?.font?.strike) className += " line-through";
    if (parsedStyle?.alignment?.wrapText) className += " wrap-text";
    if (parsedStyle?.alignment?.horizontal === "center")
      className += " text-center";
    if (parsedStyle?.alignment?.horizontal === "right")
      className += " text-right";
    if (parsedStyle?.alignment?.horizontal === "left")
      className += " text-left";

    if (parsedStyle?.font?.color?.argb) {
      style.color = `#${parsedStyle.font.color.argb.substring(2)}`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (parsedStyle?.font?.color?.indexed !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const colorRgb =
        INDEXED_COLORS[(parsedStyle.font.color as any).indexed] || "000000";
      style.color = `#${colorRgb}`;
    } else if (parsedStyle?.font?.color?.theme !== undefined) {
      style.color = `#${getColor(parsedStyle.font.color.theme, colorScheme)}`;
    }

    if (parsedStyle?.fill?.type === "pattern") {
      if (parsedStyle.fill.fgColor?.argb)
        style.backgroundColor = `#${parsedStyle.fill.fgColor?.argb.substring(
          2
        )}`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      else if (parsedStyle.fill.fgColor?.indexed !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const colorRgb =
          INDEXED_COLORS[(parsedStyle.fill.fgColor as any)?.indexed] ||
          "FFFFFF";
        style.backgroundColor = `#${colorRgb}`;
      } else if (parsedStyle.fill.fgColor?.theme !== undefined) {
        style.backgroundColor = `#${getColor(
          parsedStyle.fill.fgColor?.theme,
          colorScheme
        )}`;
      }
    }

    return (
      <div
        style={style}
        className={`${className} ${
          isHighlightedCell ? "cursor-pointer" : ""
        } cell-container cell-${cellAddress}`}
      >
        <>
          {hasComment.excelComment && (
            <div className="rk-cell-comment-excel"></div>
          )}
          {hasComment.comment && <div className="rk-cell-comment"></div>}
          <div className="rk-cell-value">
            {parsedStyle.numFmt
              ? format(parsedStyle.numFmt, toNumber(value))
              : value?.toString()}
          </div>
        </>
      </div>
    );
  }
);

export default Cell;
