import { type StateCreator } from "zustand";
import { IFileVersion, IWorkspaceItem } from "../../typings";

export type FileVersionSlice = {
  versions: IFileVersion[];
  showComments: boolean;
  actions: {
    setVersions: (versions: IFileVersion[]) => void;
    toggleComments: () => void;
    setEnrolledFile: (file: IWorkspaceItem) => void;
    setInitialVersionsData: (
      versions: IFileVersion[]
    ) => void;
  };
  enrolledFile: IWorkspaceItem | null;
};

export const createFileVersionSlice: StateCreator<FileVersionSlice> = (
  set
) => ({
  versions: [],
  showComments: false,
  enrolledFile: null,
  actions: {
    setVersions: (versions: IFileVersion[]) => {
      set({ versions });
    },
    setEnrolledFile: (file: IWorkspaceItem) => {
      set({ enrolledFile: file });
    },
    toggleComments: () => {
      set((state) => ({ showComments: !state.showComments }));
    },
    setInitialVersionsData: (
      versions: IFileVersion[],
    ) => {
      set({ versions });
    },
  },
});
