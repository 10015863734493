import { IconDots, IconMinus, IconPlus } from "@tabler/icons-react";
import { truncateString } from "../../../helpers/truncateString";
import ButtonTabs from "../../DS/ButtonTabs";
import { Menu } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";

import { SelectedSheet } from "../types.ts";
import { CHANGES_SEEN_STATUS } from "../../../common/constants.ts";
import { useSearchParams } from "react-router-dom";
import { useEnrolledFile } from "../../../store/index.ts";

export const GridFooter = ({
  sheets,
  selectedSheet,
  onSheetSelected,
  zoomLevel,
  setZoomLevel,
}: {
  selectedSheet: SelectedSheet;
  sheets: { value: string; label: string }[];
  onSheetSelected: (index: number, value: string) => void;
  zoomLevel: number;
  setZoomLevel: (level: number) => void;
}) => {
  const [searchParams] = useSearchParams();
  const currentVersionId = searchParams.get("currentVersion");
  const enrolledFile = useEnrolledFile();
  const fileId = enrolledFile?.msId || "";

  const [activeTab, setActiveTab] = useState(0);

  // on unmount mark the current sheet as seen

  const markSheetAsSeen = useCallback(
    (sheetName: string) => {
      const sheetExistsInSeenStatus = getSeenStatusOfSheet(sheetName);
      if (sheetExistsInSeenStatus === undefined) return;
      const seenStatusOfCurrentFile =
        seenStatus[`${fileId}-${currentVersionId}`];
      const seenStatusOfSheet = seenStatusOfCurrentFile?.find(
        (status: any) => status.sheetName === sheetName
      );
      seenStatusOfSheet.isSeen = true;
      localStorage.setItem(
        CHANGES_SEEN_STATUS,
        JSON.stringify({
          ...seenStatus,
          [`${fileId}-${currentVersionId}`]: seenStatusOfCurrentFile,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    return () => {
      markSheetAsSeen(selectedSheet.wsName);
    };
  }, [markSheetAsSeen, selectedSheet.wsName]);

  useEffect(() => {
    if (selectedSheet.wsName !== "") {
      const index = sheets.findIndex(
        (sheet) => sheet.value === selectedSheet.wsName
      );
      if (index !== -1) {
        setActiveTab(index);
      }
    }
  }, [selectedSheet.wsName, sheets]);

  const handleZoomIn = () => {
    if (zoomLevel >= 2) return;
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    if (zoomLevel <= 0.4) return;
    setZoomLevel(zoomLevel - 0.1);
  };

  const visibleSheets = sheets.map((item) => item.value).slice(0, 9);

  const seenStatus = JSON.parse(
    localStorage.getItem(CHANGES_SEEN_STATUS) || "{}"
  );

  const getSeenStatusOfSheet = (sheetName: string) => {
    const seenStatusOfCurrentFile = seenStatus[`${fileId}-${currentVersionId}`];
    const seenStatusOfSheet = seenStatusOfCurrentFile?.find(
      (status: any) => status.sheetName === sheetName
    );
    return seenStatusOfSheet?.isSeen;
  };

  const renderSeenStatus = (sheetName: string) => {
    const seenStatusOfSheet = getSeenStatusOfSheet(sheetName);
    if (seenStatusOfSheet === false) {
      return <div className="bg-red-500 w-[7px] h-[7px] rounded-full ml-2" />;
    }
    if (seenStatusOfSheet === true) {
      return <div className="bg-gray-400 w-[7px] h-[7px] rounded-full ml-2" />;
    }
    return null;
  };

  return (
    <div className="h-[70px] w-full border-t px-6 drop-shadow-xl flex bg-white z-[100] items-center justify-between">
      <div className="flex w-full z-[1000]  items-center">
        <div className="w-fit flex items-center">
          <ButtonTabs
            items={visibleSheets.map((item, idx) => ({
              label: (
                <div className="flex items-center" key={idx}>
                  {truncateString(item, 15)}
                  {renderSeenStatus(item)}
                </div>
              ),
              value: item,
              onClick: (item) => {
                onSheetSelected(visibleSheets.indexOf(item.value), item.value);
                markSheetAsSeen(selectedSheet.wsName);
              },
            }))}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {sheets.length > 9 && (
            <Menu>
              <Menu.Target>
                <div className="flex items-center">
                  <IconDots size={20} className="ml-3 cursor-pointer" />
                </div>
              </Menu.Target>
              <Menu.Dropdown className="max-h-[200px] !z-[10000] overflow-y-auto">
                {sheets.map((sheet, index) => (
                  <Menu.Item
                    onClick={() => {
                      const index = sheets.indexOf(sheet);
                      setActiveTab(index);
                      onSheetSelected(sheets.indexOf(sheet), sheet.value);
                    }}
                    className={`text-sm w-[150px] mb-1 flex items-center font-medium hover:bg-DS-brand-50 ${
                      index === selectedSheet.wsIdx ? "bg-DS-brand-50" : ""
                    }`}
                  >
                    {truncateString(sheet.label, 15)}
                    {renderSeenStatus(sheet.label)}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          )}
        </div>
      </div>
      <div className="z-[10000] flex items-center">
        <IconMinus
          onClick={() => handleZoomOut()}
          className="mr-3 cursor-pointer text-sm font-medium"
          size={20}
        />
        <Menu withArrow>
          <Menu.Target>
            <div className="mr-3 cursor-pointer hover:bg-DS-brand-50 p-1 rounded text-sm font-medium">
              {(zoomLevel * 100).toFixed(0)}%
            </div>
          </Menu.Target>
          <Menu.Dropdown className="max-h-[200px] !z-[10000] overflow-y-auto">
            {[150, 200, 100, 10, 25, 50, 75].map((level) => (
              <Menu.Item
                key={level}
                onClick={() => {
                  setZoomLevel(level / 100);
                }}
                className={`text-sm w-[150px] mb-1 font-medium hover:bg-DS-brand-50 ${
                  level === zoomLevel * 100 ? "bg-DS-brand-50" : ""
                }`}
              >
                {level}%
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>

        <IconPlus
          onClick={() => handleZoomIn()}
          size={20}
          className="cursor-pointer text-sm font-medium"
        />
      </div>
    </div>
  );
};
