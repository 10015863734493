import { AppShell } from "@mantine/core";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/DS/navbar/Navbar";
import WithMsAuth from "../hocs/WithMsAuth";

const AppShellOutlet: React.FC = () => {
  const pathname = useLocation().pathname;
  const showNavbar = () => {
    if (pathname.includes("/file/") && pathname.includes("/changes")) {
      return false;
    }
    return true;
  };

  return (
    <WithMsAuth>
      <AppShell
        navbar={{ width: 240, breakpoint: 0 }}
        className="font-sans text-gray-600 text-sm border-DS-gray-200"
        styles={() => ({
          root: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: "100%",
            width: "100%",
          },
          body: {
            flexGrow: 1,
            overflow: "auto",
          },
          main: {
            backgroundColor: "white",
            minHeight: "unset",
            flexGrow: 1,
            overflowX: "auto",
            display: "flex",
            flexWrap: "nowrap",
            padding: "0 0 0 0",
            paddingLeft: "calc(var(--mantine-navbar-width, 0px))",
          },
        })}
      >
        {showNavbar() && <Navbar />}
        <AppShell.Main className={`${showNavbar() && "ml-[240px]"}`}>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </WithMsAuth>
  );
};

export default AppShellOutlet;
