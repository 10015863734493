import { modalOverlayProps } from "@/helpers/theme";
import { ReactComponent as CheckSmallIcon } from "@assets/svg/DS/sys-icons/Check Small.svg";
import { UserNameWithAvatar } from "@components/DS/Avatar";
import {
  SearchableCombobox,
  SearchableOption,
} from "@components/SearchableCombobox";
import { TeamMember, TeamMembersList } from "@components/TeamMembersList";
import {
  useAddTeamMemberMutation,
  useRemoveTeamMemberMutation,
  useUpdateTeamMemberRoleMutation,
} from "@hooks/useTeamMutations";
import { useTenantUsers } from "@hooks/useTenantUsers";
import { useInternalMe } from "@hooks/utility";
import { Button, LoadingOverlay, Modal, Select, Tooltip } from "@mantine/core";
import { getTeam } from "@services/teams";
import { ExtendedIdentity } from "@services/user/user.types";
import { IconChevronDown } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "../../assets/svg/DS/sys-icons/Info.svg";

type InviteTeamModalProps = {
  opened: boolean;
  onClose: () => void;
};

const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const ROLES = [
  {
    value: "Contributor",
    label: "Contributor",
    description: "Cannot dismiss pending reviews, cannot manage team",
  },
  {
    value: "Manager",
    label: "Manager",
    description: "Cannot dismiss pending reviews, can manage team",
  },
  {
    value: "Admin",
    label: "Admin",
    description: "Full access",
  },
] as const;

export const InviteTeamModalHeader = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="body-lg-Regular flex px-4 items-center">
        <span className="font-semibold">Team members</span>
      </div>
    </div>
  );
};

const InviteTeamModal: React.FC<InviteTeamModalProps> = ({
  opened,
  onClose,
}) => {
  const { userData } = useInternalMe();
  const [email, setEmail] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("Contributor");
  const [error, setError] = useState<string>("");
  const {
    data: tenantUsers = [],
    isLoading: isTenantUsersLoading,
    error: fetchTenantUsersError,
  } = useTenantUsers(email);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  const teamId = userData?.teamMembers?.[0]?.team?.internalId?.toString() || "";
  const addTeamMemberMutation = useAddTeamMemberMutation(teamId);
  const updateTeamMemberRoleMutation = useUpdateTeamMemberRoleMutation(teamId);
  const removeTeamMemberMutation = useRemoveTeamMemberMutation(teamId);

  const { data: teamData, isLoading: isTeamDataLoading } = useQuery({
    queryKey: ["team", teamId],
    queryFn: () => getTeam(teamId),
    enabled: !!teamId,
  });

  const isAnythingLoading =
    addTeamMemberMutation.isPending ||
    updateTeamMemberRoleMutation.isPending ||
    removeTeamMemberMutation.isPending ||
    isTeamDataLoading;

  useEffect(() => {
    if (
      teamData?.teamMembers &&
      !isTenantUsersLoading &&
      tenantUsers?.length > 0
    ) {
      setTeamMembers(
        teamData.teamMembers
          .filter((member) => member.user.msId)
          .map((member) => {
            const user = tenantUsers.find(
              (user) => user.id === member.user.msId
            );
            return {
              id: member.user.msId || "",
              email:
                user?.email || member.user.email || member.user.username || "",
              role: member.role,
              name:
                user?.displayName ||
                `${member.user.firstName || ""} ${
                  member.user.lastName || ""
                }`.trim() ||
                member.user.email ||
                "",
              isCurrentUser: member.user.email === userData?.email,
            };
          })
      );
    }
  }, [isTenantUsersLoading, teamData, tenantUsers, userData?.email]);

  const handleSendInvite = useCallback(async () => {
    if (!email) {
      setError("Email cannot be empty");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    if (teamMembers.some((member) => member.email === email)) {
      setError("This email has already been added");
      return;
    }

    const selectedUser = tenantUsers.find((user) => user.email === email);
    if (!selectedUser) {
      setError("This email is not in your organization");
      return;
    }

    if (!teamId) {
      setError("No team found");
      return;
    }

    try {
      await addTeamMemberMutation.mutateAsync({
        userMsId: selectedUser.id || "",
        role: selectedRole as "Admin" | "Manager" | "Contributor",
      });
      setEmail("");
      setError("");
      setSelectedRole("Contributor");
    } catch (error) {
      console.error("Failed to add team member:", error);
      setError("Failed to add team member. Please try again.");
    }
  }, [
    email,
    teamMembers,
    tenantUsers,
    teamId,
    selectedRole,
    addTeamMemberMutation,
  ]);

  const handleEmailChange = useCallback((value: string | null) => {
    setEmail(value || "");
    setError("");
  }, []);

  const handleEmailInputChange = useCallback((value: string) => {
    setEmail(value);
    setError("");
  }, []);

  const handleClearEmail = useCallback(() => {
    setEmail("");
    setError("");
  }, []);

  const handleRoleChange = useCallback(
    async (email: string, newRole: string) => {
      if (!teamId) {
        setError("No team found");
        return;
      }

      const memberToUpdate = teamMembers.find(
        (member) => member.email === email
      );
      if (!memberToUpdate) {
        setError("Member not found");
        return;
      }

      try {
        await updateTeamMemberRoleMutation.mutateAsync({
          userId: memberToUpdate.id,
          role: newRole as "Owner" | "Admin" | "Manager" | "Contributor",
        });
      } catch (error) {
        console.error("Failed to update team member role:", error);
        setError("Failed to update team member role. Please try again.");
      }
    },
    [teamMembers, teamId, updateTeamMemberRoleMutation]
  );

  const handleRemoveMember = useCallback(
    async (email: string) => {
      if (!teamId) {
        setError("No team found");
        return;
      }

      const memberToRemove = teamMembers.find(
        (member) => member.email === email
      );
      if (!memberToRemove) {
        setError("Member not found");
        return;
      }

      try {
        await removeTeamMemberMutation.mutateAsync(memberToRemove.id);
      } catch (error) {
        console.error("Failed to remove team member:", error);
        setError("Failed to remove team member. Please try again.");
      }
    },
    [teamMembers, teamId, removeTeamMemberMutation]
  );

  const renderUserOption = useCallback((option: SearchableOption) => {
    const user = option.data as ExtendedIdentity;
    return (
      <div className="flex items-center gap-2 w-full">
        <UserNameWithAvatar
          truncate={undefined}
          user={{
            msId: user.id || "",
            email: user.email || "",
            username: user.displayName || "",
          }}
        />
        <span className="text-DS-gray-500 shrink-0 whitespace-nowrap">
          ({user.email})
        </span>
      </div>
    );
  }, []);

  const userOptions: SearchableOption[] = tenantUsers
    .filter(
      (user) => !teamMembers.some((member) => member.email === user.email)
    )
    .filter(
      (user) =>
        !email ||
        user.email?.toLowerCase().includes(email.toLowerCase()) ||
        user.displayName?.toLowerCase().includes(email.toLowerCase())
    )
    .map((user) => ({
      value: user.email || "",
      label: user.displayName || "",
      data: user,
    }));

  return (
    <Modal
      id="invite-team-modal"
      title={<InviteTeamModalHeader />}
      opened={opened}
      onClose={onClose}
      size="50rem"
      centered
      styles={{ body: { padding: 0, height: "fit-content" } }}
      overlayProps={modalOverlayProps}
    >
      <div className="flex flex-col items-center border-t w-full justify-stretch relative">
        <LoadingOverlay
          loaderProps={{ size: "sm" }}
          visible={isAnythingLoading}
        />
        <div className="flex py-5 px-6 w-full gap-6">
          <SearchableCombobox
            value={email}
            error={error}
            isLoading={isTenantUsersLoading}
            fetchError={fetchTenantUsersError}
            options={userOptions}
            placeholder="Email address"
            onChange={handleEmailChange}
            onInputChange={handleEmailInputChange}
            onClear={handleClearEmail}
            onSubmit={handleSendInvite}
            renderOption={renderUserOption}
          />
          <Select
            value={selectedRole}
            onChange={(value) => setSelectedRole(value || "Contributor")}
            data={ROLES}
            withCheckIcon={false}
            classNames={{
              root: "w-[180px]",
              wrapper: "border border-DS-gray-300 rounded h-10",
              dropdown: "-mt-[5px] border-t-0",
            }}
            rightSection={
              <IconChevronDown size={16} className="text-DS-gray-400" />
            }
            renderOption={({ option, checked }) => {
              const roleOption = option as {
                value: string;
                label: string;
                description: string;
              };
              return (
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col">
                    <span className="text-DS-gray-600 text-sm font-medium leading-5">
                      {roleOption.value}
                    </span>
                  </div>
                  {checked && (
                    <CheckSmallIcon
                      className="text-DS-gray-400"
                      width={18}
                      height={18}
                    />
                  )}
                  <Tooltip
                    label={roleOption.description}
                    position="right"
                    withArrow
                    multiline
                    events={{ hover: true, focus: true, touch: true }}
                    classNames={{
                      tooltip:
                        "bg-DS-gray-900 text-white text-sm max-w-[200px]",
                    }}
                  >
                    <div className="cursor-help">
                      <InfoIcon
                        className="text-DS-gray-400"
                        width={18}
                        height={18}
                      />
                    </div>
                  </Tooltip>
                </div>
              );
            }}
          />
          <Button
            variant="filled"
            className="bg-DS-brand-400 hover:bg-DS-brand-500 font-medium px-4 py-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-DS-brand-200 text-sm h-9 whitespace-nowrap"
            onClick={handleSendInvite}
          >
            Send invite
          </Button>
        </div>

        <TeamMembersList
          teamMembers={teamMembers}
          onRoleChange={handleRoleChange}
          onRemoveMember={handleRemoveMember}
        />
      </div>
    </Modal>
  );
};

export default InviteTeamModal;
