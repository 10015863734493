import { Button, CheckIcon } from "@mantine/core";
import { Link } from "react-router-dom";
import { ReactComponent as IconAlertHexagonFilled } from "../../../assets/svg/DS/alert.svg";
import { useEnrolledFile, useVersions } from "../../../store";
import { findLatestVersionId } from "../../../store/utils";

export const ChangesHeader = () => {
  const enrolledFile = useEnrolledFile();
  const versions = useVersions();
  const hasUnseenVersionChanges = enrolledFile?.hasUncommittedChanges;
  const latestVersion = enrolledFile?.latestVersion;

  return (
    <>
      <div className="flex items-center w-full flex-wrap justify-between">
        {!hasUnseenVersionChanges && (
          <div className="flex w-full items-center justify-between">
            <div className="mr-2 flex items-center w-[400px]">
              <CheckIcon size={18} className="text-DS-green-500" />
              <p className="heading-sm-SemiBold ml-4 text-DS-gray-700">
                Up to date
              </p>
            </div>
            <div className="flex items-center w-full">
              <p className="body-md-Regular text-DS-gray-600 flex justify-end w-full">
                No changes found since the last version was created (
                {latestVersion?.majorVersion}.{latestVersion?.minorVersion}.
                {latestVersion?.patchVersion})
              </p>
            </div>
          </div>
        )}
        {hasUnseenVersionChanges && (
          <div className="flex w-full items-center justify-between">
            <div>
              <div className="mr-2 flex items-center w-fit">
                <IconAlertHexagonFilled />
                <p className="heading-sm-SemiBold ml-4 text-DS-gray-700">
                  Changes found
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <p>
                This file has been edited since the last version was created (
                {latestVersion?.majorVersion}.{latestVersion?.minorVersion}.
                {latestVersion?.patchVersion})
              </p>
              <Link
                to={`/file/${
                  enrolledFile?.msId
                }/changes?currentVersion=live&previousVersion=${findLatestVersionId(
                  enrolledFile,
                  versions
                )}`}
              >
                <Button
                  styles={{
                    root: { height: "32px" },
                  }}
                  className="bg-DS-brand-400 hover:bg-DS-brand-500 font-medium ml-6 body-md-Regular"
                  variant="filled"
                >
                  View changes
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
