import { ChangeTypes } from "../../../store/slices/DifferencesSlice";
import { TWorkbookDiffSheetCellProcessingStatus } from "../../../typings";

type CellValue = {
  w?: string;
  v: string | number;
};

interface Diff {
  cell: string;
  cell1: CellValue | null;
  cell2: CellValue | null;
  byUserMsId: string | null;
  processingStatus?: TWorkbookDiffSheetCellProcessingStatus;
}

export interface SheetChange {
  sheetName: string;
  commonDiffs: Diff[];
  removed: Diff[];
  added: Diff[];
}

interface User {
  id: string;
}

export type Change = {
  type: ChangeTypes;
  cell?: string;
  before?: string | number;
  after?: string | number;
  version?: string;
  notes?: string;
  date?: string;
  sheetName?: string;
  user?: { id: string | null };
  processingStatus?: TWorkbookDiffSheetCellProcessingStatus | null;
};

export interface ChangeRecord {
  user: User;
  date: string;
  changes: Change[];
  sheetName?: string;
}

export function transformData(input: {
  diffs: Array<
    SheetChange &
      Partial<{
        majorVersion: string;
        minorVersion: string;
        patchVersion: string;
      }>
  >;
  date?: string;
  versionCreatorId: string;
}): Array<
  ChangeRecord &
    Partial<{
      majorVersion: string;
      minorVersion: string;
      patchVersion: string;
    }>
> {
  const diffs = input.diffs;
  return diffs.map((item) => {
    const changes: Change[] = [];

    // Handle common differences as 'CHANGED'
    item.commonDiffs.forEach(
      ({ cell, cell1, cell2, byUserMsId, processingStatus }) => {
        changes.push({
          type: ChangeTypes.CHANGED,
          cell,
          before: cell1?.w || cell1?.v || "N/A",
          after: cell2?.w || cell2?.v || "N/A",
          user: { id: byUserMsId },
          processingStatus,
          sheetName: item.sheetName,
        });
      }
    );

    // Handle 'removed' cells
    item.removed.forEach(({ cell, cell1, byUserMsId, processingStatus }) => {
      if (cell1) {
        changes.push({
          type: ChangeTypes.REMOVED,
          cell,
          before: cell1?.w || cell1?.v || "N/A",
          user: { id: byUserMsId },
          processingStatus,
          sheetName: item.sheetName,
        });
      }
    });

    // Handle 'added' cells
    item.added.forEach(({ cell, cell2, byUserMsId, processingStatus }) => {
      if (cell2) {
        changes.push({
          type: ChangeTypes.ADDED,
          cell,
          after: cell2?.w || cell2?.v || "N/A",
          user: { id: byUserMsId },
          processingStatus,
          sheetName: item.sheetName,
        });
      }
    });

    return {
      user: { id: "" }, // Placeholder for user name
      date: input.date || "",
      changes,
      sheetName: item.sheetName,
      majorVersion: item?.majorVersion,
      minorVersion: item?.minorVersion,
      patchVersion: item?.patchVersion,
    };
  });
}
