import { useState } from "react";
import Button from "../../../components/DS/Button";
// import SearchInput from "../../../components/DS/SearchInput";
import RightDrawer from "../../../components/DS/right-drawer";
import { useMe } from "../../../hooks/utility";
import { addFileToWorkspace, createEnrolledFile } from "../../../services/file";
import { IWorkspaceItem } from "../../../typings";
import FileBrowser from "./EnrollFilesFileBrowser";
import {
  loadingNotification,
  regularNotification,
} from "../../../helpers/notifications";
import { useMutation } from "@tanstack/react-query";
import { useActions, useDrawerOpened } from "../../../store";

export const EnrollFilesTabs = [
  {
    label: "My drive",
    value: "my-drive",
    className: "text-sm font-medium whitespace-nowrap",
  },
  {
    label: "Shared with me",
    value: "shared-with-me",
    className: "text-sm font-medium whitespace-nowrap",
  },
];

const EnrollFilesDrawer = ({ refetch }: { refetch: any }) => {
  const [checkedWorkspaceItems, setCheckedWorkspaceItems] = useState<
    IWorkspaceItem[]
  >([]);
  const opened = useDrawerOpened();
  const { setDrawerOpened } = useActions();
  const me = useMe();

  const addFilesMutation = useMutation({
    mutationKey: ["addFilesToWorkspace"],
    mutationFn: addFileToWorkspace,
    onSuccess: () => {
      regularNotification({
        title: "Success",
        message: `File added to your workspace`,
      });
    },
    onError: () => {
      regularNotification({
        title: "Error",
        message: `Failed to add files to your workspace`,
      });
    },
  });

  const enrollFiles = async () => {
    if (checkedWorkspaceItems?.length > 0) {
      for (const file of checkedWorkspaceItems) {
        await createEnrolledFile({
          file: file,
          userMsId: me?.id,
        });
        await addFileToWorkspace(file.msId);
      }
    } else {
      throw new Error('Please select a file to enroll');
    }
  };

  const createEnrolledFileMutation = useMutation({
    mutationFn: enrollFiles,
    onMutate: () => {
      loadingNotification({ message: `Enrolling your files...` });
    },
    onSuccess: async () => {
      const filesEnrolled = checkedWorkspaceItems.length;
      regularNotification({
        title: "Success",
        message: `${filesEnrolled} ${
          filesEnrolled === 1 ? "file" : "files"
        } enrolled successfully`,
      });
      await refetch();
      setDrawerOpened(false);
    },
    onError: (error) => {
      regularNotification({
        title: "Error",
        message: `Failed to enroll your files: ${error.message}`,
      });
    },
  });

  return (
    <RightDrawer
      title="File manager"
      opened={opened}
      close={() => {
        setDrawerOpened(false);
      }}
      callToAction={
        <Button
          className="font-medium"
          variant="blue"
          onClick={() => {
            createEnrolledFileMutation.mutate();
          }}
          disabled={
            !(checkedWorkspaceItems.length > 0) ||
            addFilesMutation.isPending ||
            createEnrolledFileMutation.isPending
          }
        >
          Enroll file(s)
        </Button>
      }
    >
      <div className="flex flex-col grow" id="file-manager">
        <div id="file-manager-content" className="">
          <FileBrowser
            refetch={refetch}
            setCheckedWorkspaceItems={setCheckedWorkspaceItems}
          />
        </div>
      </div>
    </RightDrawer>
  );
};

export default EnrollFilesDrawer;
