import { Button, Select, Textarea } from "@mantine/core";
import { modals } from "@mantine/modals";
import React, { useState } from "react";
import { createVersion } from "../../../services/versions";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../../helpers/notifications";
import { useNavigate, useParams } from "react-router-dom";
import { checkErrorResponse } from "../../../helpers/checkErrorResponse";
import { IconChevronDown } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

export const CreateVersionHeader = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="body-lg-Regular flex px-4 items-center">
        <span className="font-semibold">New version</span>
      </div>
    </div>
  );
};

type VersionType = {
  majorVersion: number;
  minorVersion: number;
  patchVersion: number;
};

type Props = {
  versionOptions: {
    label: string;
    value: VersionType;
  }[];
};

export const CreateVersionBody = ({ versionOptions }: Props) => {
  const [status, setStatus] = useState<
    "idle" | "success" | "error" | "loading"
  >("idle");
  const params = useParams();
  const [notes, setNotes] = useState("");
  const options = versionOptions.map((option) => ({
    label: option.label,
    value: `v${option.value.majorVersion}.${option.value.minorVersion}.${option.value.patchVersion}`,
  }));
  const [selectedVersion, setSelectedVersion] = React.useState<{
    majorVersion: number;
    minorVersion: number;
    patchVersion: number;
  } | null>(versionOptions[1].value);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const submitNewVersion = async () => {
    try {
      if (status === "loading") return;
      setStatus("loading");
      if (selectedVersion) {
        loadingNotification({
          title: "Creating a new version. Please wait...",
        });
        const res = await createVersion({
          enrolledFileMsId: params.fileId || "",
          version: {
            description: notes,
            majorVersion: selectedVersion?.majorVersion,
            minorVersion: selectedVersion?.minorVersion,
            patchVersion: selectedVersion?.patchVersion,
          },
        }).finally(() => {
          // no matter what happens
          //trigger a refetch of the enrolled file
          queryClient.invalidateQueries({ queryKey: ["enrolledFile"] });
        });
        if (checkErrorResponse(res)) {
          regularNotification({
            title: "Something went wrong. Please try again later.",
          });
          return;
        }
        setStatus("success");
        navigate(`/file/${params.fileId}/versions`);
        modals.closeAll();
        successNotification({
          title: `Version ${selectedVersion.majorVersion}.${selectedVersion.minorVersion}.${selectedVersion.patchVersion} created`,
        });
      }
      setStatus("success");
    } catch (err) {
      setStatus("idle");
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col items-center border-t">
      <div className="body-lg-Regular flex px-4 items-center w-full mt-8">
        <div className="flex justify-between pl-4 w-full items-center">
          <span className="font-normal text-sm">Select version number</span>
          <Select
            className="border rounded"
            checkIconPosition="right"
            rightSection={<IconChevronDown size={15} />}
            withCheckIcon
            classNames={{
              root: "hover:outline focus:outline outline-DS-brand-300",
              input: "focus:!outline !outline-DS-brand-300",
              dropdown: "drop-shadow-md p-0 py-0 m-0",
              option: "hover:bg-DS-brand-25 h-[40px]",
            }}
            styles={{
              input: {
                paddingLeft: "14px",
                cursor: "pointer",
              },
            }}
            onChange={(value) => {
              const selected = versionOptions.find(
                (option) =>
                  `v${option.value.majorVersion}.${option.value.minorVersion}.${option.value.patchVersion}` ===
                  value
              );
              setSelectedVersion(selected ? selected?.value : null);
            }}
            value={
              selectedVersion
                ? `v${selectedVersion.majorVersion}.${selectedVersion.minorVersion}.${selectedVersion.patchVersion}`
                : null
            }
            searchable
            placeholder="1.0.0(major version)"
            data={options}
          />
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitNewVersion();
        }}
        className="flex flex-col pt-4 w-full border-t mt-8"
      >
        <div className="w-full border-b px-4">
          <Textarea
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            styles={{
              input: { height: 120, width: "100%", textAlign: "left" },
            }}
            required
            placeholder="Write a short description of what changed in this version..."
            className="body-md-Regular text-left text-DS-gray-800  mx-1 w-full"
          />
        </div>
        <div className="flex w-full items-center justify-end p-4 ">
          <Button
            type="button"
            styles={{ root: { height: "32px" } }}
            variant="outline"
            className="font-medium hover:bg-DS-gray-25 text-DS-gray-600 border-DS-gray-300"
            onClick={() => modals.closeAll()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            styles={{ root: { height: "32px" } }}
            disabled={
              selectedVersion && selectedVersion?.majorVersion ? false : true
            }
            variant="filled"
            className="ml-5 disabled:bg-gray-50 font-medium hover:bg-DS-brand-500 bg-DS-brand-400"
          >
            Create version
          </Button>
        </div>
      </form>
    </div>
  );
};
