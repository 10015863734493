import { QueryClient, QueryClientProvider, focusManager } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { getRouter } from "./Router";
import { msalInstance as pca } from "./services/graph";

// Create a react query client
const queryClient = new QueryClient();

focusManager.setEventListener((onFocus) => {
  const listener = () => onFocus();
  window.addEventListener('visibilitychange', listener, false);
  window.addEventListener('focus', listener, false);

  return () => {
    window.removeEventListener('visibilitychange', listener);
    window.removeEventListener('focus', listener);
  }
});

const App: React.FC = () => {
  const gtmParams = {
    id: import.meta.env.VITE_GTM_ID,
  };
  return (
    <GTMProvider state={gtmParams}>
      <MsalProvider instance={pca}>
        <QueryClientProvider client={queryClient}>
          <UnauthenticatedTemplate>
            <RouterProvider router={getRouter.unauthenticated} />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <RouterProvider router={getRouter.authenticated} />
          </AuthenticatedTemplate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MsalProvider>
    </GTMProvider>
  );
};

export default App;
