import { Drawer } from "@mantine/core";
import React, { ReactNode } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/svg/DS/sys-icons/Close.svg";

interface Props {
  children: ReactNode;
  title: string;
  onClose?: () => void;
  callToAction?: ReactNode;
  opened: boolean;
  close: () => void;
}

interface DrawerHeaderProps {
  title: string;
  onClose?: () => void;
  callToAction?: ReactNode;
  closeDrawer: () => void;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  title,
  closeDrawer,
  callToAction,
}) => {
  return (
    <div className="flex items-center justify-between px-8 w-full border-b border-b-DS-gray-200">
      <div className="flex items-center py-3 h-[65px]">
        <CloseIcon
          onClick={closeDrawer}
          className="cursor-pointer text-DS-gray-500"
        />
        <div className="ml-4 text-DS-gray-800 body-xl-SemiBold">{title}</div>
      </div>
      {callToAction && <div>{callToAction}</div>}
    </div>
  );
};

const RightDrawer: React.FC<Props> = ({
  children,
  opened = false,
  close,
  callToAction,
  title,
}) => {

  return (
    <Drawer
      opened={opened}
      onClose={close}
      position="right"
      withCloseButton={false}
      styles={{
        content: {
          minWidth: "840px",
        },
        body: {
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
      }}
      overlayProps={{ backgroundOpacity: 0.16, color: "#222533" }}
    >
      <DrawerHeader
        title={title}
        closeDrawer={close}
        callToAction={callToAction}
      />
      <div className="grow flex">{children}</div>
    </Drawer>
  );
};

export default RightDrawer;
