export const ignoreNodesList = [
  `sheetPr`,
  `dimension`,
  `sheetViews `,
  `cols `,
  `autoFilter `,
  `rowBreaks`,
  `hyperlinks `,
  `pageMargins`,
  `dataValidations`,
  `pageSetup`,
  `headerFooter `,
  `printOptions `,
  `picture`,
  `drawing`,
  `sheetProtection`,
  `tableParts `,
  `conditionalFormatting`,
  `extLst`,
];
