import React, { useEffect, useState } from "react";
import classNames from "clsx";

type ItemType = {
  label: string | React.ReactNode;
  onClick?: (arg: ItemType) => void;
  value: string;
  className?: string;
};

export interface ButtonTabsProps {
  items: ItemType[];
  className?: string;
  activeTab?: number;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
  [key: string]: unknown;
}

const ButtonTabs: React.FC<ButtonTabsProps> = ({
  items,
  className = "",
  activeTab = 0,
  setActiveTab,
  ...props
}) => {
  const [active, setActive] = useState(activeTab);

  useEffect(() => {
    setActive(activeTab);
  }, [activeTab]);

  return (
    <div
      className={`flex flex-row space-x-2 bg-DS-gray-50 items-center rounded ${className}`}
      {...props}
    >
      {items.map((item, index) => (
        <div
          key={item.value}
          onClick={() => {
            setActive(index);
            if (setActiveTab) setActiveTab(index);
            if (item?.onClick) item.onClick(item);
          }}
          className={classNames(
            "px-4 py-[0.34rem] rounded-smd cursor-pointer transition-colors duration-200 flex items-center justify-center",
            {
              "bg-white text-DS-gray-700 border border-DS-gray-300":
                active === index,
              "bg-transparent text-DS-gray-600 border border-DS-gray-50":
                active !== index,
            },
            item.className
          )}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default ButtonTabs;
