import WithMsAuth from "@/hocs/WithMsAuth";
import {
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as AutomateIcon } from "../../assets/svg/chip-automate.svg";
import { ReactComponent as ReduceIcon } from "../../assets/svg/chip-reduce.svg";
import { ReactComponent as TrackChangesIcon } from "../../assets/svg/chip-track-changes.svg";
import { ReactComponent as SignInWithMsIcon } from "../../assets/svg/sign-in-with-ms.svg";
import { LOGIN_SCOPES } from "../../authConfig";
import Logo from "../../components/DS/LogoNew";

const GettingStarted: React.FC = () => {
  const [permanentlyFailedSilentLogin, setPermanentlyFailedSilentLogin] =
    useState(false);
  const [isLoginButtonDisabled, setIsButtonDisabled] = useState(false);

  const { login, error } = useMsalAuthentication(InteractionType.Silent, {
    scopes: LOGIN_SCOPES,
  });

  const retrySilentLogin = useCallback(
    async (retries: number) => {
      setIsButtonDisabled(true);
      for (let i = 0; i < retries; i++) {
        try {
          await login(InteractionType.Silent, { scopes: LOGIN_SCOPES });
          break; // Exit loop if login is successful
        } catch (_err) {
          if (i === retries - 1) {
            console.error("Max retries reached. Silent login failed.");
            setPermanentlyFailedSilentLogin(true);
          } else {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before retrying
          }
        }
      }
      setIsButtonDisabled(false);
    },
    [login]
  );

  useEffect(() => {
    if (error && !(error instanceof InteractionRequiredAuthError)) {
      retrySilentLogin(3);
    }
  }, [error, retrySilentLogin]);

  const handleLoginClick = () => {
    if (
      error instanceof InteractionRequiredAuthError ||
      permanentlyFailedSilentLogin
    ) {
      setIsButtonDisabled(true);
      login(InteractionType.Redirect, { scopes: LOGIN_SCOPES }).finally(() => {
        setIsButtonDisabled(false);
      });
    }
  };

  return (
    <WithMsAuth>
      <div className="flex justify-center items-center h-screen bg-DS-brand-50 w-full">
        <section className="flex flex-col items-center gap-6 p-12 pt-10 bg-white rounded-lg max-w-[26.25rem] mx-auto">
          <Logo />
          <h2 className="text-center text-base2 font-semibold">Sign in</h2>
          <div className="w-6 h-0.5 bg-DS-brand-500"></div>
          <p
            id="taskpane-card-description"
            className="text-center font-sans text-sm2 leading-6"
          >
            <b>Rockhopper</b> is the most effective solution for finance teams
            to collaborate smarter and more efficiently with spreadsheets.
          </p>
          <div className="flex flex-row gap-2 justify-center flex-wrap">
            <TrackChangesIcon title="Track Changes" />
            <AutomateIcon title="Automate" />
            <ReduceIcon title="Reduce" />
          </div>
          <p
            id="taskpane-card-cta"
            className="text-center font-sans text-sm leading-4.5 mx-4"
          >
            Log in or create a <b>Rockhopper</b> account and get started:
          </p>
          <SignInWithMsIcon
            className={`cursor-pointer ${
              isLoginButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            title="Sign in with Microsoft"
            onClick={handleLoginClick}
            style={{ pointerEvents: isLoginButtonDisabled ? "none" : "auto" }}
          />
        </section>
      </div>
    </WithMsAuth>
  );
};

export default GettingStarted;
