import { Menu } from "@mantine/core";
import { useState } from "react";
import { useCommentInput, useActions } from "../../../store";
import { getCellTagJSON } from "../../../store/utils";

type Props = {
  trigger: React.ReactNode;
  onViewEditHistory: () => void;
  onAddToCommentCallback: () => void;
  sheetName: string;
  cellAddress: string;
};

export function CellMenuOptions({
  trigger,
  onViewEditHistory,
  onAddToCommentCallback,
  sheetName,
  cellAddress,
}: Props) {
  const [opened, setOpened] = useState(false);
  const commentInput = useCommentInput();
  const { setCommentInput, setActiveTab } = useActions();

  const onCellClick = (sheetName: string, cell: string) => {
    setActiveTab(1);
    const tagCellJsonString = getCellTagJSON(sheetName, cell);
    const commentString = commentInput
      ? commentInput + ` ${tagCellJsonString}`
      : tagCellJsonString;
    setCommentInput(commentString);
    // Focus on the comment input, if it's already mounted
    const commentTextareaElement = document.querySelector("#comment-input textarea");
    if (commentTextareaElement) {
      (commentTextareaElement as HTMLElement).focus();
    }
  };

  return (
    <Menu
      onClose={() => {
        setOpened(false);
      }}
      opened={opened}
      width={200}
      shadow="md"
      trigger="click"
    >
      <Menu.Target>
        <div
          onContextMenu={(e) => {
            e.preventDefault();
            if (e.type === "contextmenu") {
              setOpened(true);
            }
          }}
          className="w-full"
        >
          {trigger}
        </div>
      </Menu.Target>
      <Menu.Dropdown className="bg-gray-800">
        <Menu.Item
          onClick={onViewEditHistory}
          className="text-white hover:bg-gray-700"
        >
          View edit history
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            onCellClick(sheetName, cellAddress);
            onAddToCommentCallback();
          }}
          className="text-white hover:bg-gray-700"
        >
          Add comment
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
