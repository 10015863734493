import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ReactComponent as PauseIcon } from "../../assets/svg/DS/pause.svg";
import { ReactComponent as DiscardIcon } from "../../assets/svg/DS/version-lines/discardIcon.svg";
import { ReactComponent as RestoreIcon } from "../../assets/svg/DS/version-lines/restoreIcon.svg";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../helpers/notifications";
import ActiveUsersRow from "./ActiveUsersRow";
import { requestFilePauseEdit } from "../../services/file";
import { useEnrolledFile } from "../../store";

export type RequestPauseType = "default" | "discard" | "revert" | "unsynced";

export const RequestPauseHeader = ({
  type,
  version,
}: {
  type: RequestPauseType;
  version?: string;
}) => {
  return (
    <div className="flex flex-col items-center">
      {type === "default" && (
        <div className="body-lg-Regular flex px-4 items-center">
          <PauseIcon className="text-gray-800" />
          <span className="font-semibold text-gray-800 text-sm ml-3">
            Request to pause edits on this file
          </span>
        </div>
      )}
      {type === "revert" && (
        <div className="body-lg-Regular flex pr-4 pl-2 items-center">
          <RestoreIcon className="mr-4" />{" "}
          <span className="font-semibold text-sm">
            Revert to {version || ""}
          </span>
        </div>
      )}
      {type === "discard" && (
        <div className="flex flex-col items-center">
          <div className="body-lg-Regular flex pr-4 pl-2 items-center">
            <DiscardIcon className="mr-4" />{" "}
            <span className="font-semibold text-sm">Discard changes?</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const RequestPauseBody = ({
  type,
  activeUsers,
  onSuccess,
}: {
  type: RequestPauseType;
  activeUsers: string[];
  onSuccess?: () => void;
}) => {
  const enrolledFile = useEnrolledFile();
  const { fileId } = useParams<{ fileId: string }>();
  const requestPauseEditMutation = useMutation({
    mutationFn: requestFilePauseEdit,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      successNotification({
        title: "Request to pause edits sent",
        message: "We will notify you as soon as all users have closed the file",
      });
    },
    onError: () => {
      regularNotification({
        title: "Error requesting to pause edits",
        message:
          "An error occurred while requesting to pause edits on this file",
      });
    },
    onMutate: () => {
      loadingNotification({
        title: "Requesting to pause edits",
        message: "Please wait while we request to pause edits on this file",
      });
    },
  });

  const getBodyText = () => {
    switch (type) {
      case "default":
        return (
          <div className="text-sm font-normal text-gray-600 p-6 pt-0">
            Pausing edits on this file will allow you to discard changes or to
            revert to a previous version of the file. <br /> <br />
            Once you request to pause edits will notify all the users working on
            this file and kindly ask them to wrap their work and close any tabs
            where they have the file open. <br /> <br />
            We will let you know as soon as all users have closed the file.
          </div>
        );
      case "discard":
        return (
          <div className="text-sm font-normal text-gray-600 p-6 pt-0">
            Cannot discard changes while users are working on this file. Please{" "}
            {enrolledFile?.pauseEditsRequestedBy === null
              ? "request to pause"
              : "wait for all users to pause"}{" "}
            edits on this file first. <br /> <br />
            Once you request to pause edits will notify all the users working on
            this file and kindly ask them to wrap their work and close any tabs
            where they have the file open. <br /> <br />
            We will let you know as soon as all users have closed the file.
          </div>
        );
      case "revert":
        return (
          <div className="text-sm font-normal text-gray-600 p-6 pt-0">
            Cannot revert to a previous version while users are working on this
            file. Please request to pause edits on this file first. <br />{" "}
            <br />
            Once you request to pause edits will notify all the users working on
            this file and kindly ask them to wrap their work and close any tabs
            where they have the file open. <br /> <br />
            We will let you know as soon as all users have closed the file.
          </div>
        );
      case "unsynced":
        return (
          <div className="text-sm font-normal text-gray-600 p-6 pt-0">
            Cannot create a new version while changes are being processed.
            <br /> <br />
            Once all the pending changes have been processed, review the change log
            again, and proceed to create a your new version.
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center border-t">
      <div className="body-lg-Regular flex px-4 items-center w-full mt-8">
        <div className="flex justify-between pl-4 w-full items-center"></div>
      </div>
      {getBodyText()}
      <div className="border-t p-6 w-full">
        <ActiveUsersRow activeUsers={activeUsers} requestPauseType={type} />
      </div>
      <div className="flex w-full border-t items-center justify-end p-4 ">
        <Button
          type="button"
          styles={{ root: { height: "32px" } }}
          variant="outline"
          className="font-medium hover:bg-DS-gray-25 text-DS-gray-600 border-DS-gray-300"
          onClick={() => modals.closeAll()}
        >
          Cancel
        </Button>
        {enrolledFile?.pauseEditsRequestedBy === null && (
          <Button
            disabled={requestPauseEditMutation.isPending}
            onClick={() => {
              requestPauseEditMutation.mutate(fileId || "");
            }}
            type="submit"
            styles={{ root: { height: "32px" } }}
            variant="filled"
            className="ml-5 disabled:bg-gray-50 font-medium hover:bg-DS-brand-500 bg-DS-brand-400"
          >
            Request a pause edits
          </Button>
        )}
      </div>
    </div>
  );
};
