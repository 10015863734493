import ExcelJS from "exceljs";
import { merge } from "lodash";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CHANGES_SEEN_STATUS } from "../../common/constants";
import { getVersionText } from "../../features/Version/utils";
import { getVersionFile } from "../../services/file";
import {
  compareLatestLiveVersions,
  compareVersions,
} from "../../services/versions";
import {
  useActions,
  useEnrolledFile,
  useVersionBlobFile,
  useVersions,
  useWorkSheetChanges,
} from "../../store";
import { ChangeTypes } from "../../store/slices/DifferencesSlice";
import { findLatestVersionId } from "../../store/utils";
import {
  IFileVersion,
  IVersionCompareResult,
  IWorkspaceItem,
} from "../../typings";
import FileHeader from "../file-versions/components/Header";
import Header from "./components/DiffHeader";
import ExcelVisualizer from "./components/ExcelVisualizer";
import { Sidebar } from "./components/sidebar";
import { ignoreNodesList } from "./helpers/ignoreNodesList";
import { ChangeRecord, transformData } from "./helpers/transformData";
import { useVersionDiffDataQueries } from "./hooks";
import { useSetEnrolledFile } from "../../hooks/file";

const VersionDiffsPage = () => {
  const { fileData, isLoadingFile } = useSetEnrolledFile();
  const enrolledFile = useEnrolledFile();
  const versions = useVersions();
  const [searchParams] = useSearchParams();
  const { setInitialVersionsData, clearDifferences } = useActions();
  const versionBlobFile = useVersionBlobFile();
  const sheetChanges = useWorkSheetChanges();

  useEffect(() => {
    // clear the differences when the component unmounts
    return () => {
      clearDifferences();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    { data: versionsData, isLoading: isLoadingVersions },
    { data: unsyncedChangesData },
  ] = useVersionDiffDataQueries(
    enrolledFile?.driveMsId || "",
    enrolledFile?.msId || ""
  );

  useEffect(() => {
    if (fileData && versionsData) {
      const versionsSorted = versionsData.sort(
        (a: IFileVersion, b: IFileVersion) =>
          a.createdAt > b.createdAt ? -1 : 1
      );
      setInitialVersionsData(versionsSorted);
    }
  }, [fileData, versionsData, setInitialVersionsData]);

  const {
    setDiffPageStatus,
    setDiffPageData,
    setWorkbook,
    setInitialDiffData,
  } = useActions();
  const isInitial = searchParams.get("initial");
  const currentVersionId = searchParams.get("currentVersion");
  const previousVersionId = searchParams.get("previousVersion");
  const latestVersionId = findLatestVersionId(
    enrolledFile as IWorkspaceItem,
    versions
  );

  const getSeenStatusData = (changes: ChangeRecord[]) => {
    const actualChanges = changes.filter((change) => {
      return change.sheetName !== "all";
    });
    const formattedSeenData = actualChanges.map((change) => ({
      sheetName: change.sheetName,
      isSeen: false,
      fileId: enrolledFile?.msId || "",
      fileVersionId: currentVersionId,
    }));

    if (enrolledFile?.msId === undefined) return {};

    const seenStatusData = {
      [`${enrolledFile?.msId}-${currentVersionId}`]: formattedSeenData,
    };
    return seenStatusData;
  };

  const handleSeenChangesData = useCallback(
    (changes: ChangeRecord[]) => {
      const seenStatusData = getSeenStatusData(changes);

      const currentChangesSeenStatus = JSON.parse(
        localStorage.getItem(CHANGES_SEEN_STATUS) || "{}"
      );

      const mergedChanges = merge(seenStatusData, currentChangesSeenStatus);

      localStorage.setItem(CHANGES_SEEN_STATUS, JSON.stringify(mergedChanges));

      const seenChanges = mergedChanges;

      const changesAreBeingTracked =
        seenChanges[`${enrolledFile?.msId}-${currentVersionId}`];

      if (!changesAreBeingTracked) {
        localStorage.setItem(
          CHANGES_SEEN_STATUS,
          JSON.stringify({ ...seenChanges, ...seenStatusData })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentVersionId, enrolledFile?.msId]
  );

  const handleDiffHeaderContent = useCallback(() => {
    if (isInitial) setDiffPageStatus("loading");

    const latestVersion = versions.find(
      (version) => version.internalId === latestVersionId
    );

    const latestIndex = versions.findIndex(
      (version) => version.internalId === latestVersionId
    );

    const prevVersion = versions[latestIndex + 1];

    if (currentVersionId === "live") {
      // handles case if a user tries to access the live changes after creating a version
      if (enrolledFile?.hasUncommittedChanges === false && latestVersion) {
        setDiffPageData({
          currentVersion: latestVersion,
          previousVersion: prevVersion,
          isShowingUncommittedChanges: false,
        });

        if (window.history.pushState) {
          const newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            `?currentVersion=${latestVersion.internalId}&previousVersion=${prevVersion.internalId}`;
          window.history.pushState({ path: newurl }, "", newurl);
        }
        return;
      }

      if (latestVersion) {
        setDiffPageData({
          currentVersion: latestVersion,
          previousVersion: latestVersion,
          isShowingUncommittedChanges: true,
        });
      }
      return;
    }

    let previousVersion = versions.find((version) => {
      return version.internalId === Number(previousVersionId);
    });

    const currentVersion = versions.find(
      (version) => version.internalId === Number(currentVersionId)
    );

    if (previousVersion && previousVersion.wasDiscarded) {
      const previousVersionIndex = versions
        .reverse()
        .findIndex(
          (version) => version.internalId === Number(previousVersionId)
        );
      previousVersion = versions[previousVersionIndex - 1];
    }

    if (!currentVersionId) return;
    if (!currentVersion) return;

    setDiffPageData({
      currentVersion: currentVersion,
      previousVersion: previousVersion,
      isShowingUncommittedChanges: currentVersionId === "live",
    });
  }, [
    currentVersionId,
    previousVersionId,
    versions,
    latestVersionId,
    enrolledFile,
    isInitial,
    setDiffPageData,
    setDiffPageStatus,
  ]);

  const getVersionCompareChanges = useCallback(async () => {
    try {
      if (!enrolledFile) return;

      setDiffPageStatus("loading");
      let currentVersion;
      if (currentVersionId === "live") {
        currentVersion = versions.find(
          (version) => version.internalId === latestVersionId
        );
      } else {
        currentVersion = versions.find(
          (version) => version.internalId === Number(currentVersionId)
        );
      }

      // download the file for the current version
      let fileStream;
      if (currentVersionId === "live") {
        // fileStream = await getLiveFile(fileId || "");
        //  no-op, the file stream will be assigned in the next if block
      } else {
        fileStream = await getVersionFile(
          currentVersion?.internalId.toString() || ""
        );
      }

      // get the changes between the two versions if it's not the initial version
      if (!isInitial) {
        let worksheetChanges: IVersionCompareResult | null | undefined;

        if (
          currentVersionId === "live" &&
          enrolledFile?.driveMsId &&
          enrolledFile?.msId
        ) {
          const { diffs, liveFile } = await compareLatestLiveVersions(
            enrolledFile?.driveMsId,
            enrolledFile?.msId
          );
          worksheetChanges = diffs;
          fileStream = liveFile;
        } else if (
          enrolledFile?.driveMsId &&
          enrolledFile?.msId &&
          previousVersionId &&
          currentVersionId
        ) {
          worksheetChanges = await compareVersions(
            enrolledFile?.driveMsId,
            enrolledFile?.msId,
            previousVersionId,
            currentVersionId
          );
        }

        const changes = !(
          !!worksheetChanges?.workbookDifferences && !!currentVersion
        )
          ? []
          : [
              {
                user: { id: currentVersion?.createdByUserMsId || "" },
                date: currentVersion?.createdAt || "",
                sheetName: "all",
                changes: [
                  {
                    type: ChangeTypes.CREATED_VERSION,
                    version: getVersionText(currentVersion),
                    notes: currentVersion?.description || "",
                  },
                ],
              },
              ...transformData({
                diffs: worksheetChanges.workbookDifferences,
                date: worksheetChanges.updatedOn,
                versionCreatorId: currentVersion?.createdByUserMsId || "",
              } as any),
            ];

        handleSeenChangesData(changes);
        setInitialDiffData({
          worksheetChanges: worksheetChanges ? { ...worksheetChanges } : null,
          changes: changes,
          unsyncedChanges: unsyncedChangesData || [],
          versionBlobFile: fileStream,
          diffPageStatus: "success",
        });
      } else {
        setInitialDiffData({
          worksheetChanges: sheetChanges,
          changes: [],
          unsyncedChanges: unsyncedChangesData || [],
          versionBlobFile: fileStream,
          diffPageStatus: "success",
        });
      }
    } catch (err) {
      console.log(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitial,
    currentVersionId,
    previousVersionId,
    enrolledFile,
    versions,
    unsyncedChangesData,
  ]);

  useEffect(() => {
    const fetchData = () => {
      if (!isLoadingFile && !isLoadingVersions) {
        handleDiffHeaderContent();
        getVersionCompareChanges();
      }
    };
    fetchData();
  }, [
    handleDiffHeaderContent,
    getVersionCompareChanges,
    isLoadingFile,
    isLoadingVersions,
  ]);

  useEffect(() => {
    if (!versionBlobFile) return;
    const initExcelJS = async (fileBuffer: ArrayBuffer) => {
      const workBook = new ExcelJS.Workbook();
      await workBook.xlsx.load(fileBuffer, {
        ignoreNodes: ignoreNodesList,
      });
      setWorkbook(workBook);
    };
    initExcelJS(versionBlobFile).then(() => {
      console.log("ExcelJS engine loaded");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionBlobFile]);

  return (
    <div className="w-full max-h-screen overflow-hidden flex bg-white">
      <div className="w-full max-w-[75%] max-h-screen h-screen flex-1 flex justify-between">
        <div className="w-full grid grid-cols-1 grid-rows-[auto_auto_1fr]">
          <div className="h-[65px] w-full">
            <Header />
          </div>
          <div className="px-8 pr-[22px] h-[64px] flex items-center w-full border-b">
            <FileHeader hideComments={true} />
          </div>
          <ExcelVisualizer isLoading={isLoadingFile || isLoadingVersions} />
        </div>
      </div>
      <div className="w-full max-w-[25%] drop-shadow-sm h-full">
        <Sidebar />
      </div>
    </div>
  );
};

export default VersionDiffsPage;
