import { useParams } from "react-router-dom";
import { useActions } from "../store";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getEnrolledFile } from "../services/file";

export const useSetEnrolledFile = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { setEnrolledFile } = useActions();
  const {
    data: fileData,
    isFetching: isLoadingFile,
    refetch: refetchFile,
  } = useQuery({
    queryKey: ["enrolledFile", fileId],
    queryFn: () => getEnrolledFile(fileId),
    refetchOnMount: false,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (fileData) {
      setEnrolledFile(fileData);
    }
  }, [fileData, setEnrolledFile]);

  return { fileData, isLoadingFile, refetchFile };
};
