import { ReactComponent as MoreIcon } from "@assets/svg/DS/sys-icons/More.svg";
import { ActionIcon, Menu, Select, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { Avatar } from "./DS/Avatar";

export type TeamMember = {
  id: string;
  email: string;
  role: string;
  name?: string | null;
  isCurrentUser?: boolean;
};

type TeamMembersListProps = {
  teamMembers: TeamMember[];
  onRoleChange: (email: string, newRole: string) => void;
  onRemoveMember: (email: string) => void;
};

const ROLES = ["Contributor", "Manager", "Admin"];

export const TeamMembersList: React.FC<TeamMembersListProps> = ({
  teamMembers,
  onRoleChange,
  onRemoveMember,
}) => {
  const sortedMembers = [...teamMembers].sort((a, b) => {
    // First, handle current user priority
    if (a.isCurrentUser) return -1;
    if (b.isCurrentUser) return 1;
    
    // Then sort alphabetically by name (or email if name is not available)
    const nameA = (a.name || a.email).toLowerCase();
    const nameB = (b.name || b.email).toLowerCase();
    return nameA.localeCompare(nameB);
  });

  return (
    <div className="p-6 w-full gap-6 border-t border-DS-gray-200">
      {sortedMembers.map((member) => (
        <div
          key={member.email}
          className="flex items-center py-1.5 gap-6"
        >
          <div className="flex w-[420px] items-center gap-6">
            <div className="flex items-center w-[12.5rem]">
              <Avatar
                user={{
                  displayName: member.name || member.email,
                }}
                className="shrink-0 mr-6"
              />

              <Text className="text-[#2B2B2B] text-[0.8125rem] font-medium leading-5 truncate">
                {member.name || member.email}
                {member.isCurrentUser && (
                  <span className="text-DS-gray-500 font-normal ml-1">
                    (you)
                  </span>
                )}
              </Text>
            </div>
            <Text className="text-DS-gray-500 text-sm truncate">
              {member.email}
            </Text>
          </div>
          <div className="flex-grow-0 w-[180px]">
            <Select
              value={member.role}
              onChange={(value) =>
                onRoleChange(member.email, value || member.role)
              }
              data={member.isCurrentUser ? [...ROLES, 'Owner'] : ROLES}
              disabled={member.isCurrentUser}
              className=""
              rightSection={
                <IconChevronDown size={16} className="text-DS-gray-400" />
              }
            />
          </div>
          {!member.isCurrentUser && (
            <Menu position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="subtle"
                  className="text-DS-gray-400 hover:text-DS-gray-600 ml-auto"
                >
                  <MoreIcon width={16} height={16} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  color="red"
                  onClick={() => onRemoveMember(member.email)}
                >
                  Remove from team
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
          {member.isCurrentUser && <div></div>}
        </div>
      ))}
    </div>
  );
}; 