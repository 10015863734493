import React from "react";
import DataGrid, { Column } from "react-data-grid";
import { useGridRef } from "../../../store";
import { GridRow } from "../types";
import { rowKeyGetter } from "../utils";

const Grid = React.memo(
  ({
    rows,
    columns,
    gridWidth,
    gridHeight,
    zoomLevel,
  }: {
    rows: GridRow[];
    columns: Column<string>[];
    gridWidth: string | number;
    gridHeight: string | number;
    zoomLevel: number;
  }) => {
    const gridRef = useGridRef();

    return (
      <DataGrid
        style={{
          transform: `scale(${zoomLevel})`,
          width: gridWidth,
          height: gridHeight,
          position: "absolute",
          top: 0,
          left: 0,
        }}
        className="origin-top-left rdg-light rk-grid w-full overflow-scroll"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        ref={gridRef}
        rows={rows}
        rowKeyGetter={rowKeyGetter}
      />
    );
  }
);

export default Grid;
