import { useEffect, useMemo } from "react";
import Comment from "./Comment";
import { ReactComponent as CommentIcon } from "../../../assets/svg/DS/comments/comments_empty.svg";
import { IFileChat } from "../../../typings";
import { useSearchParams } from "react-router-dom";
import {
  useCommentsStatus,
  useTargetRef,
  useVisibleComments,
} from "../../../store";
import { useMe } from "../../../hooks/utility";

const scrollIntoView = (target: React.RefObject<HTMLDivElement>) => {
  target.current?.scrollIntoView({
    behavior: "smooth",
  });
};

const CommentList = ({ refetchComments }: { refetchComments: () => void }) => {
  const me = useMe();
  const status = useCommentsStatus();
  const comments = useVisibleComments();
  const targetRef = useTargetRef();
  const scrollToBottom = useMemo(() => scrollIntoView, []);
  const [searchParams] = useSearchParams("id");
  const comment_id = searchParams.get("id");

  useEffect(() => {
    if (comment_id && status === "success") {
      const target = document.getElementById(comment_id);
      target?.scrollIntoView({ behavior: "smooth" });
    }
  }, [comment_id, status]);

  useEffect(() => {
    if (comments.length > 0 && !comment_id) scrollToBottom(targetRef);
  }, [comments, comment_id, scrollToBottom, targetRef]);

  const formatDate = (date: string) => {
    const today = new Date();
    const messageDate = new Date(date);
    const options = { month: "short", day: "numeric" } as const;
    if (today.toDateString() === messageDate.toDateString()) {
      return "Today";
    }
    return messageDate.toLocaleDateString("en-US", options);
  };

  // Function to group messages by date
  const groupCommentsByDate = () => {
    return comments.reduce((groups: any, message) => {
      const date = formatDate(message.createdAt || "");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const groupedComments = groupCommentsByDate();

  return (
    <div className="w-full relative">
      <div className="flex justify-center relative flex-col items-center w-full h-full">
        {comments.length === 0 && (
          <div className="flex flex-col mt-[60%] items-center justify-center">
            <CommentIcon />
            <p className="text-DS-gray-400 text-sm mt-2">
              No comments added yet
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {Object.keys(groupedComments).map((date, idx) => (
          <div key={idx}>
            <div className="flex items-center">
              <div className="h-[1px] w-full bg-DS-gray-200 my-5" />
              <p className="mx-4 text-DS-gray-500 text-sm max-w-fit w-full justify-between text-center flex items-center">
                {date}
              </p>
              <div className="h-[1px] w-full bg-DS-gray-200" />
            </div>
            {groupedComments[date].map((comment: IFileChat, index: number) => {
              const isOwnComment = comment.byUser?.msId === me?.id;
              return (
                <div
                  className={`${
                    comment.internalId.toString() === comment_id
                      ? "animate-blink"
                      : ""
                  } pb-8 group px-6 py-2 ${
                    isOwnComment && "hover:bg-DS-gray-25 ease-in-out"
                  } z-[100] relative`}
                  key={index}
                >
                  <Comment
                    refetchComments={refetchComments}
                    comment={comment}
                  />
                </div>
              );
            })}
          </div>
        ))}
        {comments.length > 0 && <div ref={targetRef} />}
      </div>
    </div>
  );
};

export default CommentList;
