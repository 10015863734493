import { IconDots } from "@tabler/icons-react";
import React, { RefObject, useEffect } from "react";
import IdChip from "../../../components/DS/chips/Id";
import { IFileChat, IWorkspaceItem, UseMeType } from "../../../typings";

import { Popover } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { UserNameWithAvatar } from "../../../components/DS/Avatar";
import { scrollToCell } from "../../../components/rk-xlsx-grid/utils";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../../helpers/notifications";
import { useMe } from "../../../hooks/utility";
import { deleteFileChat, updateChatSeen } from "../../../services/file";
import {
  useActions,
  useEnrolledFile,
  useGridRef,
  useIsEditing,
  useVersions,
} from "../../../store";
import { findLatestVersionId } from "../../../store/utils";
import { getVersionText } from "../../Version/utils";

const isUsersMessage = (message: IFileChat, me: UseMeType) => {
  return message.byUser.msId === me?.id;
};

type CommentProps = {
  comment: IFileChat;
  refetchComments: () => void;
};

interface UserMention {
  displayName: string;
  type: "user";
}

interface CellMention {
  displayName: string;
  type: "cell";
}

interface VersionMention {
  displayName: string;
  type: "version";
}

interface Props {
  message: string;
  onCellClick?: (val: string) => void;
}

export function CommentActions({
  comment,
  refetchComments,
}: {
  comment: IFileChat;
  refetchComments: () => void;
}) {
  const { setIsEditing, setCommentInput } = useActions();

  const deleteMessageMutation = useMutation({
    mutationFn: deleteFileChat,
    onMutate: () => {
      loadingNotification({
        title: "Deleting message...",
      });
    },
    onSuccess: () => {
      refetchComments();
      successNotification({
        title: "Message deleted",
      });
    },
    onError: () => {
      regularNotification({
        title: "Error deleting message",
      });
    },
  });

  return (
    <Popover
      classNames={{ dropdown: "p-0 mr-4" }}
      position="bottom-end"
      shadow="md"
    >
      <Popover.Target>
        <div className="h-full hover:bg-DS-brand-25 p-1 rounded relative z-[100] flex justify-center items-center">
          <IconDots size={18} />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div
          onClick={() => {
            setIsEditing(comment);
            setCommentInput(comment.message);
          }}
          className="body-md-Regular hover:bg-gray-50 py-2 px-4 cursor-pointer"
        >
          Edit
        </div>

        <div
          onClick={() => {
            deleteMessageMutation.mutate(comment.internalId);
          }}
          className="body-md-Regular py-2 hover:bg-gray-50 px-4 cursor-pointer"
        >
          Delete
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

const Comment = ({ comment, refetchComments }: CommentProps) => {
  const me = useMe();
  const { setCommentFilter, setCurrentSheet } = useActions();
  const isEditing = useIsEditing();
  const enrolledFile = useEnrolledFile();
  const gridRef = useGridRef();
  const versions = useVersions();

  const isOwnComment = comment.byUser.msId === me?.id;
  const isLatestVersion =
    comment.version.internalId ===
    findLatestVersionId(enrolledFile as IWorkspaceItem, versions);

  let version = getVersionText(comment.version);
  version = isLatestVersion ? version + ` (latest)` : version;

  const isUpdated = !!comment?.editedOn;
  const isBeingEdited = isEditing?.internalId === comment.internalId;
  const updateSeenMutation = useMutation({
    mutationFn: updateChatSeen,
  });

  useEffect(() => {
    const updateSeenStatus = () => {
      if (!comment.isSeen && !isUsersMessage(comment, me)) {
        return updateSeenMutation.mutate(comment.internalId.toString());
      }
    };
    updateSeenStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMessageSeen = comment.isSeen || isUsersMessage(comment, me);

  const onCellClick = (cell: string) => {
    const sheet = cell.split("!")[0];
    const cellName = cell.split("!")[1];
    setCurrentSheet(sheet);

    setTimeout(() => {
      scrollToCell({
        gridRef: gridRef as RefObject<any>,
        cell: cellName,
      });
    }, 100);
  };

  return (
    <div id={comment.internalId.toString()} className={`w-full cursor-pointer`}>
      <div className="flex w-full items-center justify-between">
        <div className="ml-[-4px]  relative z-100">
          <div className="flex items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                setCommentFilter({
                  type: "user",
                  value: comment.byUser,
                });
              }}
            >
              <UserNameWithAvatar
                user={{ ...comment.byUser }}
                date={comment.createdAt}
                truncate={13}
              />
            </div>
            {!isMessageSeen && (
              <div className="ml-3 w-[6px] h-[6px] rounded-full bg-red-500" />
            )}
          </div>
        </div>
        <div className="flex items-center">
          <span
            onClick={() => {
              setCommentFilter({
                type: "version",
                value: comment.version,
              });
            }}
          >
            <IdChip
              className={`px-2 relative z-100 cursor-pointer py-[1px] font-medium text-xs ${
                comment.version.wasDiscarded || version === "Uncommitted"
                  ? "!text-yellow-700 !bg-yellow-50"
                  : isLatestVersion
                  ? "!text-DS-brand-900 !bg-DS-brand-50"
                  : "text-DS-gray-300 bg-DS-gray-50"
              }`}
              text={`${version}`}
            />
          </span>
          {isOwnComment && !isBeingEdited && (
            <div className="h-full flex ml-2 cursor-pointer relative z-[100]  justify-center items-center">
              <CommentActions
                refetchComments={refetchComments}
                comment={comment}
              />
            </div>
          )}
        </div>
      </div>
      <div className="text-[14px] leading-[24px] text-DS-gray-700 max-w-[360px] mt-3">
        <MessageRenderer onCellClick={onCellClick} message={comment.message} />
        {isUpdated && (
          <span className="text-DS-gray-400 text-xs">(edited)</span>
        )}
      </div>
    </div>
  );
};

const MentionComponent: React.FC<{
  mention: VersionMention | UserMention | CellMention;
  onCellClick?: (cell: string) => void;
}> = ({ mention, onCellClick }) => {
  const getColor = () => {
    if (mention.type === "version" && mention.displayName === "Uncommitted") {
      return "text-yellow-700";
    }
    return "text-DS-brand-500";
  };

  const getDisplayName = () => {
    return mention.displayName;
  };

  let prefix = "@";
  prefix = mention.type === "version" ? "#" : prefix;
  prefix = mention.type === "cell" ? "" : prefix;
  return (
    <span
      onClick={() => {
        if (mention.type === "cell") {
          if (onCellClick) onCellClick(mention.displayName);
        }
      }}
    >
      <span className={`${getColor()} font-medium`}>
        {prefix}
        {getDisplayName()}
      </span>
    </span>
  );
};

const renderMessage = (
  message: string,
  onCellClick?: (val: string) => void
) => {
  const parts = message.split(/(@{{|}}|#{{|}}|={{|}})/);
  return parts.map((part, index) => {
    if (part.startsWith(`"id":"`)) {
      const pattern = /"displayName"\s*:\s*"([^"]+)"/;
      const match = part.match(pattern);
      let type: "version" | "user" | "cell" = "user";
      type = parts[index - 1] === "#{{" ? "version" : type;
      type = parts[index - 1] === "={{" ? "cell" : type;
      const displayName = match ? match[1] : "";
      return <MentionComponent key={index} mention={{ displayName, type }} />;
    }

    if (part === "={{") {
      const cellMentionText = parts[index + 1];
      return (
        <MentionComponent
          onCellClick={() => {
            if (onCellClick) onCellClick(cellMentionText);
          }}
          key={index}
          mention={{ displayName: cellMentionText || "", type: "cell" }}
        />
      );
    }

    if (parts[index - 1] === "={{") return;

    if (part === "@{{" || part === "#{{" || part === "}}") {
      return null;
    }

    return <span key={index}>{part}</span>;
  });
};

const MessageRenderer: React.FC<Props> = ({ message, onCellClick }) => {
  return <div>{renderMessage(message, onCellClick)}</div>;
};

export default Comment;
