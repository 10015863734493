import { Button, Input } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useState } from "react";
import { IFileVersion, IWorkspaceItem } from "../../../typings";
import { getVersionText } from "../utils";
import { uploadFileToDrive } from "../../../services/graph";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../../helpers/notifications";
import { checkErrorResponse } from "../../../helpers/checkErrorResponse";
import {
  addFilesToWorkspace,
  createEnrolledFile,
  getVersionFile,
} from "../../../services/file";
import { useInternalMe, useMe, useMyDrive } from "../../../hooks/utility";
import { useNavigate } from "react-router-dom";

export const CreateFileHeader = ({ version }: { version: IFileVersion }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="body-lg-Regular flex px-4 items-center">
        <span className="font-semibold">
          Create new file from {getVersionText(version).replace("v", "")}
        </span>
      </div>
    </div>
  );
};

type Props = {
  version: IFileVersion;
  file: IWorkspaceItem;
};

export const CreateFileBody = ({ version, file }: Props) => {
  const [status, setStatus] = useState<
    "idle" | "success" | "error" | "loading"
  >("idle");
  const [fileName, setFileName] = useState(
    file.name ? `${file.name} (Copy)` : ""
  );
  const me = useMe();
  const drive = useMyDrive();
  const { userData } = useInternalMe();
  const navigate = useNavigate();

  const enrollFile = async (item: IWorkspaceItem) => {
    const fileToEnroll = await createEnrolledFile({
      userMsId: me.id,
      file: item,
    });

    if (userData?.workspace?.internalId) {
      const response = await addFilesToWorkspace([
        fileToEnroll as IWorkspaceItem,
      ]);
      return response;
    }
  };

  const submitNewFile = async () => {
    try {
      if (status === "loading") return;
      setStatus("loading");
      loadingNotification({
        title: "Creating file.",
        message: "This may take a while. Please wait...",
      });
      //Step 1: Download the file
      const fileBlob = await getVersionFile(version.internalId.toString());
      //Step 2: Upload the file to drive
      const formattedName = fileName.endsWith(".xlsx")
        ? fileName
        : `${fileName}.xlsx`;
      const isShared = file.driveMsId !== drive?.drive?.id;
      const response = await uploadFileToDrive({
        file: fileBlob,
        fileName: formattedName,
        driveId: isShared && drive?.drive?.id ? drive.drive.id : file.driveMsId,
        fileId: file.msId,
        isShared,
      });

      if (checkErrorResponse(response)) {
        regularNotification({
          title: "Error",
          message: response.message || "Error creating file",
        });
      }
      // Step 3: Enroll the file in the workspace
      const fileData = {
        name: response.name,
        driveMsId: response.parentReference.driveId,
        msId: response.id,
      };
      await enrollFile(fileData as IWorkspaceItem);
      navigate("/files");
      modals.closeAll();
      successNotification({
        title: "Success",
        message: `File ${fileName} successfully`,
        autoClose: 5000,
      });
      setStatus("success");
    } catch (e) {
      setStatus("idle");
      console.log(e, "error");
      regularNotification({
        title: "Error",
        message: "Error creating file",
      });
    }
  };

  return (
    <div className="flex flex-col items-center border-t">
      <div className="body-lg-Regular flex px-4 items-center w-full mt-8">
        <div className="flex justify-between pl-4 w-full items-center">
          <span className="font-normal text-sm">{`This will create a new file with the contents of ${getVersionText(
            version
          )}`}</span>
        </div>
      </div>
      <div className="flex flex-col pt-4 w-full border-t mt-8">
        <div className="w-full border-b px-8 py-3">
          <p className="text-sm2 font-medium mb-1">File name</p>
          <Input
            autoFocus
            className="w-full border focus:border-primary-blue  rounded py-1 mb-8"
            placeholder="Enter file name"
            onChange={(event) => setFileName(event.currentTarget.value)}
            value={fileName}
          />
        </div>
        <div className="flex w-full items-center justify-end p-4 ">
          <Button
            variant="outline"
            className="font-normal text-DS-gray-450 border-DS-gray-450"
            onClick={() => modals.closeAll()}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !fileName ||
              fileName.length < 1 ||
              fileName.length > 255 ||
              fileName.includes("/")
            }
            variant="filled"
            className="ml-5 disabled:bg-gray-50 font-normal bg-DS-brand-400"
            onClick={submitNewFile}
          >
            Create file
          </Button>
        </div>
      </div>
    </div>
  );
};
