import { Popover } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import { Column } from "react-data-grid";
import { ColorScheme, GridRow } from "../types.ts";
import { getChangedColumns } from "../utils";
import { CellMenuOptions } from "./CellMenuOptions";

import { getVersionText } from "../../../features/Version/utils";
import {
  useActions,
  useDiffPageData,
  useSelectedSheet,
  useVisibleEditHistoryCell,
  useWorkSheetChanges,
} from "../../../store";
import Cell from "./Cell.tsx";
import CellHistory from "./CellHistory.tsx";

const CellContainer = ({
  row,
  column,
  colorScheme,
}: {
  row: GridRow;
  column: Column<string> & { idx: number };
  colorScheme: ColorScheme;
}) => {
  const rowId = Number(row.id) + 1;
  const columnKey = column.key;
  const cellAddress = columnKey + rowId;

  const visibleEditHistoryCell = useVisibleEditHistoryCell();
  const data = useDiffPageData();
  const { setVisibleEditHistoryCell, setActiveTab } = useActions();
  const worksheetChanges = useWorkSheetChanges();
  const selectedSheet = useSelectedSheet();
  const [showEditHistory, toggleEditHistory] = useState(false);
  const currentVersion = getVersionText(data?.currentVersion);

  const cellRef = useRef(null);

  useEffect(() => {
    if (visibleEditHistoryCell === cellAddress) {
      toggleEditHistory(true);
    } else {
      toggleEditHistory(false);
    }
  }, [setVisibleEditHistoryCell, visibleEditHistoryCell, cellAddress]);

  const getCurrentVersion = () => {
    if (data.isShowingUncommittedChanges) return "Uncommitted changes";
    if (data.currentVersion.wasDiscarded) return "Discarded changes";
    return `${currentVersion}`;
  };

  const versionName = getCurrentVersion();

  const changedColumns = getChangedColumns(
    worksheetChanges?.workbookDifferences,
    selectedSheet
  );

  const ref = useClickOutside(() => {
    toggleEditHistory(false);
    setVisibleEditHistoryCell("");
  });

  const changedColumnsArray = Array.from(changedColumns);
  const isHighlightedCell = changedColumnsArray.includes(cellAddress);

  const CellWithOptions = (
    <div className="w-full h-full">
      <Popover
        classNames={{ dropdown: "min-w-[340px] p-0" }}
        position="bottom"
        opened={showEditHistory}
        zIndex={100}
        closeOnClickOutside={true}
        closeOnEscape
        transitionProps={{ duration: 200, transition: "pop" }}
        onClose={() => {
          setVisibleEditHistoryCell("");
          toggleEditHistory(false);
        }}
      >
        <Popover.Target>
          <div
            ref={cellRef}
            className="!border h-[102%]  relative box-decoration-slice outline-2 border-DS-brand-600 block w-full bg-DS-brand-50"
          >
            <Cell
              row={row}
              column={column}
              colorScheme={colorScheme}
              cellAddress={cellAddress}
              isHighlightedCell={isHighlightedCell}
            />
          </div>
        </Popover.Target>

        <Popover.Dropdown
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            return;
          }}
          className="p-0 bg-white drop-shadow-md transition-all duration-500 ease-in-out transform scale-100 opacity-1 "
        >
          <CellHistory
            cellRef={ref}
            versionName={versionName}
            cellId={cellAddress}
          />
        </Popover.Dropdown>
      </Popover>
    </div>
  );

  if (isHighlightedCell) {
    return (
      <CellMenuOptions
        onViewEditHistory={() => {
          setVisibleEditHistoryCell(cellAddress);
          toggleEditHistory(true);
        }}
        sheetName={selectedSheet.wsName}
        cellAddress={cellAddress}
        onAddToCommentCallback={() => {
          setActiveTab(1);
        }}
        trigger={CellWithOptions}
      />
    );
  }
  return (
    <Cell
      row={row}
      column={column}
      colorScheme={colorScheme}
      cellAddress={cellAddress}
      isHighlightedCell={isHighlightedCell}
    />
  );
};

export default CellContainer;
