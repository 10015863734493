import { type StateCreator } from "zustand";
import { IWorkspaceItem } from "../../typings";

export type FilterTypes =
  | "ALL_FILES"
  | "ALL_UNCOMMITTED"
  | "SINCE_YESTERDAY"
  | "SINCE_LAST_MONTH"
  | "SINCE_LAST_WEEK";

export type FileSlice = {
  allEnrolledFiles: IWorkspaceItem[];
  appliedFilter: FilterTypes;
  visibleFiles: IWorkspaceItem[];
  isFetchingFiles: boolean;
  drawerOpened: boolean;
  fileManagerActiveTab: number;
  actions: {
    setEnrolledFiles: (files: IWorkspaceItem[]) => void;
    setAppliedFilter: (filter: FilterTypes) => void;
    setVisibleFiles: (files: IWorkspaceItem[]) => void;
    clearFilters: () => void;
    setInitialFiles: (files: IWorkspaceItem[]) => void;
    setIsFetchingFiles: (isFetching: boolean) => void;
    setDrawerOpened: (opened: boolean) => void;
    setFileManagerActiveTab: (tab: number) => void;
  };
};

export const createFileSlice: StateCreator<FileSlice> = (set) => ({
  allEnrolledFiles: [],
  visibleFiles: [],
  appliedFilter: "ALL_FILES",
  isFetchingFiles: false,
  refetchFiles: () => null,
  drawerOpened: false,
  fileManagerActiveTab: 0,
  actions: {
    setDrawerOpened: (opened: boolean) => {
      set({ drawerOpened: opened });
    },
    setEnrolledFiles: (files: IWorkspaceItem[]) => {
      set({ allEnrolledFiles: files });
    },
    setAppliedFilter: (filter: FilterTypes) => {
      if (filter === "ALL_FILES") {
        set((state) => ({
          ...state,
          visibleFiles: state.allEnrolledFiles,
          appliedFilter: filter,
        }));
      } else set({ appliedFilter: filter });
    },
    setVisibleFiles: (files: IWorkspaceItem[]) => {
      set({ visibleFiles: files });
    },
    setInitialFiles: (files: IWorkspaceItem[]) => {
      set({ allEnrolledFiles: files, visibleFiles: files });
    },

    clearFilters: () => {
      set((state) => ({
        ...state,
        appliedFilter: "ALL_FILES",
        visibleFiles: state.allEnrolledFiles,
        isFetchingFiles: false,
      }));
    },
    setIsFetchingFiles: (isFetching: boolean) => {
      set({ isFetchingFiles: isFetching });
    },
    setFileManagerActiveTab: (tab: number) => {
      set((state) => ({ ...state, fileManagerActiveTab: tab }));
    },
  },
});
