import { Button, Select, Textarea } from "@mantine/core";
import { modals } from "@mantine/modals";
import React, { useState } from "react";
import { revertToVersion } from "../../../services/versions";
import { ReactComponent as RestoreIcon } from "../../../assets/svg/DS/version-lines/restoreIcon.svg";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../../helpers/notifications";
import { useNavigate, useParams } from "react-router-dom";
import { IFileVersion } from "../../../typings";
import { checkErrorResponse } from "../../../helpers/checkErrorResponse";
import { IconChevronDown } from "@tabler/icons-react";
import { getVersionText } from "../utils";
import { useQueryClient } from '@tanstack/react-query';

export const RestoreChangeHeader = ({ title }: { title?: string }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="body-lg-Regular flex px-4 items-center">
        <RestoreIcon />
        <span className="font-semibold ml-4">
          {title || "Restore Discarded Version"}
        </span>
      </div>
    </div>
  );
};

type VersionType = {
  majorVersion: number;
  minorVersion: number;
  patchVersion: number;
};

type Props = {
  versionOptions: {
    label: string;
    value: VersionType;
  }[];
  versionInternalId: number;
  disclaimer?: string;
  onFinish?: () => void;
  latestVersion: IFileVersion | undefined;
};

export const RestoreChangeBody = ({
  versionOptions,
  versionInternalId,
  disclaimer,
  onFinish,
  latestVersion,
}: Props) => {
  const [status, setStatus] = useState<"idle" | "success" | "loading">("idle");
  const params = useParams();
  const [notes, setNotes] = useState("");
  const [selectedVersion, setSelectedVersion] = React.useState<{
    majorVersion: number;
    minorVersion: number;
    patchVersion: number;
  } | null>(versionOptions[1].value);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const options = !versionOptions
    ? []
    : versionOptions.map((option) => ({
        label: option.label,
        value: `v${option.value.majorVersion}.${option.value.minorVersion}.${option.value.patchVersion}`,
      }));

  const submitNewVersion = async () => {
    try {
      if (status === "loading") return;
      setStatus("loading");
      loadingNotification({
        message: "Restoring these changes. Please wait..",
      });
      const response = await revertToVersion(params.fileId || "", {
        ...selectedVersion,
        description: notes,
        internalId: versionInternalId,
      } as IFileVersion);
      if (checkErrorResponse(response)) {
        regularNotification({ title: "Error", message: response.message });
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["enrolledFile"] });
      modals.closeAll();
      successNotification({
        title: "Success",
        message: "Version restored successfully",
      });
      setStatus("success");
      navigate(`/file/${params.fileId}/versions`);
      if (onFinish) onFinish();
    } catch (err) {
      setStatus("idle");
      console.log(err);
    }
  };

  const version = getVersionText(latestVersion as IFileVersion);
  return (
    <div className="flex flex-col items-center border-t">
      <div>
        <p className="text-sm border-b px-8 py-6">
          {disclaimer ||
            `This will create a new version of the file with the contents of ${version} Don’t worry, you can still access any other versions created before reverting.`}
        </p>
      </div>
      <div className="body-lg-Regular flex px-4 items-center w-full mt-8">
        <div className="flex justify-between pl-4 w-full items-center">
          <span className="font-normal text-sm">Select version number</span>
          <Select
            className="border rounded"
            checkIconPosition="right"
            rightSection={<IconChevronDown size={15} />}
            classNames={{
              input: "hover:border-DS-brand-300 focus:border-DS-brand-300",
              dropdown: "drop-shadow-md p-0 py-0 m-0",
              option: "hover:bg-DS-brand-25 py-1 mt-2",
            }}
            styles={{
              input: {
                paddingLeft: "14px",
                cursor: "pointer",
              },
              option: {
                marginBottom: 6,
              },
            }}
            onChange={(value) => {
              const selected = versionOptions.find(
                (option) =>
                  `v${option.value.majorVersion}.${option.value.minorVersion}.${option.value.patchVersion}` ===
                  value
              );
              setSelectedVersion(selected ? selected?.value : null);
            }}
            value={
              selectedVersion
                ? `v${selectedVersion.majorVersion}.${selectedVersion.minorVersion}.${selectedVersion.patchVersion}`
                : null
            }
            searchable
            placeholder="1.0.0(major version)"
            data={options}
          />
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitNewVersion();
        }}
        className="flex flex-col pt-4 w-full border-t mt-8"
      >
        <div className="w-full border-b px-4">
          <Textarea
            required
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            styles={{
              input: { height: 120, width: "100%", textAlign: "left" },
            }}
            placeholder="Write a short description of why you are reverting to this version"
            className="body-md-Regular text-left  mx-1 w-full"
          />
        </div>
        <div className="flex w-full items-center justify-end p-4 ">
          <Button
            type="button"
            styles={{ root: { height: "32px" } }}
            variant="outline"
            className="font-medium hover:bg-DS-gray-25 text-DS-gray-600 border-DS-gray-300"
            onClick={() => modals.closeAll()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            styles={{ root: { height: "32px" } }}
            disabled={
              status === "loading" ||
              (selectedVersion && selectedVersion?.majorVersion)
                ? false
                : true
            }
            variant="filled"
            className="ml-5 disabled:bg-gray-50 font-medium hover:bg-DS-brand-500 bg-DS-brand-400"
          >
            Create version
          </Button>
        </div>
      </form>
    </div>
  );
};
