import classnames from "clsx";
import React from "react";
import { ReactComponent as Logo } from "../../assets/svg/DS/rockhopper-logo.svg";

export interface LogoProps {
  onClick?: () => void;
  className?: string;
  height?: number;
  width?: number;
  [key: string]: unknown;
}

const LogoComponent: React.FC<LogoProps> = ({
  onClick,
  className,
  height = 38,
  width = 129,
  ...rest
}) => {
  return (
    <div
      className={classnames([
        "text-DS-brand-500 hover:text-DS-brand-600 transition-colors duration-200 cursor-pointer",
        className,
      ])}
      onClick={onClick}
      {...rest}
    >
      <Logo height={height} width={width} />
    </div>
  );
};

export default LogoComponent;
