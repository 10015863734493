import { ReactNode } from "react";
import { useQuery } from "@tanstack/react-query";
import { getUserDetails } from "@services/user";
import { ExtendedIdentity } from "@services/user/user.types";
import { useMe } from "../hooks/utility";

type DataFetcherProps = {
  msId: string;
  render: (args: {
    data: ExtendedIdentity | undefined;
    isLoading: boolean;
    error: unknown;
  }) => ReactNode;
};

const UserFetcher = ({ render, msId }: DataFetcherProps) => {
  const me = useMe();
  const { data, error, isLoading } = useQuery({
    queryKey: ["user", msId],
    queryFn: () => getUserDetails(msId || "", me.id),
  });

  return render(
    isLoading
      ? { data, isLoading, error }
      : {
          data: data
            ? {
                ...data,
                displayName: data.displayName?.trim()
                  ? data.displayName
                  : data.email?.toLowerCase(),
              }
            : data,
          isLoading,
          error,
        }
  );
};

export default UserFetcher;
