import { TUserDetail } from "@/services/user/user.types";
import { AccountInfo } from "@azure/msal-browser";
import { User } from "@microsoft/microsoft-graph-types-beta";
import { StateCreator } from "zustand";

export type AuthSlice = {
  currentInternalMe: TUserDetail | null;
  currentGraphUser: User | null;
  currentMsalAccount: AccountInfo | null;
  actions: {
    setCurrentInternalMe: (user: AuthSlice["currentInternalMe"]) => void;
    setCurrentGraphUser: (user: AuthSlice["currentGraphUser"]) => void;
    setCurrentMsalAccount: (account: AuthSlice["currentMsalAccount"]) => void;
    clearCurrentInternalMe: () => void;
    clearCurrentGraphUser: () => void;
    clearCurrentMsalAccount: () => void;
    clearAuth: () => void;
  };
};

export const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
  currentInternalMe: null,
  currentGraphUser: null,
  currentMsalAccount: null,
  actions: {
    setCurrentInternalMe: (user: AuthSlice["currentInternalMe"]) => {
      set({ currentInternalMe: user });
    },
    setCurrentGraphUser: (user: AuthSlice["currentGraphUser"]) => {
      set({ currentGraphUser: user });
    },
    setCurrentMsalAccount: (account: AuthSlice["currentMsalAccount"]) => {
      set({ currentMsalAccount: account });
    },
    clearCurrentInternalMe: () => {
      set({ currentInternalMe: null });
    },
    clearCurrentGraphUser: () => {
      set({ currentGraphUser: null });
    },
    clearCurrentMsalAccount: () => {
      set({ currentMsalAccount: null });
    },
    clearAuth: () => {
      set({
        currentInternalMe: null,
        currentGraphUser: null,
        currentMsalAccount: null,
      });
    },
  },
});
