import { Combobox, TextInput, useCombobox } from "@mantine/core";
import { IconChevronDown, IconX } from "@tabler/icons-react";
import { FC, ReactNode } from "react";

export interface SearchableOption {
  value: string;
  label: string;
  data?: any;
}

interface SearchableComboboxProps {
  value: string;
  error?: string;
  isLoading: boolean;
  fetchError: Error | null;
  options: SearchableOption[];
  placeholder?: string;
  onChange: (value: string | null) => void;
  onInputChange: (value: string) => void;
  onClear: () => void;
  onSubmit: () => void;
  renderOption?: (option: SearchableOption) => ReactNode;
  className?: string;
}

export const SearchableCombobox: FC<SearchableComboboxProps> = ({
  value,
  error,
  isLoading,
  fetchError,
  options,
  placeholder = "Search...",
  onChange,
  onInputChange,
  onClear,
  onSubmit,
  renderOption,
  className,
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const defaultRenderOption = (option: SearchableOption) => (
    <div className="flex items-center gap-2 py-2">
      <span className="text-DS-gray-700">{option.label}</span>
      {option.data?.email && (
        <span className="text-DS-gray-500">({option.data.email})</span>
      )}
    </div>
  );

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        onChange(value);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <div className={className || "flex-1"}>
          <TextInput
            placeholder={placeholder}
            value={value}
            classNames={{
              wrapper: "border border-DS-gray-300 rounded",
            }}
            onChange={(event) => {
              onInputChange(event.currentTarget.value);
              if (event.currentTarget.value.length > 0) {
                combobox.openDropdown();
              } else {
                combobox.closeDropdown();
              }
            }}
            error={error}
            className="flex-1"
            rightSection={
              value ? (
                <IconX
                  size={16}
                  className="text-DS-gray-400 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                    combobox.closeDropdown();
                  }}
                />
              ) : (
                <IconChevronDown
                  size={16}
                  className="text-DS-gray-400 cursor-pointer"
                />
              )
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
        </div>
      </Combobox.Target>

      <Combobox.Dropdown classNames={{ dropdown: "border-t-0 -mt-[10px]" }}>
        <Combobox.Options>
          {isLoading ? (
            <div className="p-4 text-center text-DS-gray-500">
              Loading...
            </div>
          ) : fetchError ? (
            <div className="p-4 text-center text-red-500">
              Failed to load options. Please try again.
            </div>
          ) : options.length === 0 ? (
            <div className="p-4 text-center text-DS-gray-500">
              No options found
            </div>
          ) : (
            options.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option.value}
                className="flex items-center gap-2 py-2"
              >
                {(renderOption || defaultRenderOption)(option)}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}; 