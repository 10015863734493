import React, { useEffect } from "react";
import { io } from "socket.io-client";
import { apiConfig } from "../authConfig";
import { getGraphIdToken } from "../services/graph";
import { useIsAuthenticated } from "@azure/msal-react";
import { successNotification } from "../helpers/notifications";
import { useAllFiles, useEnrolledFile } from "../store";
import { IWorkspaceItem } from "../typings";

// initialize socket.io connection
export const useSocket = () => {
  const [token, setToken] = React.useState<string>("");
  const [socket, setSocket] = React.useState<any>(null);
  const allEnrolledFiles = useAllFiles();
  const enrolledFile = useEnrolledFile();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    async function retrieveToken() {
      const token = await getGraphIdToken();
      setToken(token);
    }
    if (isAuthenticated) {
      retrieveToken();
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (!token) {
      return;
    }
    const socket = io(apiConfig.apiEndpoint, {
      autoConnect: true,
      query: {
        token,
        connectionType: "frontend",
      },
    });

    socket.io.on("ping", async () => {
      const token = await getGraphIdToken();
      socket.io.opts.query = {
        token,
        connectionType: "frontend",
      };
    });

    socket.on("file-unpaused", (data: { fileMsId: string }) => {
      // check if it's the current file or lookup the file in enrolled files
      let file: IWorkspaceItem | undefined;
      if (enrolledFile?.msId === data.fileMsId) {
        file = enrolledFile;
      } else {
        file = allEnrolledFiles.find((f) => f.msId === data.fileMsId);
      }

      if (file) {
        successNotification({
          title: "Edits unpaused",
          message: `Edits have been unpaused for this file: ${file.name}`,
        });
      }
    });

    setSocket(socket);
    return () => {
      socket?.disconnect();
    };
  }, [allEnrolledFiles, enrolledFile, token]);

  return socket;
};
