import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconChevronLeft } from "@tabler/icons-react";
import { map } from "lodash";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as DiscardedIcon } from "../../../../assets/svg/DS/version-lines/discardIcon.svg";
import PauseEditsButton from "../../../../components/buttons/PauseEdits";
import {
  EditsPausedBody,
  EditsPausedHeader,
} from "../../../../features/PauseEdits/editsPausedModal";
import {
  RequestPauseBody,
  RequestPauseHeader,
} from "../../../../features/PauseEdits/requestPauseModal";
import {
  CreateVersionBody,
  CreateVersionHeader,
} from "../../../../features/Version/component/createVersionModal";
import {
  DiscardModalBody,
  DiscardModalHeader,
} from "../../../../features/Version/component/discardModal";
import { VersionDiffActions } from "../../../../features/Version/component/versionActions";
import { getVersionText } from "../../../../features/Version/utils";
import { useMe } from "../../../../hooks/utility";
import { getFileActiveUsers } from "../../../../services/file";
import {
  useDiffPageData,
  useDiffStatus,
  useEnrolledFile,
  useUnsyncedChanges,
  useVersions,
} from "../../../../store";
import {
  findLatestVersionId,
  latestVersionSelectOptions,
} from "../../../../store/utils";
import { IFileVersion, IWorkspaceItem } from "../../../../typings";

const Header = () => {
  const navigate = useNavigate();
  const enrolledFile = useEnrolledFile();
  const versions = useVersions();
  const data = useDiffPageData();
  const status = useDiffStatus();
  const me = useMe();
  const params = useParams<{ fileId: string }>();
  const unsyncedChanges = useUnsyncedChanges();
  const { data: activeUserData } = useQuery({
    queryKey: ["activeUsers", params.fileId],
    queryFn: () => getFileActiveUsers(params.fileId || ""),
  });
  const queryClient = useQueryClient();
  const canPerformActions = activeUserData?.length === 0;
  const otherUserRequestedPause = enrolledFile?.pauseEditsRequestedBy
    ? enrolledFile?.pauseEditsRequestedBy !== me?.id
    : false;

  const latestVersionId = findLatestVersionId(
    enrolledFile as IWorkspaceItem,
    versions
  );

  const latestVersion = enrolledFile?.latestVersion;
  const versionOptions = !latestVersion
    ? []
    : latestVersionSelectOptions(latestVersion);

  if (!data) return null;

  const currentVersion = getVersionText(data?.currentVersion);

  const getHeaderTitle = () => {
    if (data.isShowingUncommittedChanges) return "Uncommitted changes";
    if (data.currentVersion.wasDiscarded) return "Discarded changes";
    return `Version ${currentVersion}`;
  };

  const getCompareVersionType = (
    version: IFileVersion,
    options?: { isPrev: boolean }
  ) => {
    const isLatest = version.internalId === latestVersionId;
    if (data.isShowingUncommittedChanges && !options?.isPrev)
      return "Live file";
    return (
      <>
        {version.wasDiscarded && (
          <span className="text-DS-red-400">
            <DiscardedIcon className="w-4 mr-2" />
          </span>
        )}
        {version.wasDiscarded ? "Discarded changes" : getVersionText(version)}{" "}
        {isLatest && (
          <span className="text-DS-gray-500 font-normal">(latest)</span>
        )}
      </>
    );
  };

  if (status === "idle") {
    return (
      <div className="w-full h-full !bg-white border-b justify-between flex items-center px-6">
        <Button
          onClick={() => navigate(-1)}
          className="w-fit  px-0 !text-DS-gray-700"
        >
          <span className="cursor-pointer block">
            <IconChevronLeft size={18} className="text-DS-gray-500" />
          </span>
        </Button>
      </div>
    );
  }

  const getDiscardModalContent = () => {
    if (otherUserRequestedPause) {
      return {
        header: <EditsPausedHeader type="discard" />,
        body: (
          <EditsPausedBody
            activeUsers={activeUserData || []}
            pausedOn={enrolledFile?.pauseEditsRequestedAt || ""}
            pausedBy={enrolledFile?.pauseEditsRequestedBy || ""}
            onSuccess={() => {
              queryClient.invalidateQueries({ queryKey: ["enrolledFile"] });
            }}
            type="discard"
          />
        ),
      };
    }
    if (!canPerformActions) {
      return {
        header: <RequestPauseHeader type="discard" />,
        body: (
          <RequestPauseBody
            onSuccess={() => {
              queryClient.invalidateQueries({ queryKey: ["enrolledFile"] });
              modals.closeAll();
              navigate(-1);
            }}
            activeUsers={activeUserData || []}
            type="discard"
          />
        ),
      };
    }
    return {
      header: <DiscardModalHeader />,
      body: (
        <DiscardModalBody
          latestVersion={`v${latestVersion?.majorVersion}.${latestVersion?.minorVersion}.${latestVersion?.patchVersion}`}
        />
      ),
    };
  };

  return (
    <div className="w-full pt-2 h-full !bg-white border-b justify-between flex items-center px-6">
      <Button
        onClick={() => navigate(-1)}
        className="w-fit px-0 !text-DS-gray-700 hover:bg-transparent"
      >
        <span className="cursor-pointer block">
          <IconChevronLeft size={18} className="text-DS-gray-500" />
        </span>
        <p className="ml-4 font-bold">{getHeaderTitle()}</p>
      </Button>
      <div className="flex-1 justify-end flex items-center">
        <p className="mr-3 font-normal text-DS-gray-500">Showing</p>
        <div className="mr-3 flex items-center border px-3 py-1 text-sm rounded-md font-medium text-DS-gray-900">
          {getCompareVersionType(data.currentVersion)}
        </div>
        {data.previousVersion && (
          <>
            <div className="mr-3 text-DS-gray-500">compared to</div>
            <div className="mr-2 flex items-center border px-3 py-1 rounded-md font-medium text-DS-gray-900">
              {getCompareVersionType(data.previousVersion, { isPrev: true })}
            </div>
          </>
        )}

        {data.isShowingUncommittedChanges ? (
          <>
            {enrolledFile && (
              <PauseEditsButton
                enrolledFile={enrolledFile}
                data={activeUserData}
              />
            )}
            <Button
              styles={{ root: { height: "32px" } }}
              onClick={() => {
                modals.open({
                  size: 540,
                  yOffset: 200,
                  styles: { body: { padding: 0, height: "fit-content" } },
                  title: getDiscardModalContent()?.header,
                  children: getDiscardModalContent()?.body,
                });
              }}
              variant="outline"
              className="border-DS-red-500 font-[500] hover:bg-DS-red-25 ml-4 text-DS-red-500"
            >
              Discard
            </Button>
            <Button
              styles={{
                root: { height: "32px" },
              }}
              onClick={() => {
                modals.open({
                  size: 540,
                  yOffset: 200,
                  styles: {
                    body: {
                      padding: 0,
                      height: "fit-content",
                    },
                  },
                  title: <CreateVersionHeader />,
                  children:
                    unsyncedChanges?.length > 0 ? (
                      <RequestPauseBody
                        type="unsynced"
                        activeUsers={
                          map(unsyncedChanges, "createdByUserMsId") || []
                        }
                        onSuccess={() => {
                          queryClient.invalidateQueries({
                            queryKey: ["enrolledFile"],
                          });
                          modals.closeAll();
                        }}
                      />
                    ) : (
                      <CreateVersionBody versionOptions={versionOptions} />
                    ),
                });
              }}
              variant="filled"
              className="bg-DS-brand-400 hover:bg-DS-brand-500 ml-4"
            >
              Create version
            </Button>
          </>
        ) : (
          data.previousVersion && (
            <span className="ml-4">
              <VersionDiffActions
                refetchFile={() => {
                  console.log("refetch file");
                }}
                refetchVersions={() => {
                  console.log("refetch versions");
                }}
                versionOptions={versionOptions}
                version={data.currentVersion}
              />
            </span>
          )
        )}
      </div>
    </div>
  );
};

export default Header;
