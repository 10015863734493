import UserAvatar from "../../../../../components/UserAvatar";
import { map } from "lodash";

export type ChangeProcessing = {
  createdByUserMsId: string;
};

type ChangesProcessingRowProps = {
  changes: ChangeProcessing[];
};

const ChangesProcessingRow = ({ changes }: ChangesProcessingRowProps) => {
  const dedupedUsers = Array.from(new Set(map(changes, "createdByUserMsId")));
  return (
    <div className="flex items-center">
      <div className="bg-DS-brand-400 mr-3 text-white rounded-full text-xs w-5 h-5 flex justify-center items-center font-semibold">
        {changes.length || 0}
      </div>
      <p className="">
        {changes.length === 0 && "changes are processing"}
        {changes.length === 1 && "change is processing from"}
        {changes.length > 1 && "changes are processing from"}
      </p>
      <div className="flex items-center justify-between ml-2">
        {dedupedUsers.length > 0 && (
          <UserAvatar className="" userMsId={dedupedUsers[0]} />
        )}
        {dedupedUsers.length > 1 && (
          <p className="ml-2">+{dedupedUsers.length - 1} more</p>
        )}
      </div>
    </div>
  );
};

export default ChangesProcessingRow;
