import React, { ReactNode } from "react";
import { getFileDetails } from "../services/file";
import { FileData } from "../typings";
import { useQuery } from "@tanstack/react-query";

type DataFetcherProps = {
  msId: string;
  driveMsId: string;
  render: (args: {
    fileDetails: FileData | undefined;
    isLoading: boolean;
    error: unknown;
  }) => ReactNode;
};

// memoize the component to prevent re-rendering

const FileDetailsFetcher = React.memo(
  ({ render, msId, driveMsId }: DataFetcherProps) => {
    const { data, error, isLoading } = useQuery({
      queryKey: ["fileDetails", msId],
      queryFn: () => getFileDetails(msId, driveMsId),
    });

    return render({ fileDetails: data, isLoading, error });
  },
  (prevProps, nextProps) => {
    return (
      prevProps.msId === nextProps.msId &&
      prevProps.driveMsId === nextProps.driveMsId
    );
  }
);

export default FileDetailsFetcher;
