import { callApi } from "../api-service";
import {
  ICreateTeamDto,
  ITeam,
  IUpdateTeamDto,
  IUpdateTeamMemberRoleDto
} from "./team.types";

/**
 * Create a new team
 */
export function createTeam(team: ICreateTeamDto): Promise<ITeam> {
  return callApi("POST", "teams", team);
}

/**
 * Get all teams
 */
export function getTeams(): Promise<ITeam[]> {
  return callApi("GET", "teams");
}

/**
 * Get a single team by ID
 */
export function getTeam(id: string): Promise<ITeam> {
  return callApi("GET", `teams/${id}`);
}

/**
 * Update a team
 */
export function updateTeam(id: string, team: IUpdateTeamDto): Promise<ITeam> {
  return callApi("PATCH", `teams/${id}`, team);
}

/**
 * Delete a team
 */
export function deleteTeam(id: string): Promise<void> {
  return callApi("DELETE", `teams/${id}`);
}

/**
 * Add a member to a team
 */
export function addTeamMember(
  teamId: string,
  userMsId: string,
  role: "Owner" | "Admin" | "Manager" | "Contributor" = "Contributor"
): Promise<ITeam> {
  return callApi("POST", `teams/${teamId}/members`, { userMsId, role });
}

/**
 * Update a team member's role
 */
export function updateTeamMemberRole(
  teamId: string,
  userId: string,
  role: IUpdateTeamMemberRoleDto
): Promise<ITeam> {
  return callApi("PATCH", `teams/${teamId}/members/${userId}/role`, role);
}

/**
 * Remove a member from a team
 */
export function removeTeamMember(
  teamId: string,
  userId: string
): Promise<ITeam> {
  return callApi("DELETE", `teams/${teamId}/members/${userId}`);
}
