import {
  InteractionRequiredAuthError,
  SilentRequest,
} from "@azure/msal-browser";
import { msalInstance } from "./graph";

export const acquireTokens = async (
  scopes: string[],
  extraRequestParams?: Partial<SilentRequest>
): Promise<{
  idToken: string;
  accessToken: string;
}> => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length <= 0) {
    //wait 10 seconds for the user to login
    await new Promise((resolve) => setTimeout(resolve, 10000));
    return acquireTokens(scopes);
  } else {
    msalInstance.setActiveAccount(accounts[0]);
    const silentRequest: SilentRequest = {
      scopes,
      account: accounts[0],
      ...extraRequestParams,
    };
    return msalInstance
      .acquireTokenSilent(silentRequest)
      .then((response) => {
        return {
          idToken: response.idToken,
          accessToken: response.accessToken,
        };
      })
      .catch((error) => {
        // Handle both InteractionRequiredAuthError and invalid_grant refresh token expired errors
        if (
          error instanceof InteractionRequiredAuthError ||
          error.errorCode === "invalid_grant"
        ) {
          return msalInstance
            .acquireTokenRedirect({
              scopes,
            })
            .then(async () => {
              //wait 10 seconds for the user to login
              await new Promise((resolve) => setTimeout(resolve, 10000));
              return acquireTokens(scopes);
            });
        }
        throw error;
      });
  }
};
