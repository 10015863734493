import { useCallback } from 'react';
import { updateUserShowOnboarding } from '../services/user';
import { regularNotification } from '../helpers/notifications';

export const useUpdateOnboarding = () => {
  const updateOnboarding = useCallback(async () => {
    try {
      await updateUserShowOnboarding(false);
      return true;
    } catch (_error) {
      regularNotification({
        title: "Error",
        message: "Failed to update onboarding status. Please try again.",
      });
      return false;
    }
  }, []);

  return updateOnboarding;
}; 