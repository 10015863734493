// import SearchBar from "../SearchInput";
import Tabs from "./Tabs";
// import FileContext from "./FileContext";

function TopSection() {
  return (
    <div className="flex flex-col">
      {/* <div className="px-4"><SearchBar /></div> */}
      <Tabs />
      {/* <FileContext /> */}
    </div>
  );
}

export default TopSection;
