import prettyBytes from "pretty-bytes";
import { FC } from "react";
import {
  addFileToWorkspace,
  getEnrolledFileInfo,
  removeFileFromWorkspace,
} from "../../../services/file";
import { FileData } from "../../../typings";
import clsx from "clsx";
import { ReactComponent as IconFolder } from "../../../assets/svg/DS/sys-icons/Folder.svg";
import ActivityChip from "./ActivityChip";
import { Checkbox, Tooltip } from "@mantine/core";
import { DateTime } from "luxon";
import {
  IconCircleMinus,
  IconCirclePlus,
  IconLoader2,
} from "@tabler/icons-react";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../../helpers/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";

interface IFileRowProps {
  file: FileData & { checked?: boolean };
  idx: number;
  onDoubleClick?: (file: FileData) => void;
  onClick?: (file: FileData) => void;
  closeDrawer?: () => void;
  refetch: any;
}

const FileRow: FC<IFileRowProps> = ({
  file,
  idx,
  onDoubleClick,
  onClick,
  closeDrawer,
  refetch,
}) => {
  const { isLoading, data } = useQuery({
    queryKey: ["file_details", file.id],
    queryFn: () => getEnrolledFileInfo(file.id),
  });

  const fileStatus = data
    ? {
        enrolled: (data as any).isEnrolled,
        isInWorkspace: (data as any).isInUserWorkspace,
      }
    : { enrolled: false, isInWorkspace: false };

  const removeFileFromWorkspaceMutation = useMutation({
    mutationFn: removeFileFromWorkspace,
    onMutate: () => {
      loadingNotification({
        message: "Removing the file from your workspace...",
      });
    },
    onSuccess: () => {
      if (refetch) refetch();
      successNotification({ message: "File removed." });
      if (closeDrawer) closeDrawer();
    },
    onError: () => {
      regularNotification({
        message: "Something went wrong. Please try again.",
      });
    },
  });

  const addFileToWorkspaceMutation = useMutation({
    mutationFn: addFileToWorkspace,
    onMutate: () => {
      loadingNotification({ message: "Adding file to workspace..." });
    },
    onSuccess: () => {
      if (refetch) refetch();
      successNotification({ message: "File enrolled." });
      if (closeDrawer) closeDrawer();
    },
    onError: () => {
      regularNotification({
        message: "Something went wrong. Please try again.",
      });
    },
  });

  const getFileActionButton = () => {
    if (isLoading)
      return (
        <IconLoader2 size={20} className="text-primary-blue animate-spin" />
      );
    if (fileStatus.enrolled && !fileStatus.isInWorkspace) {
      return (
        <Tooltip
          label={<p className="text-xs font-medium">Add to My files</p>}
          position="bottom-start"
        >
          <IconCirclePlus
            onClick={() => {
              if (!addFileToWorkspaceMutation.isPending) {
                addFileToWorkspaceMutation.mutate(file.id);
              }
            }}
            className={clsx(
              "text-DS-alpha-blue-gray-500 hover:text-white hover:scale-[1.2] hover:fill-primary-blue",
              {
                "opacity-50 cursor-not-allowed hover:scale-100 hover:text-DS-alpha-blue-gray-500 hover:fill-transparent": 
                  addFileToWorkspaceMutation.isPending
              }
            )}
          />
        </Tooltip>
      );
    }

    if (fileStatus.enrolled && fileStatus.isInWorkspace) {
      return (
        <Tooltip
          label={<p className="text-xs font-medium">Remove from My files</p>}
          position="bottom-start"
        >
          <IconCircleMinus
            onClick={() => {
              if (!removeFileFromWorkspaceMutation.isPending) {
                removeFileFromWorkspaceMutation.mutate(file.id);
              }
            }}
            className={clsx(
              "text-DS-alpha-blue-gray-500 hover:text-white hover:scale-[1.2] hover:fill-DS-red-500",
              {
                "opacity-50 cursor-not-allowed hover:scale-100 hover:text-DS-alpha-blue-gray-500 hover:fill-transparent": 
                  removeFileFromWorkspaceMutation.isPending
              }
            )}
          />
        </Tooltip>
      );
    }
    return (
      <Checkbox
        styles={{
          input: { borderWidth: "1.5px" },
        }}
        size="16px"
        color="#5F8AF7"
        checked={file.checked || false}
        readOnly
        className="pointer-events-none ml-1"
      />
    );
  };

  return (
    <tr
      key={file.id}
      itemID={`${idx}`}
      className={clsx("hover:bg-DS-brand-10 cursor-pointer h-16", {
        "border-b": file?.isDir,
      })}
      draggable={!file.isDir}
      onDoubleClick={() => onDoubleClick && onDoubleClick(file)}
      onClick={() => !!onClick && !fileStatus.enrolled && onClick(file)}
    >
      <td className="py-5">
        <div className="pl-8">
          {file.isDir ? (
            <IconFolder className="-ml-[2px]" />
          ) : (
            getFileActionButton()
          )}
        </div>
      </td>
      <td className="py-2">
        <div className="flex justify-between pr-6 text-DS-gray-800">
          {file.name}
          {fileStatus.enrolled && !fileStatus.isInWorkspace && (
            <ActivityChip text="Enrolled" />
          )}
          {fileStatus.enrolled && fileStatus.isInWorkspace && (
            <ActivityChip
              className="bg-DS-green-50 text-DS-green-900"
              text="My files"
            />
          )}
        </div>
      </td>
      {/* <td className="py-2">{file.modified}</td> */}
      <td className="py-2 text-DS-gray-500">
        {file.modified
          ? DateTime.fromISO(file.modified).toLocaleString(
              DateTime.DATETIME_MED
            )
          : "---"}
      </td>
      <td className="py-2 text-right pr-8 text-DS-gray-500">
        {file.size ? prettyBytes(file.size) : ""}
      </td>
    </tr>
  );
};

export default FileRow;
