import { MentionsInput, Mention } from "react-mentions";
import { ReactComponent as IconSend } from "../../../assets/svg/message.svg";
import { ReactComponent as IconFillSend } from "../../../assets/svg/message-fill.svg";
import ExcelJS from "exceljs";
import defaultStyles from "./mentionStyles";
import { useAccount } from "@azure/msal-react";
import { Button } from "@mantine/core";
import { useMe } from "../../../hooks/utility";
import UserFetcher from "../../../components/UserFetcher";
import { IconLoader2 } from "@tabler/icons-react";
import { Avatar } from "../../../components/DS/Avatar";
import { useLocation } from "react-router-dom";
import {
  useActions,
  useCollaborators,
  useCommentInput,
  useCommentsStatus,
  useEnrolledFile,
  useIsEditing,
  useVersions,
  useWorkbook,
} from "../../../store";
import { useMutation } from "@tanstack/react-query";
import { sendFileChat, updateFileChat } from "../../../services/file";
import { findLatestVersionId, findVersionZeroId } from "../../../store/utils";
import { IWorkspaceItem } from "../../../typings";
import { regularNotification } from "../../../helpers/notifications";
import { useEffect, useRef } from 'react';

const CommentInput = ({ refetchComments }: { refetchComments: () => void }) => {
  const { setCommentInput, setIsEditing, setCommentStatus } = useActions();
  const status = useCommentsStatus();
  const isEditing = useIsEditing();
  const commentInput = useCommentInput();
  const allCollaborators = useCollaborators();
  const allVersions = useVersions();
  const enrolledFile = useEnrolledFile();
  const account = useAccount();
  const me = useMe();
  const pathname = useLocation().pathname;
  const workbook = useWorkbook();

  const sendMessageMutation = useMutation({
    mutationFn: sendFileChat,
    onMutate: () => {
      setCommentStatus("sending");
    },
    onSuccess: () => {
      setCommentStatus("success");
      refetchComments();
      setCommentInput("");
    },
  });

  const updateMessageMutation = useMutation({
    mutationFn: updateFileChat,
    onMutate: () => {
      setCommentStatus("sending");
    },
    onError: () => {
      regularNotification({
        message: "Something went wrong trying to update the comment.",
      });
      setCommentStatus("error");
    },
    onSuccess: () => {
      setCommentStatus("success");
      setIsEditing(null);
      refetchComments();
      setCommentInput("");
    },
  });

  let sheets: { id: string; display: string }[] = [];

  if (workbook) {
    const wb = workbook;
    const sheetNames = wb.worksheets.map((ws) => ws.name);
    const uniqueSheetNames = Array.from(new Set(sheetNames));
    sheets = uniqueSheetNames.map((item) => ({
      id: item,
      display: item,
    }));
  }

  const collaborators = allCollaborators
    ?.map((item) => ({
      ...item,
      id: item.id || "_",
      display: (!item.displayName?.trim()
        ? item.email
        : (item.displayName)) as string,
    }))
    .filter((item) => item.id !== me?.id && item.email !== me.email);

  const versions = allVersions
    .map((item) => ({
      ...item,
      id: item.internalId,
      display:
        item.majorVersion === 0 &&
        item.minorVersion === 0 &&
        item.patchVersion === 0
          ? "Uncommitted"
          : `v${item.majorVersion}.${item.minorVersion}.${item.patchVersion}`,
    }))
    .filter((item) => {
      if (!enrolledFile?.hasUncommittedChanges && item.majorVersion === 0) {
        return false;
      }
      if (item.wasDiscarded) {
        return false;
      }
      return true;
    })
    //sort by version number and place uncommitted at the top
    .sort((a, b) => {
      if (a.majorVersion === 0) {
        return -1;
      }
      if (b.majorVersion === 0) {
        return 1;
      }
      if (a.majorVersion === b.majorVersion) {
        if (a.minorVersion === b.minorVersion) {
          return b.patchVersion - a.patchVersion;
        }
        return b.minorVersion - a.minorVersion;
      }
      return b.majorVersion - a.majorVersion;
    });

  const checkEmailAndUpdate = () => {
    if (
      commentInput.includes(`"email":"__email__"`) &&
      commentInput.includes(`"id":`)
    ) {
      const id = commentInput.split('"id":"')?.[1]?.split('",')?.[0];
      const collaborator = collaborators?.find((c) => c.id === id);
      if (!collaborator) {
        return commentInput; // Return the original input if no collaborator is found
      }

      let updatedValue = commentInput;
      const emailPlaceholderCount = (
        updatedValue.match(/"email":"__email__"/g) || []
      ).length;
      for (let i = 0; i < emailPlaceholderCount; i++) {
        updatedValue = updatedValue.replace(
          `"email":"__email__"`,
          `"email":"${collaborator.email}"`
        );
      }
      return updatedValue;
    }
    return commentInput;
  };

  function replaceEmailInText(text: string) {
    const emailRegex = /"email":"[^"]+"/g;
    const replacement = `"email":"__email__"`;
    return text.replace(emailRegex, replacement);
  }

  const handleSendMessage = async (message: string) => {
    const hasUncommittedChanges = enrolledFile?.hasUncommittedChanges;
    await sendMessageMutation.mutateAsync({
      fileMsId: enrolledFile?.msId || "",
      message,
      versionInternalId: hasUncommittedChanges
        ? (findVersionZeroId(versions)?.toString() as string)
        : (findLatestVersionId(
            enrolledFile as IWorkspaceItem,
            allVersions
          )?.toString() as string),
    });
  };

  const handleUpdateMessage = async (message: string) => {
    const chatId = isEditing?.internalId.toString();
    if (!message || !chatId) {
      return;
    }
    await updateMessageMutation.mutateAsync({ message, chatId });
  };

  const onSend = () => {
    const value = checkEmailAndUpdate();
    let transformedValue = value;
    transformedValue = getTransformedText(value);
    if (isEditing) {
      handleUpdateMessage(transformedValue);
    } else handleSendMessage(transformedValue);
  };

  const value = isEditing ? replaceEmailInText(commentInput) : commentInput;

  const hasMention = (text: string) => {
    return text.includes("@{{") || text.includes("#{{");
  };

  const getTransformedText = (input: string) => {
    const regex = /}}!(\w+)/g;
    const result = input.replace(regex, (_, value) => {
      const lastChar = value[value.length - 1];

      if (isNaN(Number(lastChar))) return `}}`;
      if (!isNaN(Number(value[0]))) return `}}`;

      return `!${value}}}`;
    });
    return result;
  };

  // Focus on the textarea when the component mounts, if there is a comment text
  const commentTextareaElement = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (commentInput && commentTextareaElement.current) {
      commentTextareaElement.current.focus();
      // Set the cursor at the end of the text
      commentTextareaElement.current.setSelectionRange(
        commentInput.length,
        commentInput.length
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentTextareaElement.current]);

  return (
    <div className="w-full h-full mt-2 flex flex-col  body-md-Medium border border-l-0">
      <div
        id="comment-input"
        className="h-[160px] relative max-h-[160px] overflow-y-auto"
      >
        <MentionsInput
          id='comment-input'
          disabled={status === "sending"}
          value={value}
          inputRef={commentTextareaElement}
          forceSuggestionsAboveCursor={
            hasMention(commentInput)
              ? commentInput.length > 100
              : commentInput.length > 40
          }
          suggestionsPortalHost={
            document.getElementById("comment-input") as HTMLElement
          }
          onChange={(e) => {
            const lastChar = e.target.value[e.target.value.length - 1];
            if (
              (lastChar === " " || lastChar === "." || lastChar === "?") &&
              e.target.value.includes("={{")
            ) {
              const text = getTransformedText(e.target.value);
              setCommentInput(text);
              return;
            }
            setCommentInput(e.target.value);
          }}
          onKeyDown={(e) => {
            // shift + enter should not submit the form
            if (e.key === "Enter" && e.shiftKey) {
              return;
            }
            if (!commentInput.trim().length) {
              return;
            }
            if (e.key === "Enter") {
              e.preventDefault();
              onSend();
            }
          }}
          style={{
            ...defaultStyles,
            suggestions: pathname.includes("/versions")
              ? { ...defaultStyles.suggestions }
              : {
                  ...defaultStyles.suggestions,
                  left: "20%",
                },
          }}
          className="w-full text-sm relative !font-medium p-6 border-l-0 border-0"
          placeholder="Write a comment..."
          allowSpaceInQuery={true}
        >
          <Mention
            displayTransform={(_, display: string) => {
              return `@${display} `;
            }}
            trigger="@"
            className="relative z-[100]"
            data={collaborators || []}
            markup={`@{{"id":"__id__","displayName":"__display__", "email":"__email__"}} `}
          />
          <Mention
            markup={`#{{"id":"__id__","displayName":"__display__"}} `}
            displayTransform={(_, display: string) => {
              return `#${display} `;
            }}
            trigger="#"
            className="relative z-[100]"
            data={versions}
          />
          <Mention
            markup={`={{__display__}} `}
            displayTransform={(_, display: string) => {
              return `${display} `;
            }}
            trigger="="
            className="relative z-[100]"
            data={sheets}
          />
        </MentionsInput>
      </div>
      <div className="flex mb-4 mt-0 items-end w-full px-6 justify-between">
        <div>
          {status === "sending" ? (
            <IconLoader2 className="animate-spin mr-2 text-primary-blue" />
          ) : (
            <UserFetcher
              msId={account?.localAccountId || ""}
              render={({ data, isLoading }) => {
                if (isLoading) {
                  return (
                    <IconLoader2 className="animate-spin mr-2 text-primary-blue" />
                  );
                }
                return (
                  <span>
                    <Avatar
                      className="mr-3"
                      user={{
                        displayName: data?.displayName || data?.email || "",
                      }}
                    />
                  </span>
                );
              }}
            />
          )}
        </div>
        <div className="flex items-center">
          {isEditing && (
            <Button
              size="xs"
              variant="outline"
              onClick={() => {
                setIsEditing(null);
                setCommentInput("");
              }}
              className="text-DS-gray-500 mr-4 border-DS-gray-400 cursor-pointer"
            >
              Cancel
            </Button>
          )}
          <span
            onClick={() => {
              if (commentInput.length) {
                onSend();
              }
            }}
            className={`rounded relative z-[100] cursor-pointer inline-block p-1 ${
              commentInput.length ? "bg-DS-brand-400" : "bg-DS-gray-50"
            }`}
          >
            {commentInput.length > 0 ? <IconFillSend /> : <IconSend />}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CommentInput;
