import SignInButton from "../../components/buttons/SignIn";
import OneDriveLogo from "../../assets/svg/OneDrive Icon.svg";

function RootUnauth() {
  return (
    <div className="flex flex-col justify-center items-center h-full gap-4 max-w-xs mx-auto text-DS-gray-900">
      <img src={OneDriveLogo} alt="logo" className="w-20" />
      <div className="text-3xl leading-10 font-bold">Connect OneDrive</div>
      <div className="text-base2 font-normal leading-7">
        Please connect your OneDrive account.
      </div>
      <SignInButton />
      <div className="text-DS-gray-450 text-sm2 font-normal text-center">
        A new page will open where you will be asked to connect your account.
      </div>
    </div>
  );
}

export default RootUnauth;
