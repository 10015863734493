import ExcelJS from "exceljs";
import React from "react";
import { StateCreator } from "zustand";
import { SelectedSheet } from "../../components/rk-xlsx-grid/types";
import { Change, ChangeRecord } from "../../pages/version-diffs/helpers/transformData";
import { IFileVersion, IVersionCompareResult } from "../../typings";

export const enum ChangeTypes {
  CREATED_VERSION = "CREATED_VERSION",
  CHANGED = "CHANGED",
  REMOVED = "REMOVED",
  ADDED = "ADDED",
}

export type DifferencesSlice = {
  diffPageData: {
    currentVersion: IFileVersion;
    previousVersion: IFileVersion | undefined;
    isShowingUncommittedChanges: boolean;
  };
  workbook: ExcelJS.Workbook | null;
  gridRef: React.RefObject<any> | null;
  versionBlobFile: any;
  worksheetChanges: IVersionCompareResult | null;
  diffPageStatus:
    | "idle"
    | "loading"
    | "error"
    | "success"
    | "loading_diffs"
    | "loading_header";
  changes: ChangeRecord[];
  unsyncedChanges: unknown[];
  currentSheet: string;
  visibleEditHistoryCell: string;
  activeTab: number;
  selectedSheet: SelectedSheet;
  selectedCellHistory: Array<
    ChangeRecord &
      Partial<{
        majorVersion: string;
        minorVersion: string;
        patchVersion: string;
      }>
  > | null;
  actions: {
    setInitialDiffData: (args: {
      changes: ChangeRecord[];
      unsyncedChanges: unknown[];
      versionBlobFile: any;
      worksheetChanges: IVersionCompareResult | null;
      diffPageStatus: DifferencesSlice["diffPageStatus"];
    }) => void;
    setSelectedSheet: (sheet: SelectedSheet) => void;
    setVersionBlobFile: (blob: any) => void;
    setWorksheetChanges: (changes: IVersionCompareResult) => void;
    setChanges: (changes: ChangeRecord[]) => void;
    setSheetChanges: (sheetChanges: ChangeRecord) => void;
    setCellChange: (cellChange: Change) => void;
    setUnsyncedChanges: (changes: unknown[]) => void;
    setCurrentSheet: (sheet: string) => void;
    setDiffPageStatus: (status: DifferencesSlice["diffPageStatus"]) => void;
    setDiffPageData: (diffPageData: DifferencesSlice["diffPageData"]) => void;
    setGridRef: (gridRef: React.RefObject<any>) => void;
    setActiveTab: (tab: number) => void;
    setVisibleEditHistoryCell: (cell: string) => void;
    setWorkbook: (workbook: ExcelJS.Workbook) => void;
    clearDifferences: () => void;
    setSelectedCellHistory: (
      cellHistory: Array<
        ChangeRecord &
          Partial<{
            majorVersion: string;
            minorVersion: string;
            patchVersion: string;
          }>
      >
    ) => void;
    clearSelectedCellHistory: () => void;
  };
};

export const createDifferenceSlice: StateCreator<DifferencesSlice> = (set) => {
  return {
    diffPageData: {
      currentVersion: {} as IFileVersion,
      previousVersion: undefined,
      isShowingUncommittedChanges: false,
    },
    selectedSheet: {
      wsIdx: 0,
      wsName: "",
    },
    gridRef: React.createRef(),
    workbook: null,
    visibleEditHistoryCell: "",
    activeTab: 0,
    versionBlobFile: null,
    worksheetChanges: null,
    diffPageStatus: "idle",
    changes: [],
    unsyncedChanges: [],
    currentSheet: "",
    selectedCellHistory: [],
    actions: {
      setSelectedSheet: (sheet) => {
        set({ selectedSheet: sheet });
      },
      setVersionBlobFile: (blob: any) => {
        set({ versionBlobFile: blob });
      },
      setWorksheetChanges: (changes: IVersionCompareResult) => {
        set({ worksheetChanges: changes });
      },
      setChanges: (changes: ChangeRecord[]) => {
        set({ changes });
      },
      setSheetChanges: (sheetChanges: ChangeRecord) => {
        set((state) => {
          const changes = state.changes;
          // update the changes array with the new changes for the sheet
          const updatedChanges = changes.map((change) =>
            change.sheetName === sheetChanges.sheetName ? sheetChanges : change
          );
          return { ...state, changes: updatedChanges };
        });
      },
      setCellChange: (cellChange: Change) => {
        const sheetName = cellChange.sheetName;
        set((state) => {
          // find the change record for the sheet
          const sheetChange = state.changes.find(
            (change) => change.sheetName === sheetName
          );
          if (!sheetChange) return state;
          // find the change in the change record by cell and sheetName and replace it
          const updatedChanges = sheetChange.changes.map((change) =>
            change.cell === cellChange.cell ? cellChange : change
          );
          // update the changes array with the new changes for the sheet
          const updatedSheetChange = { ...sheetChange, changes: updatedChanges };
          
          const updatedChangesArray = state.changes.map((change) =>
            change.sheetName === sheetName ? updatedSheetChange : change
          );

          return { ...state, changes: updatedChangesArray };

        })
      },
      setUnsyncedChanges: (changes: unknown[]) => {
        set({ unsyncedChanges: changes });
      },
      setCurrentSheet: (sheet: string) => {
        set({ currentSheet: sheet });
      },
      setDiffPageStatus: (status: DifferencesSlice["diffPageStatus"]) => {
        set({ diffPageStatus: status });
      },
      setDiffPageData: (data: DifferencesSlice["diffPageData"]) => {
        set({ diffPageData: data });
      },
      setGridRef: (gridRef: React.RefObject<any>) => {
        set({ gridRef });
      },
      setActiveTab: (tab: number) => {
        set({ activeTab: tab });
      },
      setVisibleEditHistoryCell: (cell: string) => {
        set({ visibleEditHistoryCell: cell });
      },
      setWorkbook: (workbook: ExcelJS.Workbook) => {
        set({ workbook });
      },
      setInitialDiffData: (args: {
        versionBlobFile: any;
        changes: ChangeRecord[];
        unsyncedChanges: unknown[];
        worksheetChanges: IVersionCompareResult | null;
        diffPageStatus: DifferencesSlice["diffPageStatus"];
      }) => {
        set((state) => ({ ...state, ...args }));
      },
      clearDifferences: () => {
        set((state) => ({
          ...state,
          diffPageData: {
            currentVersion: {} as IFileVersion,
            previousVersion: undefined,
            isShowingUncommittedChanges: false,
          },
          workbook: null,
          gridRef: React.createRef(),
          visibleEditHistoryCell: "",
          activeTab: 0,
          versionBlobFile: null,
          worksheetChanges: null,
          diffPageStatus: "idle",
          changes: [],
          unsyncedChanges: [],
          currentSheet: "",
          enrolledFile: {},
        }));
      },
      setSelectedCellHistory: (
        cellHistory: Array<
          ChangeRecord &
            Partial<{
              majorVersion: string;
              minorVersion: string;
              patchVersion: string;
            }>
        >
      ) => {
        set({ selectedCellHistory: cellHistory });
      },
      clearSelectedCellHistory: () => {
        set({ selectedCellHistory: [] });
      },
    },
  };
};
