import { User } from "@microsoft/microsoft-graph-types-beta";
import { IApiUser, IWorkspaceItem } from "../../typings";
import { callApi } from "../api-service";
import { getFileDetails } from "../file";
import { graphClient } from "../graph";
import { ExtendedIdentity, TUserDetail } from "./user.types";

export async function getUserWorkspaceFiles(
  userId: string | undefined
): Promise<unknown> {
  if (!userId) {
    return Promise.resolve([]);
  }
  const apiFiles = await callApi("GET", `enrolled-files`);
  // map through the files and get the details of each file, replace the name with the live file name
  const files: Array<IWorkspaceItem> = await Promise.all(
    apiFiles.map(async (file: IWorkspaceItem) => {
      try {
        const fileDetails = await getFileDetails(file.msId, file.driveMsId);
        return { ...file, name: fileDetails.name };
      } catch (error) {
        console.log("Error getting file details");
        console.log(error);
        return file;
      }
    })
  );
  return files;
}

//get users
export async function getUsers(searchQuery?: string): Promise<ExtendedIdentity[]> {
  try {
    let apiPath = '/users';
    if (searchQuery) {
      // Search by display name, given name, surname, mail, or userPrincipalName
      apiPath += `?$search="displayName:${searchQuery}" OR "givenName:${searchQuery}" OR "surname:${searchQuery}" OR "mail:${searchQuery}" OR "userPrincipalName:${searchQuery}"`;
    }
    const users = await graphClient.api(apiPath).header("ConsistencyLevel", "eventual").get();
    return users.value.map((user: User) => ({
      ...user,
      id: user.id || "",
      displayName: user.displayName || user.givenName + " " + user.surname,
      email: user.mail || user.userPrincipalName,
      username: user?.userPrincipalName,
    }));
  } catch (error) {
    console.log("Error getting users");
    console.log(error);
    return [];
  }
}

export function upsertUser(
  user: IApiUser,
  options?: { throwOnErrors?: boolean }
): Promise<TUserDetail> {
  return callApi("POST", "users", user, options);
}

//get a user by msId
export function getInternalUserByMsId(msId: string): Promise<TUserDetail> {
  return callApi("GET", `users/${msId}`);
}

//get avatar photo by user id
export async function getProfilePhoto(userId: string): Promise<string> {
  if (!userId) {
    return "";
  }

  return graphClient
    .api(`/users/${userId}/photos/48x48/$value`)
    .getStream()
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      return url;
    })
    .catch(() => {
      return "";
    });
}

const userDetailsDbInfo = async (userId: string) => {
  const user = await getInternalUserByMsId(userId);
  const name =
    user.firstName || user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.email;

  return { id: user.msId, displayName: name, email: user.email };
};

//get user details by id
export async function getCurrentUser(): Promise<ExtendedIdentity> {
  const user = await graphClient.api(`/me`).get();
  return {
    id: user.id || "",
    displayName: user.displayName || "",
    email: user.mail || user.userPrincipalName,
  };
}

/**
 * Update user's onboarding status
 * @param showOnboarding Whether to show onboarding
 */
export function updateUserShowOnboarding(
  showOnboarding: boolean
): Promise<unknown> {
  return callApi("PATCH", "users/me/onboarding", {
    shouldShowOnboarding: showOnboarding,
  });
}

export async function getUserDetails(
  msId: string,
  meMsId?: string
): Promise<ExtendedIdentity> {
  if (msId === "null" || !msId) {
    return { id: undefined, displayName: undefined, email: undefined };
  }

  if (msId === meMsId) {
    return await getCurrentUser();
  }

  const user = await userDetailsDbInfo(msId);

  if (user && user.id) return user;

  if (!user || !user.id) {
    const msGraphUser = await graphClient.api(`/users/${msId}`).get();
    if (msGraphUser) {
      return {
        id: msGraphUser.id || "",
        displayName: msGraphUser.displayName || "",
        email: msGraphUser.mail || msGraphUser.userPrincipalName,
      };
    }
  }

  return { id: "", displayName: "", email: "" };
}
