import { ExtendedIdentity } from "@services/user/user.types";

export function mergeIdentityArrays(
  ...arrays: ExtendedIdentity[][]
): ExtendedIdentity[] {
  const mergedArray: ExtendedIdentity[] = arrays.reduce(
    (result: ExtendedIdentity[], currentArray: ExtendedIdentity[]) => {
      currentArray.forEach((obj) => {
        if (!result.some((item) => item.email === obj.email)) {
          result.push(obj);
        }
      });
      return result;
    },
    []
  );

  return mergedArray;
}
