// default color scheme if theme is not available. Black text on white bg.
import {ColorScheme} from "./types.ts";
import ExcelJS from "exceljs";
import {XMLParser} from "fast-xml-parser";

const DEFAULT_SCHEME: ColorScheme = {
  dk1: '000000',
  lt1: 'FFFFFF',
  dk2: '000000',
  lt2: 'FFFFFF',
  accent1: '000000',
  accent2: '000000',
  accent3: '000000',
  accent4: '000000',
  accent5: '000000',
  accent6: '000000',
  hyperlink: '000000',
  followedHyperlink: '000000',
};

// TODO: Add support for more than 1 theme
export const getThemeColorScheme = (wb: ExcelJS.Workbook): ColorScheme => {
  try {
    let colorScheme: ColorScheme = Object.assign({}, DEFAULT_SCHEME);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const theme in wb._themes) {
      const parser = new XMLParser({
        preserveOrder: true,
        ignoreAttributes: false,
        attributeNamePrefix: "",
        attributesGroupName: "attr_"
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const parsedTheme = parser.parse(wb._themes[theme]);
      const colorSchemeObj = parsedTheme[1]["a:theme"][0]["a:themeElements"][0]["a:clrScheme"];
      colorScheme = {
        dk1: colorSchemeObj[0]["a:dk1"][0][":@"].attr_.val,
        lt1: colorSchemeObj[1]["a:lt1"][0][":@"].attr_.val,
        dk2: colorSchemeObj[2]["a:dk2"][0][":@"].attr_.val,
        lt2: colorSchemeObj[3]["a:lt2"][0][":@"].attr_.val,
        accent1: colorSchemeObj[4]["a:accent1"][0][":@"].attr_.val,
        accent2: colorSchemeObj[5]["a:accent2"][0][":@"].attr_.val,
        accent3: colorSchemeObj[6]["a:accent3"][0][":@"].attr_.val,
        accent4: colorSchemeObj[7]["a:accent4"][0][":@"].attr_.val,
        accent5: colorSchemeObj[8]["a:accent5"][0][":@"].attr_.val,
        accent6: colorSchemeObj[9]["a:accent6"][0][":@"].attr_.val,
        hyperlink: colorSchemeObj[10]["a:hlink"][0][":@"].attr_.val,
        followedHyperlink: colorSchemeObj[11]["a:folHlink"][0][":@"].attr_.val,
      }
      if (colorScheme.dk1 === 'windowText') {
        colorScheme.dk1 = colorSchemeObj[0]["a:dk1"][0][":@"].attr_.lastClr || '000000';
      }
      if (colorScheme.lt1 === 'window') {
        colorScheme.lt1 = colorSchemeObj[1]["a:lt1"][0][":@"].attr_.lastClr || 'FFFFFF';
      }
    }
    return colorScheme;
  } catch (e) {
    console.error("Error parsing theme", e);
  }
  return DEFAULT_SCHEME;
}

export const getColor = (index: number, colorScheme: ColorScheme) => {
  switch (index) {
    case 0:
      return colorScheme.lt1;
    case 1:
      return colorScheme.dk1;
    case 2:
      return colorScheme.lt2;
    case 3:
      return colorScheme.dk2;
    case 4:
      return colorScheme.accent1;
    case 5:
      return colorScheme.accent2;
    case 6:
      return colorScheme.accent3;
    case 7:
      return colorScheme.accent4;
    case 8:
      return colorScheme.accent5;
    case 9:
      return colorScheme.accent6;
    case 10:
      return colorScheme.hyperlink;
    case 11:
      return colorScheme.followedHyperlink;
  }
}
