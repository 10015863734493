import LogoComponent from "@/components/DS/LogoNew";
import useUpsertUserMutation from "@/hooks/useUpsertUserMutation";
import { useInternalMe } from "@/hooks/utility";
import { useCurrentGraphUser, useCurrentMsalAccount } from "@/store";
import { AccountInfo } from "@azure/msal-browser";
import { Button, LoadingOverlay, Overlay, TextInput } from "@mantine/core";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface WithInviteCodeRequiredProps {
  children: React.ReactNode;
}

/**
 * Higher-Order Component that verifies if the user has a valid invite code
 * Prevents access to protected content without a valid invite
 */
const WithInviteCodeRequired: FC<WithInviteCodeRequiredProps> = ({
  children,
}) => {
  const { userData: internalMe, userLoading: isInternalMeLoading } =
    useInternalMe();
  const currentGraphUser = useCurrentGraphUser();
  const { username: currentMsalAccountUsername, name: currentMsalAccountName } =
    useCurrentMsalAccount() || ({} as AccountInfo);
  const {
    mutateAsync: upsertUserMutation,
    isPending: isUpsertingUser,
    error: upsertUserError,
  } = useUpsertUserMutation();
  const [inviteCodeInput, setInviteCodeInput] = useState<string>("");
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (code?: string) => {
      if (
        currentGraphUser &&
        currentGraphUser?.id &&
        currentMsalAccountUsername
      ) {
        const splitAccountName = currentMsalAccountName?.split(" ") || [];
        await upsertUserMutation({
          firstName: currentGraphUser?.givenName || splitAccountName[0] || "",
          lastName:
            currentGraphUser?.surname ||
            splitAccountName[splitAccountName.length - 1] ||
            "",
          email:
            currentGraphUser?.mail ||
            currentGraphUser?.userPrincipalName ||
            currentMsalAccountUsername ||
            "",
          msId: currentGraphUser?.id || "",
          username: currentMsalAccountUsername || "",
          inviteCode: code,
        });
      }
    },
    [
      currentGraphUser,
      currentMsalAccountUsername,
      currentMsalAccountName,
      upsertUserMutation,
    ]
  );

  useEffect(() => {
    handleSubmit();
    // this is intentional, should only auto upsert user if it has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGraphUser]);

  if (!internalMe && !isInternalMeLoading) {
    return (
      <Overlay className="flex flex-col justify-center items-center bg-white">
        <div className="w-[360px] flex flex-col items-center border border-gray-200 rounded-lg p-8 shadow-md">
          <LogoComponent width={280} className="mb-6" />
          <h2 className="heading-sm-SemiBold text-center">Invite Code</h2>
          <p className="text-gray-600 body-md-Regular w-[90%] mx-auto mt-1 text-center">
            An invite code is required to sign-up. Please enter your invite code
            below.
          </p>
          <TextInput
            size="xl"
            className="mt-6 text-2xl w-full"
            classNames={{
              input: "text-center",
              wrapper: "border border-gray-300 rounded",
            }}
            value={inviteCodeInput}
            onChange={(e) => setInviteCodeInput(e.target.value)}
          />
          <div className="mt-6 mb-4 flex items-center justify-center">
            <Button
              type="button"
              styles={{ root: { height: "32px" } }}
              variant="outline"
              className="font-medium hover:bg-DS-gray-25 text-DS-gray-600 border-DS-gray-300"
              onClick={() => navigate("/logout")}
            >
              Logout
            </Button>
            <Button
              onClick={() => {
                handleSubmit(inviteCodeInput);
              }}
              type="button"
              styles={{ root: { height: "32px" } }}
              variant="filled"
              loading={isUpsertingUser}
              className="ml-5 font-medium hover:bg-DS-brand-500 hover:disabled:bg-DS-brand-400 bg-DS-brand-400"
            >
              Submit
            </Button>
          </div>
          {upsertUserError && (
            <p className="text-red-500 text-sm text-center mt-2">
              {upsertUserError.message}
            </p>
          )}
        </div>
      </Overlay>
    );
  }

  if (internalMe && !isInternalMeLoading) {
    return <>{children}</>;
  }

  return <LoadingOverlay visible={true} />;
};

export default WithInviteCodeRequired;
