import { Tooltip } from "@mantine/core";
import { IconArrowsDiff } from "@tabler/icons-react";
import { groupBy } from "lodash";
import { ReactComponent as SheetIcon } from "../../../../../assets/svg/DS/sheet-line.svg";
import { UserNameWithAvatar } from "../../../../../components/DS/Avatar";
import { formatDateTime } from "../../../../../helpers/formatDate";
import {
  useChanges,
  useCurrentSheet,
  useGridRef,
  useUnsyncedChanges,
  useWorkSheetChanges,
} from "../../../../../store";
import ChangesProcessingRow from "./ChangesProcessingRow";
import Chip from "./Chip";
import { UserChanges } from "./UserChange";

function getSheetDiffsByUser(
  sheetDiffs:
    | {
        added: Array<{
          sheet: string;
          byUserMsId: string;
        }> | null;
        removed: Array<{
          sheet: string;
          byUserMsId: string;
        }> | null;
      }
    | null
    | undefined
): Record<
  string,
  Array<{ sheet: string; byUserMsId: string; type: "added" | "removed" }>
> {
  const sheetDiffsByUser: Record<
    string,
    Array<{ sheet: string; byUserMsId: string; type: "added" | "removed" }>
  > = {};
  if (sheetDiffs) {
    sheetDiffs.added?.forEach((item) => {
      if (!sheetDiffsByUser[item.byUserMsId]) {
        sheetDiffsByUser[item.byUserMsId] = [];
      }
      sheetDiffsByUser[item.byUserMsId].push({
        sheet: item.sheet,
        byUserMsId: item.byUserMsId,
        type: "added",
      });
    });
    sheetDiffs.removed?.forEach((item) => {
      if (!sheetDiffsByUser[item.byUserMsId]) {
        sheetDiffsByUser[item.byUserMsId] = [];
      }
      sheetDiffsByUser[item.byUserMsId].push({
        sheet: item.sheet,
        byUserMsId: item.byUserMsId,
        type: "removed",
      });
    });
  }
  return sheetDiffsByUser;
}

export const ChangesList = () => {
  const changes = useChanges();
  const unsyncedChanges = useUnsyncedChanges();
  const sheetDiffs = useWorkSheetChanges()?.sheetDifferences;
  const sheetsUpdatedOn = useWorkSheetChanges()?.updatedOn;
  const currentSheet = useCurrentSheet();
  const gridRef = useGridRef();
  const createdVersion = changes[0]; // First change is always the created version
  const userCurrentSheetChanges = changes.slice(1).filter((c) => {
    return c.sheetName === currentSheet;
  });
  // Rest of the changes are user changes
  // group userChanges by user
  const userCellChangesByUser = userCurrentSheetChanges.map((item) => {
    const changesByUser = groupBy(item.changes, (change) => change.user?.id);
    return { date: item.date, changes: changesByUser };
  });

  // group sheetDiffs by user
  const sheetDiffsByUser = getSheetDiffsByUser(sheetDiffs);

  return (
    <div className="w-full mb-12 mt-[-0px]">
      <div className="bg-white border border-t-0 border-l-0 h-16 p-8 py-0 flex">
        <ChangesProcessingRow changes={unsyncedChanges} />
      </div>
      <div className="mb-6 bg-white border border-t-0 border-l-0 h-[64px] p-8 py-0 flex items-center">
        <Tooltip label="Sheet" className="cursor-pointer">
          <div>
            <SheetIcon />
          </div>
        </Tooltip>
        <p className="text-sm font-semibold ml-4">{currentSheet}</p>
      </div>
      <div className="">
        <div className="mx-8 py-2">
          {createdVersion && (
            <>
              <UserNameWithAvatar
                user={{ ...createdVersion.user, msId: createdVersion.user.id }}
                date={createdVersion.date}
              />
              <div className="flex flex-col mb-4 ml-3 mt-3">
                <UserChanges
                  change={createdVersion.changes[0]}
                  gridRef={gridRef}
                />
              </div>
              {/* {userChangesByUser?.length > 0 && (
                
              )} */}
            </>
          )}
        </div>
      </div>
      {Object.keys(sheetDiffsByUser).length && sheetsUpdatedOn ? (
        <>
          <div className="h-[1px] w-full my-4 bg-DS-gray-200" />
          <div className="mx-8 py-2">
            <div className="flex flex-col mb-5 ml-0">
              <p className="font-semibold  flex items-center">
                <IconArrowsDiff className="mr-2 text-primary-blue" />
                Sheet changes
              </p>
              <div className="flex items-center my-2 ml-3 max-w-full">
                <p className="text-xs text-DS-gray-500 shrink-0">updated on</p>
                <Chip
                  classNames={"bg-DS-gray-50 text-DS-gray-500 ml-2"}
                  label={formatDateTime(sheetsUpdatedOn, "MMM d, h:mm a")}
                  showTooltip={false}
                />
              </div>
            </div>
            <div className="">
              {Object.entries(sheetDiffsByUser).map(
                ([userMsId, item], index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-3 mb-4 ml-3 pt-3"
                    >
                      <UserNameWithAvatar user={{ msId: userMsId || "" }} />
                      {item.map((innerItem, innerIndex) => {
                        return (
                          <div
                            key={innerIndex}
                            className="ml-3 gap-2 flex flex-col mt-2"
                          >
                            <div className="text-sm flex items-center">
                              <div className="flex items-center max-w-full">
                                <p className="text-[13px] text-DS-gray-500 shrink-0">
                                  {innerItem?.type} sheet
                                </p>
                                <Chip
                                  classNames="!bg-green-50 !text-green-900"
                                  label={innerItem?.sheet}
                                  showTooltip={false}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </>
      ) : null}
      {userCellChangesByUser?.length > 0 && (
        <div className="h-[1px] w-full my-4 bg-DS-gray-200" />
      )}
      {userCellChangesByUser.map((item, index) => {
        return (
          <div className="" key={index}>
            <div className="mx-8 py-2">
              <div className="flex flex-col mb-5 ml-0">
                <p className="font-semibold  flex items-center">
                  <IconArrowsDiff className="mr-2 text-primary-blue" />
                  Cell changes
                </p>
                <div className="flex items-center my-2 ml-3 max-w-full">
                  <p className="text-xs text-DS-gray-500 shrink-0">updated on</p>
                  <Chip
                    showTooltip={false}
                    classNames={"bg-DS-gray-50 text-DS-gray-500 ml-2"}
                    label={formatDateTime(item.date, "MMM d, h:mm a")}
                  />
                </div>
              </div>
              <div className="">
                {Object.keys(item.changes).map((userId, index) => {
                  const userChanges = item.changes[userId];
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-3 mb-4 ml-3 pt-3"
                    >
                      <UserNameWithAvatar user={{ msId: userId || "" }} />
                      {userChanges.map((change, index) => {
                        const isLastChange = index === userChanges.length - 1;
                        return (
                          <div
                            key={index}
                            className="ml-3 gap-2 flex flex-col mt-2"
                          >
                            <UserChanges change={change} gridRef={gridRef} />
                            {!isLastChange && (
                              <div className="h-[1px] w-full mt-4 bg-DS-gray-200" />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
