import { AppShell } from "@mantine/core";
import BottomSection from "./BottomSection";
import GrowSection from "./GrowSection";
import TopSection from "./TopSection";
import { useIsAuthenticated } from "@azure/msal-react";

function Navbar() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <AppShell.Navbar className="bg-white font-medium">
      {/* First section with normal height (depends on section content) */}
      <AppShell.Section>
        <TopSection />
      </AppShell.Section>

      {/* Grow section will take all available space that is not taken by first and last sections */}
      {isAuthenticated && (
        <div className="mt-4">
          <AppShell.Section grow>
            <GrowSection />
          </AppShell.Section>
        </div>
      )}

      {/* Last section with normal height (depends on section content) */}
      <AppShell.Section>
        <BottomSection />
      </AppShell.Section>
    </AppShell.Navbar>
  );
}

export default Navbar;
