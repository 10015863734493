import { Button, Divider, Menu, Radio } from "@mantine/core";
import { IconFilter } from "@tabler/icons-react";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useActions, useAppliedFilter } from "../../../store";
import { getFilesWithUncommittedChanges } from "../../../services/file";
import { IWorkspaceItem } from "../../../typings";
import { regularNotification } from "../../../helpers/notifications";
import { FilterTypes } from "../../../store/slices/FilesSlice";

export function FileFilterMenu() {
  const [opened, setOpened] = useState(false);
  const appliedFilter = useAppliedFilter();
  const { setAppliedFilter, setVisibleFiles, setIsFetchingFiles } =
    useActions();

  const filterFilesMutation = useMutation({
    mutationKey: ["files", appliedFilter],
    mutationFn: (filter: FilterTypes) =>
      getFilesWithUncommittedChanges({
        hasUncommittedChanges: true,
        since: appliedFilter === "ALL_UNCOMMITTED" ? null : filter,
      }),
    onSuccess: (data) => {
      setIsFetchingFiles(false);
      setVisibleFiles(data as IWorkspaceItem[]);
    },
    onError: () => {
      regularNotification({
        title: "Error",
        message: "Failed to filter files",
      });
    },
  });

  const isFilteringUncommittedChanges = [
    "ALL_UNCOMMITTED",
    "SINCE_LAST_WEEK",
    "SINCE_LAST_MONTH",
    "SINCE_YESTERDAY",
  ].includes(appliedFilter);

  const handleApplyFilters = () => {
    if (appliedFilter === "ALL_FILES") {
      setAppliedFilter("ALL_FILES");
      return;
    }
    setIsFetchingFiles(true);
    filterFilesMutation.mutate(appliedFilter);
    setOpened(false);
  };

  const handleClearAll = () => {
    setAppliedFilter("ALL_FILES");
    setOpened(false);
  };

  return (
    <Menu
      onChange={setOpened}
      opened={opened}
      withArrow
      position="bottom-end"
      shadow="md"
      width={300}
    >
      <Menu.Target>
        <span
          onClick={() => setOpened(!opened)}
          className="border border-solid hover:bg-DS-gray-100 p-1 rounded cursor-pointer "
        >
          {<IconFilter size={18} className="text-DS-gray-500" />}
        </span>
      </Menu.Target>

      <Menu.Dropdown className="py-4 !px-0">
        <div className="px-4">
          <Menu.Label>FILTERS</Menu.Label>
          <Menu.Item closeMenuOnClick={false} className="mt-3">
            <Radio
              checked={appliedFilter === "ALL_FILES"}
              onChange={() => {
                setAppliedFilter("ALL_FILES");
              }}
              label="show all files"
              className="font-medium text-DS-gray-700"
            />
          </Menu.Item>
          <Menu.Item closeMenuOnClick={false} className="mt-2">
            <Radio
              checked={
                appliedFilter === "ALL_UNCOMMITTED" ||
                isFilteringUncommittedChanges
              }
              onChange={() => {
                setAppliedFilter("ALL_UNCOMMITTED");
              }}
              className="font-medium text-DS-gray-700"
              label="show only files with uncommitted changes"
            />
          </Menu.Item>
          <div className="ml-8">
            {/* <Menu.Item closeMenuOnClick={false} className="">
              <Radio
                onChange={() => {
                  setAppliedFilter("ALL_TIME");
                }}
                checked={
                  appliedFilter === "ALL_TIME" || appliedFilter === "ALL_UNCOMMITTED"
                }
                label="all time"
                className="body-md-Regular mb-1 text-DS-gray-450"
              />
            </Menu.Item> */}
            <Menu.Item className="my-2" closeMenuOnClick={false}>
              <Radio
                checked={appliedFilter === "SINCE_YESTERDAY"}
                onChange={() => {
                  setAppliedFilter("SINCE_YESTERDAY");
                }}
                className="body-md-Regular mb-1 text-DS-gray-450"
                label="since yesterday"
              />
            </Menu.Item>{" "}
            <Menu.Item className={"mb-2"} closeMenuOnClick={false}>
              <Radio
                label="since last week"
                checked={appliedFilter === "SINCE_LAST_WEEK"}
                onChange={() => {
                  setAppliedFilter("SINCE_LAST_WEEK");
                }}
                className="body-md-Regular mb-1 text-DS-gray-450"
              />
            </Menu.Item>
            <Menu.Item closeMenuOnClick={false}>
              <Radio
                checked={appliedFilter === "SINCE_LAST_MONTH"}
                onChange={() => {
                  setAppliedFilter("SINCE_LAST_MONTH");
                }}
                className="body-md-Regular mb-1 text-DS-gray-450"
                label="since last month"
              />
            </Menu.Item>
          </div>
        </div>
        <Divider className="my-4" />
        <div className="flex justify-between px-6">
          <Button
            onClick={handleClearAll}
            variant="outline"
            className="border-DS-gray-300 font-medium px-3 hover:bg-DS-gray-100  text-DS-gray-600"
          >
            Clear all
          </Button>
          <Button
            onClick={handleApplyFilters}
            variant="filled"
            className="bg-DS-brand-400 hover:bg-DS-brand-500 font-medium px-3"
          >
            Apply filters
          </Button>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
}
