import { IFileVersion, IWorkspaceItem } from "../typings";

export const findLatestVersionId = (
  enrolledFile: IWorkspaceItem,
  versions: IFileVersion[]
) => {
  const latestVersion = enrolledFile?.latestVersion;
  const latestVersionId = versions.find(
    (version) =>
      version.majorVersion === latestVersion?.majorVersion &&
      version.minorVersion === latestVersion?.minorVersion &&
      version.patchVersion === latestVersion?.patchVersion
  )?.internalId;
  return latestVersionId;
};

export const findVersionZeroId = (versions: IFileVersion[]) => {
  const versionZeroId = versions.find(
    (version) =>
      version.majorVersion === 0 &&
      version.minorVersion === 0 &&
      version.patchVersion === 0
  )?.internalId;
  return versionZeroId;
};

export const getPreviousNonDiscardedVersion = (
  versions: IFileVersion[],
  version: number
) => {
  const versionIndex = versions.findIndex((v) => v.internalId === version);
  const sliced = versions.slice(versionIndex + 1);
  const previousNonDiscardedVersion = sliced.find(
    (v) => Boolean(v.wasDiscarded) === false
  );
  return previousNonDiscardedVersion;
};

export const latestVersionSelectOptions = (latestVersion: {
  majorVersion: number;
  minorVersion: number;
  patchVersion: number;
}) => [
  {
    label: `v${latestVersion.majorVersion + 1}.0.0 (Major version)`,
    value: {
      majorVersion: latestVersion.majorVersion + 1,
      minorVersion: 0,
      patchVersion: 0,
    },
  },
  {
    label: `v${latestVersion.majorVersion}.${
      latestVersion.minorVersion + 1
    }.0 (Minor version)`,
    value: {
      majorVersion: latestVersion.majorVersion,
      minorVersion: latestVersion.minorVersion + 1,
      patchVersion: 0,
    },
  },
  {
    label: `v${latestVersion.majorVersion}.${latestVersion.minorVersion}.${
      latestVersion.patchVersion + 1
    } (Patch)`,
    value: {
      majorVersion: latestVersion.majorVersion,
      minorVersion: latestVersion.minorVersion,
      patchVersion: latestVersion.patchVersion + 1,
    },
  },
];

export const getCellTagJSON = (sheetName: string, cell: string) => {
  const tagText = `${sheetName}!${cell}`;
  const tagString = `={{${tagText}}}`;
  return tagString;
};
