import { useQuery } from "@tanstack/react-query";
import { getUsers } from "@services/user";
import { ExtendedIdentity } from "@services/user/user.types";

export const useTenantUsers = (searchQuery?: string) => {
  return useQuery<ExtendedIdentity[], Error>({
    queryKey: ["tenantUsers", searchQuery],
    queryFn: async () => {
      try {
        const users = await getUsers(searchQuery);
        return users;
      } catch (_error) {
        throw new Error("Failed to fetch tenant users");
      }
    },
  });
}; 