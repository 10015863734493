import { useActions } from "@/store";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Text } from "@mantine/core";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout: FC = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { clearAuth } = useActions();

  useEffect(() => {
    clearAuth();

    const handleLogout = async () => {
      try {
        const account = instance.getActiveAccount();
        // const response = await instance.logoutRedirect();
        await instance.logoutPopup({
          mainWindowRedirectUri: "/",
          account,
        });
      } catch (err) {
        console.error(err);
      }
    };

    if (isAuthenticated) {
      handleLogout();
    }
    if (!isAuthenticated) {
      //wait for 5 seconds before redirecting to home page
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }, [clearAuth, instance, isAuthenticated, navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-full gap-4 max-w-md mx-auto">
      <Text c="dimmed" className="">
        You have been logged out, redirecting...
      </Text>
    </div>
  );
};

export default Logout;
