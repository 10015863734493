import { useQueries, queryOptions } from "@tanstack/react-query";
import { getFileUnsyncedChanges } from "../../../services/file";
import { getFileVersions } from "../../../services/versions";

export const dataQueries = (driveMsId: string, fileMsId: string) => {
  const versions = queryOptions({
    queryKey: ["fileVersions", fileMsId],
    queryFn: () => getFileVersions(fileMsId || ""),
    enabled: !!fileMsId,
    refetchOnWindowFocus: false,
  });

  const unsyncedChanges = queryOptions({
    queryKey: ["unsyncedChanges", fileMsId],
    queryFn: () => getFileUnsyncedChanges(driveMsId, fileMsId),
    enabled: !!fileMsId,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    refetchInterval: 10000,
  });

  return {
    versions,
    unsyncedChanges,
  };
};

export const useVersionDiffDataQueries = (
  driveMsId: string,
  fileMsId: string
) => {
  // const { data: versionsData, isLoading: isLoadingVersions } = useQuery();

  // const { data: unsyncedChangesData, isLoading: isLoadingUnsyncedData } =
  //   useQuery();

  const results = useQueries({
    queries: [
      dataQueries(driveMsId, fileMsId).versions,
      dataQueries(driveMsId, fileMsId).unsyncedChanges,
    ],
  });

  return results;
};
