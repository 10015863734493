import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

import { ChangeProcessing } from "../pages/version-diffs/components/ChangeLog/components/ChangesProcessingRow";
import { AuthSlice, createAuthSlice } from "./slices/AuthSlice";
import { CommentSlice, createCommentsSlice } from "./slices/CommentSlice";
import {
  createDifferenceSlice,
  DifferencesSlice,
} from "./slices/DifferencesSlice";
import { createFileSlice, FileSlice } from "./slices/FilesSlice";
import {
  createFileVersionSlice,
  FileVersionSlice,
} from "./slices/FileVersionsSlice";
import {
  createPersistentSettingsSlice,
  PersistentSettingsSlice,
} from "./slices/PersistentSettingsSlice";

type Store = FileSlice &
  FileVersionSlice &
  CommentSlice &
  DifferencesSlice &
  AuthSlice &
  PersistentSettingsSlice;

const useAppStore = create<Store>()((...a) => ({
  ...createFileSlice(...a),
  ...createFileVersionSlice(...a),
  ...createCommentsSlice(...a),
  ...createDifferenceSlice(...a),
  ...createAuthSlice(...a),
  ...createPersistentSettingsSlice(...a),
  actions: {
    ...createFileSlice(...a).actions,
    ...createFileVersionSlice(...a).actions,
    ...createCommentsSlice(...a).actions,
    ...createDifferenceSlice(...a).actions,
    ...createAuthSlice(...a).actions,
    ...createPersistentSettingsSlice(...a).actions,
  },
}));

if (import.meta.env.DEV) {
  console.log("Mounting store devtools");
  mountStoreDevtool("Store", useAppStore);
}

export const useActions = () => useAppStore((state: Store) => state.actions);

/****************************************/
/******** Files store selectors ********/
export const useDrawerOpened = () =>
  useAppStore((state: Store) => state.drawerOpened);

export const useAllFiles = () =>
  useAppStore((state: Store) => state.allEnrolledFiles);

export const useVisibleFiles = () =>
  useAppStore((state: Store) => state.visibleFiles);

export const useIsFetchingFiles = () =>
  useAppStore((state: Store) => state.isFetchingFiles);

export const useAppliedFilter = () =>
  useAppStore((state: Store) => state.appliedFilter);

export const useFileVersionsStore = () =>
  useAppStore((state: Store) => ({
    versions: state.versions,
    showComments: state.showComments,
  }));

export const useFileManagerActiveTab = () =>
  useAppStore((state: Store) => state.fileManagerActiveTab);

/******** Versions store selectors ********/
export const useShowComments = () =>
  useAppStore((state: Store) => state.showComments);

export const useVersions = () => useAppStore((state: Store) => state.versions);

export const useEnrolledFile = () =>
  useAppStore((state: Store) => state.enrolledFile);

/******* Comments store selectors **********/
export const useComments = () => useAppStore((state: Store) => state.comments);

export const useVisibleComments = () =>
  useAppStore((state: Store) => state.visibleComments);

export const useCommentInput = () =>
  useAppStore((state: Store) => state.commentInput);

export const useAppliedCommentFilter = () =>
  useAppStore((state: Store) => state.appliedCommentsFilter);

export const useIsEditing = () =>
  useAppStore((state: Store) => state.isEditing);

export const useCommentsStatus = () =>
  useAppStore((state: Store) => state.status);

export const useCollaborators = () =>
  useAppStore((state: Store) => state.collaborators);

export const useTargetRef = () =>
  useAppStore((state: Store) => state.targetRef);

/***************** Diff store selectors ************/

export const useDiffPageData = () =>
  useAppStore((state: Store) => state.diffPageData);

export const useDiffStatus = () =>
  useAppStore((state: Store) => state.diffPageStatus);

export const useVersionBlobFile = () =>
  useAppStore((state: Store) => state.versionBlobFile);

export const useWorkSheetChanges = () =>
  useAppStore((state: Store) => state.worksheetChanges);

export const useChanges = () => useAppStore((state: Store) => state.changes);

export const useUnsyncedChanges = () =>
  useAppStore((state: Store) => state.unsyncedChanges as ChangeProcessing[]);

export const useCurrentSheet = () =>
  useAppStore((state: Store) => state.currentSheet);

export const useActiveTab = () =>
  useAppStore((state: Store) => state.activeTab);

export const useGridRef = () => useAppStore((state: Store) => state.gridRef);

export const useVisibleEditHistoryCell = () =>
  useAppStore((state: Store) => state.visibleEditHistoryCell);

export const useWorkbook = () => useAppStore((state: Store) => state.workbook);

export const useSelectedSheet = () =>
  useAppStore((state: Store) => state.selectedSheet);

export const useSelectedCellHistory = () =>
  useAppStore((state: Store) => state.selectedCellHistory);

/********** Auth store selectors **********/
export const useCurrentInternalMe = () =>
  useAppStore((state: Store) => state.currentInternalMe);

export const useCurrentGraphUser = () =>
  useAppStore((state: Store) => state.currentGraphUser);

export const useCurrentMsalAccount = () =>
  useAppStore((state: Store) => state.currentMsalAccount);

export const useAuthStatus = () => useAppStore((state: Store) => state.status);

/********** Persistent settings store selectors **********/
export const useShowFileManagerDelayWarning = () =>
  useAppStore((state: Store) => state.showFileManagerDelayWarning);
