import { Tooltip } from "@mantine/core";
import {
  Identity,
  NullableOption,
} from "@microsoft/microsoft-graph-types-beta";
import { clsx } from "clsx";
import { FC, useEffect, useState } from "react";
import { useMe } from "../hooks/utility";
import { getUserDetails } from "../services/user";
import { Avatar } from "./DS/Avatar";

const UserAvatar: FC<{
  userMsId?: string;
  identity?: NullableOption<Identity> | undefined;
  className?: string;
  withNameLabel?: boolean;
}> = ({ identity, userMsId, className, withNameLabel = false }) => {
  const me = useMe();
  const [user, setUser] = useState<Identity>();

  useEffect(() => {
    if (identity) {
      setUser(identity);
    } else if (userMsId) {
      getUserDetails(userMsId, me.id).then((user) => setUser(user));
    }
  }, [identity, userMsId, me]);

  return (
    <div className={clsx("flex items-center", className)}>
      <Tooltip
        openDelay={0}
        position="top"
        className="cursor-pointer"
        label={user?.displayName}
      >
        <Avatar
          user={{
            id: user?.id ?? undefined,
            displayName: user?.displayName || "",
          }}
        />
      </Tooltip>

      {withNameLabel && (
        <span className="ml-2 text-sm font-semibold text-DS-gray-700">
          {user?.displayName}
        </span>
      )}
    </div>
  );
};

export default UserAvatar;
