import clsx from "clsx";
import React, { FC } from "react";

export interface IconButtonProps {
  variant?: "blue" | "ghost";
  onClick?: () => void;
  Icon: React.ElementType;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;

  [key: string]: unknown;
}

const IconButton: FC<IconButtonProps> = ({
  variant = "blue",
  onClick = () => null,
  disabled = false,
  wrapperClassName,
  className,
  Icon,
  ...rest
}) => {
  let resultClassName;
  if (disabled) {
    resultClassName = clsx("bg-DS-gray-50 text-gray-400", wrapperClassName);
  } else {
    switch (variant) {
      case "blue":
        resultClassName = clsx(
          "text-white bg-DS-brand-400 hover:bg-DS-brand-500",
          "cursor-pointer",
          wrapperClassName
        );
        break;
      case "ghost":
        resultClassName = clsx(
          "text-DS-gray-500 bg-transparent  hover:bg-DS-alpha-blue-gray-100",
          "cursor-pointer",
          wrapperClassName
        );
        break;
      default:
        resultClassName = clsx(
          "text-white bg-DS-brand-400 hover:bg-DS-brand-500",
          "cursor-pointer",
          wrapperClassName
        );
        break;
    }
  }

  resultClassName += " rounded w-8 h-8 flex items-center justify-center";
  return (
    <div onClick={onClick} className={resultClassName} {...rest}>
      <Icon className={className} />
    </div>
  );
};

export default IconButton;
