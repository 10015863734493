import { DateTime } from "luxon";
import { useMemo } from "react";
import { ReactComponent as FileIcon } from "../../../assets/svg/DS/sys-icons/Spreadsheet.svg";
import { IFileVersion, IWorkspaceItem } from "../../../typings";
import { FileOptionPopover } from "./FileOptionPopover";
import {
  loadingNotification,
  regularNotification,
  successNotification,
} from "../../../helpers/notifications";
import { IconLoader2, IconX } from "@tabler/icons-react";
import { ReactComponent as OpenFileIcon } from "../../../assets/svg/DS/open-file.svg";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Button, Tooltip } from "@mantine/core";
import IdChip from "../../../components/DS/chips/Id";
import { useNavigate } from "react-router-dom";
import FileDetailsFetcher from "../../../components/FileDetailsFetcher";
import { getVersionText } from "../../../features/Version/utils";
import {
  deleteEnrolledFile,
  removeFileFromWorkspace,
} from "../../../services/file";
import { modals } from "@mantine/modals";
import { useMutation } from "@tanstack/react-query";
import { useVisibleFiles } from "../../../store";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

type FileTableProps = DataTableProps<any, any>;

const FileTable = ({ columns, data }: FileTableProps) => {
  const navigate = useNavigate();
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="table-auto w-full">
      <thead className="bg-DS-gray-50">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="text-left text-DS-gray-450 text-sm leading-5 h-10 bg-gray-50 border-b border-b-DS-gray-200"
          >
            {headerGroup.headers.map((header) => (
              <th key={header.id} className="pl-8">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            onClick={() => {
              navigate(`/file/${row.original.msId}/versions`);
            }}
            className="relative cursor-pointer h-[70px] hover:bg-DS-gray-25 border-b border-DS-gray-100 group"
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="pl-8">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

type FileListProps = {
  files: IWorkspaceItem[];
  onClick: (file: IWorkspaceItem) => void;
  refetch: () => void;
};

const FilesList = ({ refetch }: FileListProps) => {
  const files = useVisibleFiles();
  const navigate = useNavigate();

  const removeEnrolledFileMutation = useMutation({
    mutationKey: ["removeFile"],
    mutationFn: deleteEnrolledFile,
    onMutate: () => {
      loadingNotification({ message: "Removing file from workspace." });
    },
    onSuccess: () => {
      refetch();
      successNotification({ message: "File unenrolled" });
      close();
      modals.closeAll();
    },
    onError: () => {
      regularNotification({
        message: "Something went wrong. Please try again.",
        icon: <IconX color="red" />,
      });
    },
  });

  const removeFileFromWorkspaceMutation = useMutation({
    mutationKey: ["removeFileFromWorkspace"],
    mutationFn: removeFileFromWorkspace,
    onSuccess: () => {
      refetch();
      successNotification({ message: "File removed" });
    },
    onMutate: () => {
      loadingNotification({
        message: "Removing the file from your workspace...",
      });
    },
    onError: () => {
      regularNotification({
        message: "Something went wrong. Please try again.",
        icon: <IconX color="red" />,
      });
    },
  });

  const unenrollFile = async (file: IWorkspaceItem) => {
    await removeEnrolledFileMutation.mutateAsync(file.msId);
  };

  const removeFromMyFiles = (file: IWorkspaceItem) => {
    removeFileFromWorkspaceMutation.mutate(file.msId);
  };

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: "File Name",
        accessorKey: "name",
        cell: ({ row }) => (
          <div className="flex items-center ml-[-16px] min-w-0">
            <span
              className={`rounded-full w-[6px] h-[6px] mr-[10px] shrink-0 ${
                row.original.changes ? "bg-DS-red-500" : "bg-transparent"
              }`}
            />
            <FileIcon className="text-DS-green-600 shrink-0" fontSize={18} />
            <div className="ml-4 body-md-Medium text-DS-gray-800">
              {row.original.name}
            </div>
          </div>
        ),
      },
      {
        header: "Changes",
        accessorKey: "changes",
        cell: ({ row }) => {
          return row.original.hasUncommittedChanges ? (
            <div className="relative z-[100]">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/file/${row.original.msId}/changes?currentVersion=live&previousVersion`
                  );
                }}
                variant="filled"
                className="bg-DS-brand-50 px-2 h-[28px] text-DS-brand-900 body-md-Medium"
              >
                View Changes
              </Button>
            </div>
          ) : (
            <div className="ml-2">-</div>
          );
        },
      },
      {
        header: "Latest version",
        accessorKey: "latestVersion",
        cell: ({ row }) => (
          <IdChip
            className="w-fit px-2 bg-white border"
            text={row.original.latestVersion}
          />
        ),
      },
      {
        header: "Last modified",
        accessorKey: "lastModified",
        cell: ({ row }) => (
          <FileDetailsFetcher
            msId={row.original.msId}
            driveMsId={row.original.driveMsId}
            render={({ fileDetails, isLoading }) => {
              if (isLoading) {
                return null;
              }
              return (
                <div className="flex items-center">
                  <p className="text-DS-gray-800 body-md-Regular">
                    {DateTime.fromISO(
                      fileDetails?.modified as string
                    ).toLocaleString(DateTime.DATETIME_MED)}
                  </p>
                </div>
              );
            }}
          />
        ),
      },
      {
        header: "",
        accessorKey: "actions",
        cell: ({ row }) => {
          return (
            <FileDetailsFetcher
              msId={row.original.msId}
              driveMsId={row.original.driveMsId}
              render={({ fileDetails, isLoading }) => {
                if (isLoading) {
                  return (
                    <IconLoader2
                      size={14}
                      className="text-primary-blue animate-spin"
                    />
                  );
                }
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="flex justify-end mr-9 items-center gap-4 z-[1000]"
                  >
                    <Tooltip
                      className="text-xs mr-2 hover:bg-DS-gray-100 rounded p-2"
                      label="Open live file"
                    >
                      <a href={fileDetails?.webUrl} target="_blank">
                        <OpenFileIcon className="text-DS-gray-500  relative z-[10] w-[18px] cursor-pointer" />
                      </a>
                    </Tooltip>
                    <FileOptionPopover
                      removeFile={() => {
                        removeFromMyFiles(row.original);
                      }}
                      onUnenroll={() => unenrollFile(row.original)}
                      file={row.original}
                    />
                  </div>
                );
              }}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data = !files
    ? []
    : files.map((file) => {
        return {
          ...file,
          name: file.name,
          size: "20KB",
          changes:
            Boolean(file.hasUncommittedChanges) ||
            Boolean(file.hasUnseenMessages),
          latestVersion: getVersionText(file.latestVersion as IFileVersion),
          lastModified: file.modified,
        };
      });

  return <FileTable columns={columns} data={data} />;
};

export default FilesList;
