import { FC } from "react";

interface ActivityChipProps {
  text: string;
  className?: string;
}

const ActivityChip: FC<ActivityChipProps> = ({ text, className }) => {
  return (
    <div
      className={`text-DS-brand-900 bg-DS-brand-50 flex py-[0.125rem] px-[0.375rem] items-center gap-[0.375rem] rounded ${className}`}
    >
      {text}
    </div>
  );
};

export default ActivityChip;
