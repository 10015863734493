import { IApiUser, IFileVersion } from "../../../typings";
import { useActions, useAppliedCommentFilter } from "../../../store";

const AppliedFilter = () => {
  const appliedFilter = useAppliedCommentFilter();
  const { clearCommentFilter } = useActions();

  if (!appliedFilter) {
    return null;
  }

  const versionData = appliedFilter.value as IFileVersion;
  const userData = appliedFilter.value as IApiUser;

  const userDisplayName = userData.firstName
    ? `${userData.firstName} ${userData.lastName}`
    : userData.email;

  return (
    <div className="bg-gray-50 flex  pt-2 justify-between border-b text-gray-600 text-sm font-normal px-4 py-2">
      {appliedFilter.type === "version" && (
        <p>
          Showing comments for{" "}
          <span className="font-semibold">{`v${versionData.majorVersion}.${versionData.minorVersion}.${versionData.patchVersion}`}</span>
        </p>
      )}
      {appliedFilter.type === "user" && (
        <p>
          Showing comments by{" "}
          <span className="font-semibold">{userDisplayName}</span>
        </p>
      )}
      <p
        onClick={() => {
          clearCommentFilter();
        }}
        className="font-medium cursor-pointer text-gray-800 underline"
      >
        Clear
      </p>
    </div>
  );
};

export default AppliedFilter;
