import { IconLoader2 } from "@tabler/icons-react";
import RkXlsGrid from "../../../../components/rk-xlsx-grid";
import { useDiffStatus } from "../../../../store";

const ExcelVisualizer = ({ isLoading }: { isLoading: boolean }) => {
  const status = useDiffStatus();

  if (isLoading || status === "loading") {
    return (
      <div className="flex w-full h-[80vh] flex-col items-center justify-center">
        <IconLoader2 size={30} className="text-primary-blue animate-spin" />
        <p className="text-sm font-medium mt-4">
          Working our Excel magic... Your insights are coming up!
        </p>
      </div>
    );
  }
  return <RkXlsGrid />;
};

export default ExcelVisualizer;
