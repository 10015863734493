import Button from "@/components/DS/Button";
import IconAndCountButton from "@/components/buttons/IconAndCountButton";
import {
  useActions,
  useAppliedFilter,
  useDrawerOpened,
  useVisibleFiles,
} from "@/store";
import EnrollFilesDrawer from "./EnrollFilesDrawer";
import { FileFilterMenu } from "./FileFilterMenu";
import { useInternalMe } from "@hooks/utility";

const FilesHeader = ({
  refetch,
  onTeamMembersClick,
  teamData,
}: {
  refetch: any;
  onTeamMembersClick?: () => void;
  teamData?: any;
}) => {
  const opened = useDrawerOpened();
  const items = useVisibleFiles();
  const appliedFilter = useAppliedFilter();
  const { clearFilters, setDrawerOpened } = useActions();
  const { userData } = useInternalMe();

  const uncommittedChangesCount =
    !items || items.length === 0
      ? 0
      : items.filter((item) => item.hasUncommittedChanges).length;

  const getFilterText = () => {
    if (appliedFilter === "SINCE_LAST_WEEK") {
      return (
        <span>
          Showing all files with{" "}
          <span className="font-semibold">uncommitted changes</span> since{" "}
          <span className="font-semibold">last week</span>
        </span>
      );
    }
    if (appliedFilter === "SINCE_YESTERDAY") {
      return (
        <span>
          Showing all files with{" "}
          <span className="font-semibold">uncommitted changes</span> since{" "}
          <span className="font-semibold">yesterday</span>
        </span>
      );
    }
    if (appliedFilter === "ALL_UNCOMMITTED") {
      return (
        <span>
          Showing all files with{" "}
          <span className="font-semibold">uncommitted changes</span>
        </span>
      );
    }
    if (appliedFilter === "SINCE_LAST_MONTH") {
      return (
        <span>
          Showing all files with{" "}
          <span className="font-semibold">uncommitted changes</span> since{" "}
          <span className="font-semibold">last month</span>
        </span>
      );
    }

    return (
      <div className="flex items-center">
        <div className="bg-DS-brand-400 mr-3 text-white rounded-full text-xs w-5 h-5 flex justify-center items-center font-semibold">
          {uncommittedChangesCount || 0}
        </div>{" "}
        files {uncommittedChangesCount === 1 ? "has" : "have"} uncommitted
        changes
      </div>
    );
  };
  return (
    <>
      <div className="bg-white border-t border-b px-8 py-4 flex justify-between">
        <div className="flex items-center">
          <div className="body-lg-SemiBold text-DS-gray-800">My files</div>
          <div className="flex items-center mt-[1px] ml-5">
            <div className="ml-2">{getFilterText()}</div>
            {(appliedFilter !== "ALL_FILES" || !appliedFilter) && (
              <Button
                onClick={() => {
                  clearFilters();
                }}
                size="sm"
                className="ml-4 hover:bg-DS-red-50 hover:text-DS-red-900 bg-DS-red-50 !text-DS-red-900"
              >
                Clear
              </Button>
            )}
          </div>
        </div>
        <div className="flex items-center gap-6">
          <IconAndCountButton
            onClick={onTeamMembersClick || (() => {})}
            label="Team members"
            count={teamData?.teamMembers?.length || 0}
            className="!ml-0"
            disabled={
              !((teamData?.teamMembers?.length ?? 0) >= 1) ||
              !userData?.teamMembers?.[0]?.permissions.includes(
                "can_invite_members"
              )
            }
          />
          <FileFilterMenu />
          <Button
            variant="blue"
            onClick={() => {
              setDrawerOpened(true);
            }}
          >
            File manager
          </Button>
        </div>
      </div>
      {opened && <EnrollFilesDrawer refetch={refetch} />}
    </>
  );
};

export default FilesHeader;
