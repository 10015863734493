import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import React, { useCallback } from "react";

type WelcomeModalProps = {
  opened: boolean;
  onClose: () => void;
  teamName?: string;
};

const WelcomeModal: React.FC<WelcomeModalProps> = ({ opened, onClose, teamName }) => {
  const openModal = useCallback(() => {
    modals.open({
      id: "welcome-modal",
      onClose,
      size: "auto",
      title: (
        <div className="text-DS-gray-800 text-xl font-semibold w-full">
          Welcome to Rockhopper!
        </div>
      ),
      centered: true,
      withCloseButton: false,
      styles: {
        title: {
          textAlign: "center",
          width: "100%",
        },
        root: {
          padding: "2.5rem",
        },
      },
      children: (
        <div className="text-center w-[22.5rem] text-sm">
          <p className="text-DS-gray-500">
            Welcome to {teamName ? `${teamName}` : 'your team'}! You're all set to start using Rockhopper.
          </p>
          <div className="flex justify-center gap-6 mt-4">
            <Button
              variant="filled"
              className="bg-DS-brand-400 hover:bg-DS-brand-500 font-medium px-3 focus:outline-none focus-visible:ring-0 text-sm"
              onClick={onClose}
            >
              Get Started
            </Button>
          </div>
        </div>
      ),
    });
  }, [onClose, teamName]);

  React.useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [opened, openModal]);

  return null;
};

export default WelcomeModal;
