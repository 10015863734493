import { Button, Popover } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { IWorkspaceItem } from "../../../typings";
import Separator from "../../../components/DS/Separator";
import { modals } from "@mantine/modals";

type Props = {
  file?: IWorkspaceItem;
  onUnenroll: () => void;
  removeFile: () => void;
};

const UnenrollModalHeader = () => {
  return (
    <div className="flex flex-col">
      <span className="font-semibold text-lg ml-4">Unenroll this file?</span>
    </div>
  );
};

const UnenrollModalBody = ({ onUnenroll }: { onUnenroll: () => void }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="text-sm font-medium text-DS-gray-500 px-8">
        Careful, this will unenroll the file for{" "}
        <strong>
          <u>all users</u>
        </strong>{" "}
        that have access to it in Rockhopper. All versions & comments related to
        this file will be lost.
      </div>
      <div className="flex w-full items-center justify-end p-4">
        <Button
          variant="outline"
          className="font-normal text-DS-gray-450 border-DS-gray-450"
          onClick={() => modals.closeAll()}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          className="ml-5 disabled:bg-gray-50 font-normal bg-DS-red-400"
          onClick={onUnenroll}
        >
          Yes, unenroll file
        </Button>
      </div>
    </div>
  );
};

export function FileOptionPopover({ file, onUnenroll, removeFile }: Props) {
  return (
    <Popover
      classNames={{ dropdown: "p-0 mr-4" }}
      position="bottom-end"
      withArrow={false}
      shadow="md"
    >
      <Popover.Target>
        <div className="h-full hover:bg-DS-gray-100 p-2 rounded relative z-[100] flex justify-center items-center">
          <IconDots className="text-DS-gray-500" size={18} />
        </div>
      </Popover.Target>

      <Popover.Dropdown className="w-[220px]">
        <div className="body-md-Regular hover:bg-DS-brand-50 py-2 px-4 cursor-pointer">
          <Link to={`/file/${file?.msId}/versions`}>View Version History</Link>
        </div>
        <Separator />
        <div
          onClick={removeFile}
          className="body-md-Regular py-2 hover:bg-DS-brand-50 px-4 cursor-pointer"
        >
          Remove from My files
        </div>
        <Separator />
        <div
          onClick={() => {
            modals.open({
              size: 436,
              yOffset: 200,
              styles: { body: { padding: 0, height: "fit-content" } },
              title: <UnenrollModalHeader />,
              children: <UnenrollModalBody onUnenroll={onUnenroll} />,
            });
          }}
          className="body-md-Regular text-DS-red-500 py-2 hover:bg-DS-brand-50 px-4 cursor-pointer"
        >
          Unenroll file
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}
