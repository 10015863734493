import { modals } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as PauseIcon } from "../../assets/svg/DS/pause.svg";
import { ReactComponent as PausedIcon } from "../../assets/svg/DS/paused.svg";
import {
  EditsPausedBody,
  EditsPausedHeader,
} from "../../features/PauseEdits/editsPausedModal";
import {
  RequestPauseBody,
  RequestPauseHeader,
} from "../../features/PauseEdits/requestPauseModal";
import { useMe } from "../../hooks/utility";
import { IWorkspaceItem } from "../../typings";
import IconAndCountButton from "./IconAndCountButton";

type PauseEditsButtonProps = {
  data?: string[];
  enrolledFile: IWorkspaceItem;
};

const PauseEditsButton = ({ enrolledFile, data }: PauseEditsButtonProps) => {
  const activeUsersLength = data?.length || 0;
  const me = useMe();

  const canUnpauseEdit = !!enrolledFile?.pauseEditsRequestedBy;
  const canPauseEdit = !enrolledFile?.pauseEditsRequestedBy;

  const queryClient = useQueryClient();

  const handleSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ["enrolledFile"] });
    modals.closeAll();
  };

  return (
    <div>
      {canUnpauseEdit && (
        <IconAndCountButton
          onClick={() => {
            modals.open({
              size: 540,
              yOffset: 200,
              styles: { body: { padding: 0, height: "fit-content" } },
              title: <EditsPausedHeader type="unpause" />,
              children: (
                <EditsPausedBody
                  type="unpause"
                  canUnpause={enrolledFile?.pauseEditsRequestedBy === me?.id}
                  pausedOn={enrolledFile?.pauseEditsRequestedAt || ""}
                  pausedBy={enrolledFile?.pauseEditsRequestedBy || ""}
                  activeUsers={data || []}
                  onSuccess={handleSuccess}
                />
              ),
            });
          }}
          icon={<PausedIcon />}
          label="Edits paused"
          count={activeUsersLength}
          className="bg-DS-orange-25 text-DS-orange-500"
        />
      )}
      {canPauseEdit && (
        <IconAndCountButton
          onClick={() => {
            modals.open({
              size: 540,
              yOffset: 200,
              styles: { body: { padding: 0, height: "fit-content" } },
              title: <RequestPauseHeader type="default" />,
              children: (
                <RequestPauseBody
                  onSuccess={handleSuccess}
                  activeUsers={data || []}
                  type="default"
                />
              ),
            });
          }}
          icon={<PauseIcon />}
          label="Pause edits"
          count={activeUsersLength}
          disabled={!activeUsersLength}
          tooltip={
            !activeUsersLength
              ? "No users are actively using this file. You can revert or discard without pausing edits."
              : undefined
          }
        />
      )}
    </div>
  );
};

export default PauseEditsButton;
