// Import React Query hooks
import { upsertUser } from "@/services/user";
import { useActions } from "@/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Define the mutation function
const useUpsertUserMutation = () => {
  const queryClient = useQueryClient();
  const { setCurrentInternalMe } = useActions();

  return useMutation({
    mutationFn: async (userData: {
      firstName: string;
      lastName: string;
      email: string;
      msId: string;
      username: string;
      inviteCode?: string;
    }) => {
      return await upsertUser(userData, { throwOnErrors: true });
    },
    onSuccess: (data, variables) => {
      // Set the query data directly
      queryClient.setQueryData([`user_${variables.msId}`], data);

      // Update the store with the user data
      setCurrentInternalMe(data);
    },
  });
};

export default useUpsertUserMutation;
