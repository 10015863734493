import { apiConfig } from "../authConfig";
import { getGraphIdToken } from "./graph";

export const getApiHeaders = async () => {
  const headers = new Headers();
  const idToken = await getGraphIdToken();

  headers.append("Authorization", `Bearer ${idToken}`);
  headers.append("Content-Type", "application/json");
  headers.append("Cache-Control", "no-cache");
  return headers;
};


export async function callApi(method = "GET", endpoint = "", body?: unknown, { throwOnErrors = false } = {}) {
  const options = {
    method,
    headers: await getApiHeaders(),
    body: JSON.stringify(body),
  };

  let fetchUrl = apiConfig.apiEndpoint;

  if (endpoint) {
    fetchUrl = `${fetchUrl}/${endpoint}`;
  }

  return fetch(fetchUrl, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      };
      return response
        .clone()
        .json()
        .catch(() => response.text())
    })
    .catch((error) => {
      console.log("Error calling API:", fetchUrl, options, error);
      if (throwOnErrors) {
        throw error;
      }
    });
}
