import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTeamMember, removeTeamMember, updateTeamMemberRole } from "@services/teams";

export const useAddTeamMemberMutation = (teamId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userMsId,
      role,
    }: {
      userMsId: string;
      role: "Owner" | "Admin" | "Manager" | "Contributor";
    }) => addTeamMember(teamId, userMsId, role),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["team", teamId.toString()] });
    },
  });
};

export const useUpdateTeamMemberRoleMutation = (teamId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      role,
    }: {
      userId: string;
      role: "Owner" | "Admin" | "Manager" | "Contributor";
    }) => updateTeamMemberRole(teamId, userId, { role }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["team", teamId.toString()] });
    },
  });
};

export const useRemoveTeamMemberMutation = (teamId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => removeTeamMember(teamId, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["team", teamId.toString()] });
    },
  });
}; 