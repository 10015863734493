import React from "react";
import { Tooltip } from "@mantine/core";

type ChipProps = {
  label: string;
  classNames?: string;
  onClick?: () => void;
  truncate?: boolean;
  showTooltip?: boolean;
};

const Chip: React.FC<ChipProps> = ({
  label,
  classNames,
  onClick,
  truncate = true,
  showTooltip = true,
}) => {
  if (!label) return null;
  return (
    <Tooltip
      zIndex={300}
      multiline
      label={<p className="text-[13px] max-w-lg">{label.toString()}</p>}
      disabled={!showTooltip}
    >
      <p
        onClick={onClick || (() => {})}
        className={`w-fit text-[13px] ml-2 px-2 py-[1px] rounded-md !font-medium bg-DS-brand-50 text-DS-brand-900 ${truncate ? "truncate" : ""} ${onClick ? "cursor-pointer" : ""} ${classNames}`}
      >
        {label}
      </p>
    </Tooltip>
  );
};

export default Chip;
