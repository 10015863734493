// sign in button component
//
import { PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import SimpleButton from "../DS/Button";

export default function SignInButton() {
  const { instance } = useMsal();
  const signIn = () => {
    const request: RedirectRequest | PopupRequest = {
      ...loginRequest,
    };
    instance.loginRedirect(request).catch((e) => {
      console.log(e);
    });
  };
  return (
    <SimpleButton onClick={signIn} variant="blue">
      Connect OneDrive
    </SimpleButton>
  );
}
