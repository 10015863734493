import { useState } from "react";
import UserAvatar from "../../components/UserAvatar";
import { RequestPauseType } from "./requestPauseModal";

const ActiveUsersRow = ({
  activeUsers,
  requestPauseType,
}: {
  activeUsers: string[];
  requestPauseType?: RequestPauseType;
}) => {
  const dedupedUsers = Array.from(new Set(activeUsers));
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  const getText = () => {
    switch (requestPauseType) {
      case "unsynced":
        return (
          <div className="flex items-center">
            <div className="bg-DS-brand-400 mr-3 text-white rounded-full text-xs w-5 h-5 flex justify-center items-center font-semibold">
              {activeUsers.length || 0}
            </div>
            <p className="">
              {activeUsers.length === 0 && "changes are processing"}
              {activeUsers.length === 1 && "change is processing from"}
              {activeUsers.length > 1 && "changes are processing from"}
            </p>
          </div>
        );
      default:
        return "This file is currently being used by:";
    }
  };

  return (
    <div className="flex flex-col">
      <div className="text-sm flex items-center w-full justify-between text-gray-600">
        {getText()}{" "}
        <div className="flex items-center">
          <UserAvatar userMsId={dedupedUsers[0]} className="ml-2" />
          {dedupedUsers.length > 1 && (
            <span
              className="ml-2 font-normal cursor-pointer"
              onClick={toggleExpanded}
            >
              + {dedupedUsers.length - 1} more
            </span>
          )}
        </div>
      </div>
      <div
        className={`transition-all ease-in-out duration-1000 flex items-center mt-4 gap-2 ${
          isExpanded ? "max-h-screen" : "max-h-0"
        }`}
      >
        {isExpanded &&
          dedupedUsers.map((userMsId) => (
            <UserAvatar userMsId={userMsId} className="" />
          ))}
      </div>
    </div>
  );
};

export default ActiveUsersRow;
