import ButtonTabs from "../../../components/DS/ButtonTabs";
import Comments from "../../../features/Comments";
import { ChangeLog } from "../components/ChangeLog";
import { useActions, useActiveTab } from "../../../store";

export const Tabs = [
  {
    label: "Change Log",
    value: "change-log",
    className: "text-sm font-medium whitespace-nowrap",
  },
  {
    label: "Comments",
    value: "comments",
    className: "text-sm font-medium whitespace-nowrap",
  },
];

export const Sidebar = () => {
  const activeTab = useActiveTab();
  const { setActiveTab } = useActions();

  return (
    <div className="flex drop-shadow-sm relative flex-col w-full h-full">
      <div className="w-full py-[16px] flex pl-8 items-center border-l border-b">
        <ButtonTabs
          activeTab={activeTab}
          setActiveTab={(tab) => {
            setActiveTab(tab as number);
          }}
          items={Tabs}
        ></ButtonTabs>
      </div>
      <div className="flex-col flex-1 w-full justify-between bg-white h-[90vh] border-b">
        {activeTab === 0 && (
          <div className="bg-white border-l overflow-y-auto  flex-1 h-full">
            <ChangeLog />
          </div>
        )}
        {activeTab === 1 && (
          <div className="bg-white h-full border-l">
            <Comments />
          </div>
        )}
      </div>
    </div>
  );
};
