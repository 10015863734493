import { Tabs as MantineTabs } from "@mantine/core";
import { useNavigate, useMatches } from "react-router-dom";
// import { ReactComponent as InboxIcon } from "../../../assets/svg/DS/sys-icons/Inbox.svg";
// import { ReactComponent as ProjectsIcon } from "../../../assets/svg/DS/sys-icons/Project.svg";
// import { ReactComponent as MyTasksIcon } from "../../../assets/svg/DS/sys-icons/Task User.svg";
import { ReactComponent as FilesIcon } from "../../../assets/svg/DS/sys-icons/File.svg";

function Tabs() {
  const navigate = useNavigate();
  const matches = useMatches();
  //destructure last element of matches array
  const [{ id }] = matches.slice(-1);

  const tabsClassName =
    "flex items-center gap-3 px-[0.625rem] py-[0.375rem] data-[active='true']:bg-DS-brand-50 data-[active='true']:text-DS-brand-700 rounded h-8 transition-colors duration-200";
  return (
    <MantineTabs
      unstyled
      defaultValue="projects"
      orientation="vertical"
      value={id}
      onChange={(value) => navigate(`/${value}`)}
    >
      <MantineTabs.List className="flex flex-col gap-3 px-3 pt-1">
        {/* <MantineTabs.Tab
          className={tabsClassName}
          value="inbox"
          icon={<InboxIcon />}
        >
          Inbox
        </MantineTabs.Tab> */}

        <MantineTabs.Tab
          className={tabsClassName}
          value="files"
          leftSection={<FilesIcon />}
        >
          <span className="body-md-Medium">My files</span>
        </MantineTabs.Tab>

        {/* <MantineTabs.Tab
          className={tabsClassName}
          value="projects"
          icon={<ProjectsIcon />}
        >
          Projects
        </MantineTabs.Tab> */}
        {/* <MantineTabs.Tab
          className={tabsClassName}
          value="my-tasks"
          icon={<MyTasksIcon />}
        >
          My Tasks
        </MantineTabs.Tab> */}
      </MantineTabs.List>
    </MantineTabs>
  );
}

export default Tabs;
