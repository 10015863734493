import { useEffect } from "react";
import { Button } from "@mantine/core";
import { IconLoader2 } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as Comment } from "../../../assets/svg/DS/comments/comments.svg";
import { ReactComponent as NoComments } from "../../../assets/svg/DS/comments/no_comments.svg";
import { ReactComponent as SpreadsheetIcon } from "../../../assets/svg/DS/sys-icons/Spreadsheet.svg";
import PauseEditsButton from "../../../components/buttons/PauseEdits";
import FileDetailsFetcher from "../../../components/FileDetailsFetcher";
import { getFileActiveUsers } from "../../../services/file";
import { useActions, useEnrolledFile, useShowComments } from "../../../store";

interface FileHeaderProps {
  hideComments?: boolean;
  hasPauseEdits?: boolean;
}

const Header = (props: FileHeaderProps) => {
  const enrolledFile = useEnrolledFile();
  const showComments = useShowComments();
  const { toggleComments } = useActions();

  const { data } = useQuery({
    queryKey: ["activeUsers", enrolledFile?.msId],
    queryFn: () => getFileActiveUsers(enrolledFile?.msId || ""),
    enabled: !!enrolledFile?.msId,
  });

  useEffect(() => {
    if (enrolledFile) {
      document.title = `${enrolledFile?.name} - Versions`;
    }
  }, [enrolledFile]);

  const renderCommentsBtn = () => {
    return enrolledFile?.hasUnseenMessages ? (
      <Comment
        onClick={() => {
          toggleComments();
        }}
        className="cursor-pointer"
      />
    ) : (
      <div
        className={`rounded border-none p-1 hover:bg-gray-100 ${
          showComments ? "bg-gray-100" : ""
        }`}
      >
        <NoComments
          onClick={() => {
            toggleComments();
          }}
          className="cursor-pointer"
        />
      </div>
    );
  };

  return (
    <div className="w-full flex items-center justify-between">
      <div className="flex items-center">
        <SpreadsheetIcon className="text-DS-green-600 w-[16.5px] h-[16.5px]" />
        <p className="ml-4 text-DS-gray-700 mt-[2px] font-medium text-sm2">
          {enrolledFile?.name}
        </p>
      </div>
      <div className="text-DS-gray-600 flex items-center body-md">
        {props?.hideComments ? null : renderCommentsBtn()}
        {props?.hasPauseEdits && enrolledFile && (
          <PauseEditsButton enrolledFile={enrolledFile} data={data || []} />
        )}
        <FileDetailsFetcher
          msId={enrolledFile?.msId || ""}
          driveMsId={enrolledFile?.driveMsId || ""}
          render={({ fileDetails, isLoading }) => {
            if (isLoading) {
              return (
                <IconLoader2
                  size={14}
                  className="animate-spin text-primary-blue mx-4"
                />
              );
            }
            return (
              <a href={fileDetails?.webUrl} target="_blank" rel="noreferrer">
                <Button
                  styles={{
                    root: { height: "32px" },
                  }}
                  className="bg-DS-green-500 mr-1 hover:bg-green-600 ml-6"
                  variant="filled"
                >
                  Open live file
                </Button>
              </a>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Header;
